import React, { useEffect, useState } from 'react';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableInformationOperation from '../components/TableInformationOperation';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInfoOperation, getActivitylogList } from '../action';
import { getReportType, getInfoOperationList } from '../../general/action';
import { getUnitList } from '../../user-accounts/action';

function ActivityLog() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const role = state.profile.showProfile?.role;
  const { reportType, listInfo, loading } = state.general;

  const { listUnit } = state.account;
  const [activitylogList, setActivityLogList] = useState([]);
  const [search, setSearch] = useState();
  useEffect(() => {
    const name = 'information_operations';
    dispatch(getReportType(name));
    const params = { search: search };
    dispatch(getActivitylogList(params, callback));
  }, []);
  const callback = (response) => {
    const { data } = response;
    console.log(data);
    const newData = data.map((newdata) => {
      return {
        name: newdata?.relationships?.causer?.full_name,
        activity: newdata?.attributes?.log_name,
        description: newdata?.attributes?.description,
        date: newdata?.attributes?.timestamp,
        rank: newdata?.relationships?.causer?.rank.name,
        unit: newdata?.relationships?.causer?.unit.name,
      };
    });
    console.log(newData);
    setActivityLogList(newData);
  };

  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>ACTIVITY LOG</p>
          <p className='md-title text-gray-20'>
            View recent report activity here
          </p>
        </div>
        <div className='mt-2'>
          {state && (
            <TableInformationOperation
              loading={loading}
              listInfo={listInfo}
              listUnit={listUnit}
              reportType={reportType}
              deleteInfoOperation={deleteInfoOperation}
              getInfoOperationList={activitylogList}
              setActivityLogList={setActivityLogList}
              role={role}
              setSearch={setSearch}
              search={search}
            />
          )}
        </div>
      </div>
    </StandardLanding>
  );
}

export default ActivityLog;
