import axios from "axios";
import { BASE_URL } from "../constant/url";

const TIMEOUT = 10000;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
});

export { instance as axios };

export function getAuthHeader() {
  let token = localStorage.getItem("token");

  if (token) {
    return { Authorization: "Bearer " + token, 
    'Access-Control-Allow-Origin': '*', 
    'Access-Control-Allow-Methods' : '*',
    'Accept': 'application/json',
  };
  } 
}

export function header() {
  let token = localStorage.getItem("token");

  if (token) {
    return { Authorization: "Bearer " + token, 
    'Access-Control-Allow-Origin': '*', 
    'Access-Control-Allow-Methods' : '*',
    'Accept': 'application/json',
    "Content-Type": "application/json",
  };
  } 
}

export const authHeader = () => {
  let token = localStorage.getItem("token");
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods' : '*',
        'Accept': 'application/json',
        "Content-Type": "application/json",
      },
    };
  } 
};

export const getError = (error) => {
  let message, errorCode, httpStatus;
  if (error.response) {
    const { msg } = error.response.data;
    httpStatus = error.response.status;
    message = msg;
  } else if (typeof error === "string") {
    errorCode = "no_code";
    message = error;
  } else {
    message =
      "Could not connect to the server, Please check your network connection.";
  }
  return {
    errorCode: errorCode,
    message,
    httpStatus: httpStatus || 0,
  };
};

export const isUserLoggedIn = (user) => {
  return !!user && !!localStorage.getItem("token");
};
