import React from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'moment';
import {
  getAdminList,
  getCommunityAffairList,
  getDevelopmentList,
  getInfoOperationList,
  getPlanProgramList,
  getPublicInfo,
  getSalaamPoliceList,
} from '../../modules/general/action';
export const treeData = [
  {
    title: 'Quarterly',
    value: 'quarterly',
    children: [
      {
        title: '1st Quarter',
        value: 'first_quarter',
      },
      {
        title: '2nd Quarter',
        value: 'second_quarter',
      },
      {
        title: '3rd Quarter',
        value: 'third_quarter',
      },
      {
        title: '4th Quarter',
        value: 'fourth_quarter',
      },
    ],
  },
  {
    title: 'Monthly',
    value: 'monthly',
    children: [
      {
        title: 'January',
        value: 'jan',
      },
      {
        title: 'February',
        value: 'feb',
      },
      {
        title: 'March',
        value: 'mar',
      },
      {
        title: 'April',
        value: 'april',
      },
      {
        title: 'May',
        value: 'may',
      },
      {
        title: 'June',
        value: 'jun',
      },
      {
        title: 'July',
        value: 'jul',
      },
      {
        title: 'August',
        value: 'aug',
      },
      {
        title: 'September',
        value: 'sept',
      },
      {
        title: 'October',
        value: 'oct',
      },
      {
        title: 'November',
        value: 'nov',
      },
      {
        title: 'December',
        value: 'dec',
      },
    ],
  },
  {
    title: 'Annually',
    value: 'annually',
  },
];

export const useDateRange = (dispatch, key) => (e) => {
  console.log(e);
  let date = new Date().getFullYear();

  let params = {
    formatDateStart: Moment(`01/01/${date}`).format('MM/DD/YYYY'),
    formatDateEnd: Moment(new Date()).format('MM/DD/YYYY'),
  };
  if (e === 'first_quarter') {
    params = {
      formatDateStart: `01/01/${date}`,
      formatDateEnd: `03/31/${date}`,
    };
  }
  if (e === 'second_quarter') {
    params = {
      formatDateStart: `04/01/${date}`,
      formatDateEnd: `06/30/${date}`,
    };
  }
  if (e === 'third_quarter') {
    params = {
      formatDateStart: `07/01/${date}`,
      formatDateEnd: `09/30/${date}`,
    };
  }
  if (e === 'fourth_quarter') {
    params = {
      formatDateStart: `10/01/${date}`,
      formatDateEnd: `12/31/${date}`,
    };
  }
  if (e === 'annually') {
    params = {
      formatDateStart: `01/01/${date}`,
      formatDateEnd: `12/31/${date}`,
    };
  }
  if (e === 'jan') {
    params = {
      formatDateStart: `01/01/${date}`,
      formatDateEnd: `01/31/${date}`,
    };
  }
  if (e === 'feb') {
    params = {
      formatDateStart: `02/01/${date}`,
      formatDateEnd: `03/01/${date}`,
    };
  }
  if (e === 'mar') {
    params = {
      formatDateStart: `03/01/${date}`,
      formatDateEnd: `03/31/${date}`,
    };
  }
  if (e === 'april') {
    params = {
      formatDateStart: `04/01/${date}`,
      formatDateEnd: `04/30/${date}`,
    };
  }
  if (e === 'may') {
    params = {
      formatDateStart: `05/01/${date}`,
      formatDateEnd: `05/31/${date}`,
    };
  }
  if (e === 'jun') {
    params = {
      formatDateStart: `06/01/${date}`,
      formatDateEnd: `06/30/${date}`,
    };
  }
  if (e === 'jul') {
    params = {
      formatDateStart: `07/01/${date}`,
      formatDateEnd: `07/31/${date}`,
    };
  }
  if (e === 'aug') {
    params = {
      formatDateStart: `08/01/${date}`,
      formatDateEnd: `08/31/${date}`,
    };
  }
  if (e === 'sept') {
    params = {
      formatDateStart: `09/01/${date}`,
      formatDateEnd: `09/30/${date}`,
    };
  }
  if (e === 'oct') {
    params = {
      formatDateStart: `10/01/${date}`,
      formatDateEnd: `10/31/${date}`,
    };
  }
  if (e === 'nov') {
    params = {
      formatDateStart: `11/01/${date}`,
      formatDateEnd: `11/30/${date}`,
    };
  }
  if (e === 'dec') {
    params = {
      formatDateStart: `12/01/${date}`,
      formatDateEnd: `12/31/${date}`,
    };
  }
  if (key === 'info_operation') {
    console.log(params);
    dispatch(getInfoOperationList(params));
  }
  if (key === 'public_information') {
    console.log(params);
    dispatch(getPublicInfo(params));
  }
  if (key === 'community_affairs') {
    console.log(params);
    dispatch(getCommunityAffairList(params));
  }
  if (key === 'salaam_police') {
    console.log(params);
    dispatch(getSalaamPoliceList(params));
  }
  if (key === 'developement') {
    console.log(params);
    dispatch(getDevelopmentList(params));
  }
  if (key === 'plan_program') {
    console.log(params);
    dispatch(getPlanProgramList(params));
  }
  if (key === 'admin') {
    console.log(params);
    dispatch(getAdminList(params));
  }
};
