import { getAuthHeader, getError, header } from '../../shared/utils';
import {
  API_ADD_SALAAM_POLICE,
  API_EDIT_SALAAM_POLICE,
  API_DELETE_SALAAM_POLICE,
  API_UPDATE_SALAAM_POLICE_STATUS,
} from '../../shared/constant/url';

export const SALAAM = {
  ADD_START: 'ADD_START',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_ERROR: 'ADD_ERROR',

  DELETE_START: 'DELETE_START',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_ERROR: 'DELETE_ERROR',

  EDIT_START: 'EDIT_START',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  EDIT_ERROR: 'EDIT_ERROR',

  UPDATE_SALAAM_POLICE_START: 'UPDATE_SALAAM_POLICE_START',
  UPDATE_SALAAM_POLICE_SUCCESS: 'UPDATE_SALAAM_POLICE_SUCCESS',
  UPDATE_SALAAM_POLICE_ERROR: 'UPDATE_SALAAM_POLICE_ERROR',
};

export const addSalaamPoliceRecord = (params, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALAAM.ADD_START });
    let formData = new FormData();
    formData.append('report_type', params.category);
    formData.append('date_submitted', params.date_submitted);
    formData.append('subject', params.subject);
    if (params.task_id) {
      formData.append('task_id', params.task_id);
    }
    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }
    formData.append('description', params.description);
    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_SALAAM_POLICE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: SALAAM.ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: SALAAM.ADD_ERROR,
          payload: getError(error),
        });
      });
  };
};
export const ArchiveAll = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALAAM.ADD_START });
    let formData = new FormData();

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
    };
    fetch(
      `${API_ADD_SALAAM_POLICE}/archive?report_type=${
        params.report_type ? params.report_type : ''
      }&start_date=${params.start_date ? params.start_date : ''}&end_date=${
        params.end_date ? params.end_date : ''
      }`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: SALAAM.ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: SALAAM.ADD_ERROR,
          payload: getError(error),
        });
      });
  };
};
export const editSalaamPoliceRecord = (params, file, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALAAM.EDIT_START });
    let formData = new FormData();
    if (params.category) {
      formData.append('report_type', params.category);
    }
    if (params.date_submitted) {
      formData.append('date_submitted', params.date_submitted);
    }
    if (params.subject) {
      formData.append('subject', params.subject);
    }
    if (params.description) {
      formData.append('description', params.description);
    }

    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }

    formData.append('_method', 'PUT');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_EDIT_SALAAM_POLICE}${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: SALAAM.EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: SALAAM.EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteSalaamPoliceRecord = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALAAM.DELETE_START });
    let requestOption = {
      method: 'DELETE',
      headers: getAuthHeader(),
    };
    fetch(`${API_DELETE_SALAAM_POLICE}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: SALAAM.DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: SALAAM.DELETE_ERROR, payload: error });
      });
  };
};

export const updateSalaamPoliceStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: SALAAM.UPDATE_SALAAM_POLICE_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: 'PATCH',
      body: data,
      headers: header(),
    };
    fetch(`${API_UPDATE_SALAAM_POLICE_STATUS}${id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: SALAAM.UPDATE_SALAAM_POLICE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: SALAAM.UPDATE_SALAAM_POLICE_ERROR, payload: error });
      });
  };
};
