import React, { useState, useEffect } from "react";
import FormResetPassword from "../components/FormResetPassword";
import StandardLanding from "../../../shared/components/StandardLanding";
import queryString from "qs";

import {
  ImageBackground,
  View,
  Text,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/react-native/UI/js";

const ResetPassword = (props) => {
  const { search } = props.location;
  const query = queryString.parse(search, { ignoreQueryPrefix: true });

  const [w, setW] = useState(UI.getWidth());

  useEffect(() => {
    setW(UI.getWidth());
    window.addEventListener("resize", resize);

    return function cleanup() {
      window.removeEventListener("resize", resize);
    };
  }, [w]);

  const resize = () => {
    setW(UI.getWidth());
  };

  return (
    <StandardLanding>
      <div className='w-100 d-flex justify-content-center align-items-center position-absolute z-index' style={{ height: '100vh', minHeight: '100%', zIndex: '99' }}>
        <div className='d-flex flex-column align-items-center justify-content-center w-100'>
          <ImageBackground
            imageFit={"cover"}
            source={"/images/login_banner.jpg"}
            style={{
              width: w > 1022 ? w / 4 : "100%",
              minHeight: 120,
              padding: 16,
              backgroundColor: "black",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <img
                style={{ height: 80, width: 80 }}
                src={"/images/main_logo.png"}
                alt='logo' />

              <View style={{ marginLeft: 10, lineHeight: '120%' }}>
                <Text
                  style={{ color: "white", fontSize: 15, fontWeight: "bold" }}
                >
                  Police Community Relations
                </Text>
                <Text
                  style={{ color: "white", fontSize: 15, fontWeight: "bold" }}
                >
                  Management Information System
                </Text>
              </View>
            </View>
          </ImageBackground>
          <div className="bg-white rounded-bl-md rounded-br-md w-full lg:w-1/4">
            <FormResetPassword query={query} />
          </div>
          <p className="mt-4 lg-title text-white" style={{ fontSize: '15px' }}>
            © 2022 Directorate for Police Community Relations
          </p>
        </div>
      </div>
      <div className='bg-black'>
        <div
          style={{ background: 'url("images/bg.png")', opacity: '0.75', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}
          className='w-full h-screen'
        />
      </div>
    </StandardLanding>
  );
};

export default ResetPassword;
