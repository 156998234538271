import React from 'react';
import StandardLanding from '../../../shared/components/StandardLanding';
import FormEditPassword from '../components/FormEditPassword';
import queryString from 'qs';


const Landing = (props) => {
    const { search } = props.location;
    const query = queryString.parse(search, { ignoreQueryPrefix: true });
    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">CHANGE PASSWORD</p>
                    <p className="md-title text-gray-20">Change your password here</p>
                </div>
                <div className="mt-2">
                    <FormEditPassword query={query} />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
