import React, { useEffect } from 'react';
import CardProfile from '../components/CardProfile';
import CardReports from '../components/CardReports';
import { onShowProfile } from '../../profile/action';
import { useDispatch, useSelector } from 'react-redux';
import CardAnnouncement from '../components/CardAnnouncement';
import './Landing.scss';
import {
  getAnnouncementList,
  getFeaturedAnnouncementList,
} from '../../announcement/action';
import { getPendingReportList, getRecentReportList } from '../action';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableRecentReport from '../components/TableRecentReport';
import { Fragment } from 'react';

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { showProfile } = state.profile;
  const { listAnnouncement } = state.announcement;
  const { listPendingReport, loading } = state.dashboard;
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    let name = { search: 'featured' };
    dispatch(onShowProfile());
    dispatch(getRecentReportList());
    dispatch(getPendingReportList());
    dispatch(getFeaturedAnnouncementList());
  }, []);

  return (
    <StandardLanding>
      <div className='row'>
        <div className='col-lg-4'>
          <CardProfile showProfile={showProfile} />
        </div>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-6 mb-5'>
              <div className='reportprofile__announcement'>
                <CardAnnouncement listAnnouncement={listAnnouncement} />
              </div>
            </div>
            <div className='col-6 mb-5'>
              <div className='reportprofile__total-pending-reports'>
                {user &&
                  (user.role === 'admin' ||
                    user.role === 'supervisor' ||
                    user.role === 'super_admin') && (
                    <Fragment>
                      <CardReports
                        loading={loading}
                        listPendingReport={listPendingReport}
                        getPendingReportList={getPendingReportList}
                      />
                    </Fragment>
                  )}
              </div>
            </div>
            <div className='col'>
              <div className='reportprofile__recent-report-activity'>
                {user &&
                (user.role === 'admin' ||
                  user.role === 'supervisor' ||
                  user.role === 'super_admin') ? (
                  <TableRecentReport />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardLanding>
  );
};

export default Landing;
