import React, { useEffect } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom';
import Login from '../../modules/auth/containers/Login';
import MainLanding from '../../modules/layout/Landing'
import ForgotPassword from '../../modules/auth/containers/ForgotPassword';
import ResetPassword from '../../modules/auth/containers/ResetPassword';

function AppRouter() {
  const history = useHistory()
  const token = localStorage.getItem('token')

  // useEffect(() => {
  //   if (!token) {
  //     history.push('/')
  //   }
  // }, [])

  return (
    <>
      {token ? <MainLanding /> :

        <Switch>
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/" component={Login} />
        </Switch>
      }
    </>
  );
}

export default AppRouter;
