import React from "react";
import styled from "@emotion/styled";

function FormWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default FormWrapper;

const StyledDiv = styled.div({
 

  ".form-flex": {
    display: 'flex !important',
    flexFlow: 'wrap',
    ".form-icon": {
      position: 'absolute', 
      left: '0',
      top: '0', 
      margin: "1em 1em 0 1.5em",
      zIndex: 10
    },
  },  
  ".ant-input": {
    color: "#4E4E4E",
    border: "1px #C5C5C5 solid",
    borderRadius: "0.5rem",
    padding: "5px 10px 5px 20px",
    height: '3rem'
  },
  ".ant-input-password": {
    color: "#4E4E4E",
    border: "1px #C5C5C5 solid",
    borderRadius: "0.5rem",
    padding: "5px 20px 5px 20px",
  },
  ".ant-select": {
    color: "#4E4E4E",
    border: "1px #C5C5C5 solid",
    borderRadius: "0.5rem",
    padding: "5px 10px 5px 0px",
  },
  ".ant-form-item-control-input-content": {
    background: "#FFF !important",
    borderRadius: "0.5rem",
  },
  ".ant-picker": {
    color: "#4E4E4E",
    border: "1px #C5C5C5 solid",
    borderRadius: "0.5rem",
    padding: "5px 20px 5px 20px",
    width: "100%"
  },
  ".ant-input-number": {
    color: "#4E4E4E",
    border: "2px #C5C5C5 solid",
    borderRadius: "0.5rem",
    padding: "10px 10px 10px 40px",
  },
  ".ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    border: "0"
  }, 
  ".ant-form-item-label": {
    marginTop: "-17px !important",
    position: "absolute !important",
    top: "0 !important",
    zIndex: "10",
    marginLeft: "10px",
    padding: "0px 5px",
  },
  ".ant-form-item .ant-form-item-label > label": {
    color: "#00539A",
    background: "#FFF",
    height: "20px !important", 
    fontSize: "16px"
  },
});
