import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Table, Popconfirm, message, Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from '../../../shared/components/Style';
import FormSearch from './FormSearch';
import { useDispatch } from 'react-redux';
import TableWrapper from '../../../shared/components/TableWrapper';
import { updateInfoOperationStatus } from '../action';
import { list } from 'postcss';
import { AiFillEye } from 'react-icons/ai';
import {
  BsFillPencilFill,
  BsCheckLg,
  BsArrowCounterclockwise,
} from 'react-icons/bs';
import { approveStatus } from '../../general/action';

const TableInformationOperation = (props) => {
  const his = useHistory();
  const dispatch = useDispatch();
  // console.log(props.role)
  const user = JSON.parse(localStorage.getItem('user'));

  const [id, setId] = useState('');
  const [dataListInfo, setDataListInfo] = useState();
  const [renderedInfo, setRenderedInfo] = useState();
  const {
    listInfo,
    loading,
    reportType,
    listUnit,
    deleteInfoOperation,
    getInfoOperationList,
    setActivityLogList,
    setSearch,
    search,
  } = props;
  const [modalShow, setModalShow] = React.useState(false);
  const [ReportType, setReportType] = useState({ title: '', id: '' });
  const [typeFilter, setTypeFilter] = useState('');
  const [unitFilter, setUnitFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  useEffect(() => {
    if (props) {
      if (!renderedInfo) {
        if (props?.listInfo?.length > 0) {
          setRenderedInfo(props.listInfo);
        }
      }

      if (dataListInfo != listInfo) {
        setDataListInfo(listInfo);
        setRenderedInfo(listInfo);
      }

      setDataListInfo(props.listInfo);
      // console.log(props);
      // console.log(renderedInfo);
    }

    // console.log("listInfo", listInfo);
  }, [props, listInfo, renderedInfo]);

  useEffect(() => {
    renderSearch(typeFilter, unitFilter, dateFilter);
  }, [typeFilter, unitFilter, dateFilter]);

  const columns = [
    {
      title: 'Name/User',
      dataIndex: 'name',
      key: 'id',
    },
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'id',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'id',
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'id',
    },
    {
      title: 'Details',
      dataIndex: 'description',
      key: 'id',
    },
    {
      title: 'Date and Time',
      dataIndex: 'date',
      key: 'id',
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'x',
    //   key: 'x',
    //   render: (data, rowData) => renderAction(rowData),
    // },
  ];

  const onApprove = () => {
    let param = { status: 'approved' };
    dispatch(updateInfoOperationStatus(param, id, callback));
  };

  const onDecline = () => {
    let param = { status: 'declined' };
    dispatch(updateInfoOperationStatus(param, id, callback));
  };

  const menu = (
    <div className='flex flex-col'>
      <button
        style={styled.approved}
        className='mb-2 px-1'
        onClick={onApprove}
      >
        Approve
      </button>
      <button
        style={styled.declined}
        onClick={onDecline}
      >
        Decline
      </button>
    </div>
  );

  const onDeleteItem = (id) => {
    dispatch(deleteInfoOperation(id, callback));
  };

  const callback = (response, error) => {
    console.log(response);
    let res = response.message;
    if (res === 'Request failed with status code 403') {
      message.error('You do not have the required authorization.');
    } else {
      dispatch(getInfoOperationList());
    }
  };

  const renderStatus = (data) => {
    switch (data.status) {
      case 'pending':
        return <p style={styled.pending}>{data.status}</p>;
        break;
      case 'approved':
        return <p style={styled.approved}>{data.status}</p>;
        break;
      case 'declined':
        return <p style={styled.declined}>{data.status}</p>;
    }
  };

  const download_file = async (url) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderDropdown = (data) => {
    if (data.status === 'pending' && user.role === 'admin') {
      return (
        <Popover
          content={menu}
          trigger='click'
          placement='bottom'
        >
          <button onClick={() => setId(data.id)}>
            <i
              style={styled.btn}
              className='fas fa-caret-down bg-green-10'
            ></i>
          </button>
        </Popover>
      );
    }
  };

  const renderAction = (data) => {
    return (
      <div
        className='d-flex flex-row flex-nowrap align-items-center'
        style={{ gap: '1em' }}
      >
        {/* <Link to="/summary-report">
                    <i style={styled.btn} className="far fa-eye bg-green-10"></i>
                </Link> */}

        <div
          onClick={() => {
            download_file(data.file.url);
          }}
          style={{ width: '2rem' }}
        >
          <AiFillEye
            className='py-2 px-1 rounded'
            style={{ backgroundColor: '#339ce2' }}
            color='white'
            size={35}
          />
        </div>
        <button
          disabled={
            data.status !== 'approved' || props.role !== 'supervisor'
              ? false
              : true
          }
          onClick={() => {
            his.push(`/edit-info/${data.id}`);
          }}
          style={{ width: '2rem' }}
        >
          <BsFillPencilFill
            className=' py-2 px-1 rounded'
            color='white'
            size={35}
            style={{
              background:
                data.status !== 'approved' || props.role !== 'supervisor'
                  ? 'black'
                  : 'grey',
              cursor: 'pointer',
            }}
          />
        </button>

        {/* <Popconfirm
          placement='top'
          title='Are you sure you want to delete this?'
          onConfirm={() => onDeleteItem(data.id)}
          okText='Yes'
          cancelText='No'
        >
          <button style={{ width: '2rem' }}>
            <i
              style={{ ...styled.btn, margin: '0', width: '100%' }}
              className='far fa-trash-alt bg-red-10'
            ></i>
          </button>
        </Popconfirm> */}
        {/* <Popconfirm
          placement='top'
          title='Are you sure you want to delete this?'
          onConfirm={() => onDeleteItem(data.id)}
          okText='Yes'
          cancelText='No'
          style={{ width: '2rem' }}
        >
          <BsArrowCounterclockwise
            className=' py-2 px-1 rounded'
            color='white'
            size={35}
            style={{ background: '#e1cb06' }}
          />
        </Popconfirm> */}
      </div>
    );
  };

  const renderSearch = (type = '', unit = '', date = '') => {
    let temporaryData = dataListInfo;
    let finalData;

    if (temporaryData) {
      if (type && !unit && !date) {
        const filteredData = temporaryData.filter((data) => {
          return data.report_type == type;
        });
        finalData = filteredData;
      }

      if (unit && !type && !date) {
        const filteredData = temporaryData.filter((data) => {
          return data.unit == unit;
        });
        finalData = filteredData;
      }

      if (date && !unit && !type) {
        const resData = selectDateType(date, temporaryData);
        finalData = resData;
      }

      if (type && unit) {
        const filteredData = temporaryData.filter((data) => {
          return data.report_type == type && data.unit == unit;
        });
        finalData = filteredData;
      }

      if (unit && date) {
        const unitFiltered = temporaryData.filter((data) => {
          return data.unit == unit;
        });

        const resData = selectDateType(date, unitFiltered);
        finalData = resData;
      }

      if (type && date) {
        const unitFiltered = temporaryData.filter((data) => {
          return data.report_type == type;
        });

        const resData = selectDateType(date, unitFiltered);
        finalData = resData;
      }

      if (type && unit && date) {
        const notFinalData = temporaryData.filter((data) => {
          return data.report_type == type && data.unit == unit;
        });

        const resData = selectDateType(date, notFinalData);
        finalData = resData;
      }
    }

    setRenderedInfo(finalData);
  };

  const selectDateType = (e, temporaryData) => {
    if (e == 'daily') {
      return weekFilterByDaily(temporaryData);
    } else if (e == 'weekly') {
      return weekFilterByWeekly(temporaryData);
    } else if (e == 'monthly') {
      return weekFilterByMonthly(temporaryData);
    }
  };

  const weekFilterByDaily = (temporaryData) => {
    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.date_submitted.split(' ');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);
      const currentDate = new Date();

      const dataDay = newDate.getDate();
      const currentDay = currentDate.getDate();

      const getDataMonth = newDate.toLocaleString('en-US', {
        month: 'long',
      });
      const getCurrentMonth = currentDate.toLocaleString('en-US', {
        month: 'long',
      });

      const getDataYear = newDate.getFullYear();
      const getCurrentYear = currentDate.getFullYear();

      return (
        dataDay == currentDay &&
        getDataMonth == getCurrentMonth &&
        getDataYear == getCurrentYear
      );
    });

    return filteredData;
  };

  const weekFilterByWeekly = (temporaryData) => {
    const currentDate = new Date();

    let dataWeek = [];
    for (let i = 1; i <= 7; i++) {
      let first = currentDate.getDate() - currentDate.getDay() + i;
      let day = new Date(currentDate.setDate(first)).toISOString().slice(0, 10);
      dataWeek.push(day);
    }

    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.date_submitted.split(' ');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);

      const convertedDay = () => {
        return (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
      };
      const convertedMonth = () => {
        return (
          (newDate.getMonth() < 10 ? '0' : '') +
          (parseInt(newDate.getMonth()) + 1).toString()
        );
      };

      const fullDate = `${newDate.getFullYear()}-${convertedMonth()}-${convertedDay()}`;

      let isTrue = false;
      dataWeek.forEach((week) => {
        if (week == fullDate) {
          isTrue = true;
        }
      });

      return isTrue ? data : '';
    });

    return filteredData;
  };

  const weekFilterByMonthly = (temporaryData) => {
    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.date_submitted.split(' ');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);
      const currentDate = new Date();

      const getDataMonth = newDate.toLocaleString('en-US', {
        month: 'long',
      });
      const getCurrentMonth = currentDate.toLocaleString('en-US', {
        month: 'long',
      });

      const getDataYear = newDate.getFullYear();
      const getCurrentYear = currentDate.getFullYear();

      return getDataMonth == getCurrentMonth && getDataYear == getCurrentYear;
    });

    return filteredData;
  };
  const onSubmitHandler = (id, statusresponse) => {
    // e.preventDefault();
    console.log(id, statusresponse);
    const params = { id: id, status: statusresponse, remarks: 'test' };
    // dispatch(approveStatus(params, callback));
    setModalShow(false);
  };

  return (
    <>
      <div className='sub-container p-5'>
        {props && (
          <>
            <FormSearch
              reportType={reportType}
              listUnit={listUnit}
              onUnitChange={(e) => setUnitFilter(e)}
              onReportTypeChange={(e) => setTypeFilter(e)}
              onDateChange={(e) => setDateFilter(e)}
              setActivityLogList={setActivityLogList}
              setSearch={setSearch}
              search={search}
            />
            <TableWrapper>
              <Table
                dataSource={getInfoOperationList}
                loading={loading}
                columns={columns}
                scroll={{ x: 1000 }}
              />
            </TableWrapper>
          </>
        )}
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          className='d-flex justify-center'
          closeButton
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            <span className='text-center'> {ReportType.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Control
                type='text'
                placeholder='Remarks'
              />
            </Form.Group>

            <div className='d-flex justify-center mt-5'>
              <Button
                className='px-4 mx-2 py-2'
                onClick={(e) => onSubmitHandler(ReportType.id, 1)}
              >
                Approve
              </Button>
              <Button
                className='bg-white px-4 mx-2 py-2  text-primary'
                onClick={(e) => onSubmitHandler(ReportType.id, 2)}
              >
                Decline
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TableInformationOperation;
