import React from 'react';
import JSZip from 'jszip';
const useDownloadFile = (file) => {
  console.log(file);
  var zip = new JSZip();
  for (var i = 0; i < file.length; i++) {
    window.open(file[i].url, file[i].name);

    console.log(file[i].url);
  }
  // const a = document.createElement('a');
  // a.href = url;
  // a.download = url.split('/').pop();
  // document.body.appendChild(a);
  // a.click();
  // document.body.removeChild(a);

  // zip.file('Hello.txt', 'Hello World\n');
  // var img = zip.folder('images');
  // img.file(file[0].url, file, { base64: true });
  // zip.generateAsync({ type: 'blob' }).then(function (content) {
  //   saveAs(content, 'example.zip');
  // });
  // zip.generateAsync().then(function (content) {
  //   saveAs(content, 'zipFilename');
  // });

  // const link = document.createElement('a');
  // link.setAttribute('download', null);
  // link.style.display = 'none';
  // document.body.appendChild(link);
  // for (var i = 0; i < file.url.length; i++) {
  //   link.setAttribute('href', file.url[i]);
  //   link.click();
  // }
  // file.map((dd) => {
  //   fetchFile(url).then((file) => exportFile(file));
  //   a.href = dd.url;
  //   a.download = dd.url.split('/').pop();
  //   a.target = '_blank';
  //   console.log(a);

  //   a.click();
  //   document.body.removeChild(a);
  // });
};

export default useDownloadFile;
