import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Avatar, Card, message, Popover } from 'antd';
import { useDispatch } from 'react-redux';
import { logout } from '../../auth/action';
import { fetchAllNotification } from '../../view-all-notification/request';
import './PageHeader.css';
const PageHeader = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const { showSideBar, showProfile } = props;

  const onLogout = () => {
    dispatch(logout(callback));
  };

  const callback = (response) => {
    if (response.message === 'Successfully logged out!') {
      history.push('/');
      message.success('Successfully logged out!');
    }
  };
  const content = (
    <div>
      <Link to='/forgot-password'>
        <p className='text-center'>Change password</p>
      </Link>
    </div>
  );

  const [notification, setNotification] = useState([]);
  const [unread_count, setUnread_count] = useState();
  const getData = async () => {
    const result = await fetchAllNotification();
    const { data } = result;
    setUnread_count(data.unread_count);
    setNotification(data.data);
  };
  const redirectionHandler = (type, attributes) => {
    console.log(type);
    console.log(attributes);
    if (type === 'information_operations') {
      history.push(`add-info?task_id=${attributes?.payload?.id}&type=${type}`);
    }
    if (type === 'public_informations') {
      history.push(
        `add-public-info?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'community-affairs') {
      history.push(
        `add-community-affair?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'salaam_polices') {
      history.push(
        `add-salaam-police?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'developments') {
      history.push(
        `add-development?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'plans_programs') {
      history.push(
        `add-program?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'admins') {
      history.push(`add-admin?task_id=${attributes?.payload?.id}&type=${type}`);
    }
  };
  useEffect(() => {
    getData();
    setNotification(notification);
  }, []);
  //
  const notification_content = (
    <div>
      {notification.map((e) => {
        const { attributes, title } = e;
        const { payload } = attributes;

        const { type } = attributes;

        return (
          <Card
            className='header-card'
            onClick={() => redirectionHandler(type, attributes)}
          >
            <div className='row'>
              <div
                className='col-2'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  style={{ width: 50, height: 50 }}
                  src={attributes.image}
                />
              </div>
              <div className='col-7'>
                <span
                  style={{
                    padding: '0px 10px 0px 10px',

                    height: 'auto',
                    wordBreak: 'break-word',
                    textAlign: 'start',
                  }}
                >
                  <p className='font-bold'>{attributes.title}</p>
                  <p>{attributes.body}</p>
                  <p>
                    <span className='fw-bold'>Type</span>:
                    {attributes.type.replace('_', '-')}
                  </p>
                </span>
              </div>
              <div
                className='col-3'
                style={{
                  display: 'flex',

                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <p>{attributes.created.human}</p>
              </div>
            </div>
          </Card>
        );
      })}

      <div className='text-center w-full py-2'>
        <Link to='/notification'>
          <p>See all notification</p>
        </Link>
      </div>
    </div>
  );

  return (
    <div
      className='d-flex'
      style={{ justifyContent: 'center', alignItems: 'center', gap: '10px' }}
    >
      <Popover
        placement='bottomLeft'
        content={notification_content}
        title={<p className='text-center font-bold'>Notifications</p>}
      >
        <button>
          <i
            className='far fa-bell mr-5'
            style={{ position: 'relative', fontSize: '25px' }}
          >
            <span
              className=' text-white p-1'
              style={{
                borderRadius: unread_count === 0 ? '' : '50%',
                fontSize: unread_count === 0 ? '' : '10px',
                position: unread_count === 0 ? '' : 'absolute',
                top: unread_count === 0 ? '' : '-27%',
                right: unread_count === 0 ? '' : '-33%',
                backgroundColor: unread_count === 0 ? '' : '#D94647',
              }}
            >
              {unread_count === 0 ? '' : unread_count}
            </span>
          </i>
        </button>
      </Popover>
      <Popover
        content={content}
        title={<p className='text-center font-bold'>Settings</p>}
      >
        <button>
          <i
            className='fas fa-cog mr-5'
            style={{ position: 'relative', fontSize: '25px' }}
          ></i>
        </button>
      </Popover>
      <Avatar
        src={showProfile?.file ? showProfile?.file : './images/pnplogo.png'}
        className='mr-5'
        style={{ position: 'relative', fontSize: '25px' }}
      />
      <i
        onClick={onLogout}
        className='fas fa-sign-out-alt cursor-pointer'
        style={{ position: 'relative', fontSize: '25px' }}
      ></i>
    </div>
  );
};

export default PageHeader;
