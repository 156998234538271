import React, { useEffect } from 'react';
import FormAddEdit from '../components/FormAddEdit';
import StandardLanding from '../../../shared/components/StandardLanding';
import { onShowAnnouncement } from '../action';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addAnnouncement, editAnnouncement } from '../action';

const Landing = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(onShowAnnouncement(id));
        }
    }, []);

    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">ANNOUNCEMENTS</p>
                    <p className="md-title text-gray-20">Add new announcement here</p>
                </div>
                <div className="mt-2">
                    <FormAddEdit
                        addAnnouncement={addAnnouncement}
                        editAnnouncement={editAnnouncement}
                    />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
