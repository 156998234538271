import {
  authHeader,
  getAuthHeader,
  getError,
  header,
} from '../../shared/utils';
import {
  API_ADD_INFORMATION_OPERATION,
  API_EDIT_INFORMATION_OPERATION,
  API_DELETE_INFORMATION_OPERATION,
  API_UPDATE_INFORMATION_OPERATION_STATUS,
} from '../../shared/constant/url';

export const INFO = {
  ADD_START: 'ADD_START',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_ERROR: 'ADD_ERROR',

  DELETE_START: 'DELETE_START',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_ERROR: 'DELETE_ERROR',

  EDIT_START: 'EDIT_START',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  EDIT_ERROR: 'EDIT_ERROR',

  UPDATE_INFORMATION_OPERATION_START: 'UPDATE_INFORMATION_OPERATION_START',
  UPDATE_INFORMATION_OPERATION_SUCCESS: 'UPDATE_INFORMATION_OPERATION_SUCCESS',
  UPDATE_INFORMATION_OPERATION_ERROR: 'UPDATE_INFORMATION_OPERATION_ERROR',
};

export const addNewInfoRecord = (params, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.ADD_START });
    let formData = new FormData();
    formData.append('report_type', params.category);
    formData.append('date_submitted', params.date_submitted);
    formData.append('subject', params.subject);
    formData.append('description', params.description);
    if (params.task_id) {
      formData.append('task_id', params.task_id);
    }
    for (let i = 0; i < file.length; i++) {
      formData.append(`file[${i}]`, file[i]);
    }

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_INFORMATION_OPERATION, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: INFO.ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: INFO.ADD_ERROR,
          payload: getError(error),
        });
      });
  };
};
export const ArchiveAll = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.ADD_START });
    let formData = new FormData();

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
    };
    fetch(
      `${API_ADD_INFORMATION_OPERATION}/archive?report_type=${
        params.report_type ? params.report_type : ''
      }&start_date=${params.start_date ? params.start_date : ''}&end_date=${
        params.end_date ? params.end_date : ''
      }`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: INFO.ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: INFO.ADD_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editInfoRecord = (params, file, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.EDIT_START });
    let formData = new FormData();
    if (params.category) {
      formData.append('report_type', params.category);
    }
    if (params.date_submitted) {
      formData.append('date_submitted', params.date_submitted);
    }
    if (params.subject) {
      formData.append('subject', params.subject);
    }
    if (params.description) {
      formData.append('description', params.description);
    }

    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }

    formData.append('_method', 'PUT');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_EDIT_INFORMATION_OPERATION}${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: INFO.EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: INFO.EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteInfoOperation = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.DELETE_START });
    let requestOption = {
      method: 'DELETE',
      headers: getAuthHeader(),
    };
    fetch(`${API_DELETE_INFORMATION_OPERATION}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: INFO.DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: INFO.DELETE_ERROR, payload: error });
      });
  };
};

export const updateInfoOperationStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.UPDATE_INFORMATION_OPERATION_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: 'PATCH',
      body: data,
      headers: header(),
    };
    fetch(
      `${API_UPDATE_INFORMATION_OPERATION_STATUS}${id}/status`,
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: INFO.UPDATE_INFORMATION_OPERATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({
          type: INFO.UPDATE_INFORMATION_OPERATION_ERROR,
          payload: error,
        });
      });
  };
};
