import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import StandardLanding from "../../../shared/components/StandardLanding";
import FormAddEditRecord from "../../general/FormAddEditRecord";
import { addPlanRecord, editPlanRecord } from "../action";
import { showPlanProgram } from "../../general/action";

const Landing = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(showPlanProgram(id, callback));
    }
  }, []);

  const callback = () => { };

  return (
    <StandardLanding>
      <div className="main-container content-crop">
        <div className="flex justify-between">
          <p className="lg-title text-blue-10">AOPBE</p>
          <p className="md-title text-gray-20">Add new report here</p>
        </div>
        <div className="mt-2">
          <FormAddEditRecord
            editPlanRecord={editPlanRecord}
            addPlanRecord={addPlanRecord}
          />
        </div>
      </div>
    </StandardLanding>
  );
};

export default Landing;
