import {
  getError,
  getAuthHeader,
  axios,
  authHeader,
  header,
} from '../../shared/utils';
import {
  API_LOGIN,
  API_FORGOT_PASSWORD,
  API_RESET_PASSWORD,
  PRIVACYPOLICY,
  BASE_URL,
} from '../../shared/constant/url';

export const AUTH = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

  LOGOUT: 'LOGOUT',
};
export const updatePrivacyPolicy = async () => {
  console.log('authHeader: ', authHeader());
  const result = await axios.get(`${PRIVACYPOLICY}`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return result;
};

export const login = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.LOGIN_START });
    let formData = new FormData();
    formData.append('email', params.email);
    formData.append('password', params.password);
    let reqOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(API_LOGIN, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        callback(response, null);
        dispatch({ type: AUTH.LOGIN_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({ type: AUTH.LOGIN_ERROR, payload: getError(error) });
      });
  };
};

export const logout = (callback = null) => {
  let responses = { message: 'Successfully logged out!' };

  return (dispatch) => {
    let requestOption = {
      method: 'DELETE',
      headers: getAuthHeader(),
    };
    fetch(`${BASE_URL}/api/v1/logout`, requestOption)
      .then((response) => {
        callback(responses);
        dispatch({ type: AUTH.LOGOUT });
        localStorage.clear();
      })
      .catch((error) => {
        callback(error, getError(error));
      });
  };
};

export const forgotPassword = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.FORGOT_PASSWORD_START });
    let formData = new FormData();
    formData.append('email', params.email);
    let reqOptions = {
      method: 'POST',
      body: formData,
    };
    fetch(API_FORGOT_PASSWORD, reqOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: AUTH.FORGOT_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.FORGOT_PASSWORD_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const resetPassword = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: AUTH.RESET_PASSWORD_START });
    let formData = new FormData();
    formData.append('password', params.password);
    formData.append('password_confirmation', params.password_confirmation);
    let reqOptions = {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    };
    fetch(
      `${API_RESET_PASSWORD}token=${params.token}&email=${params.email}`,
      reqOptions,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: AUTH.RESET_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: AUTH.RESET_PASSWORD_ERROR,
          payload: getError(error),
        });
      });
  };
};
