import { Avatar, Badge, Button, Card, Modal, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchAllNotification } from './request';
import './index.css';
const ViewAllNotification = () => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 5,
    total_pages: 10,
  });

  const [fullDetails, setFullDetails] = useState({
    attributes: {
      payload: {
        status: '',
        title: '',
        description: '',
        posted_by: '',
        date_published: '',
      },
    },
  });
  const [notificationData, setNotificationData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [current, setCurrent] = useState(1);

  const showAllNotification = async () => {
    const result = await fetchAllNotification(
      pagination.current_page,
      pagination.per_page,
    );
    const { data } = result;
    console.log('per_page', data.meta.per_page);
    console.log('total_pages', data.meta.total);
    setPagination((prev) => {
      return {
        ...prev,
        per_page: data.meta.per_page,
        total_pages: data.meta.total,
      };
    });
    console.log(data.data);
    setNotificationData(data.data);
  };

  useEffect(() => {
    showAllNotification();
  }, []);

  const onPaginate = async (page) => {
    console.log(page);
    const result = await fetchAllNotification(page, pagination.per_page);
    console.log(result.data);
    setNotificationData(result.data.data);
  };
  const redirectionHandler = (type, attributes) => {
    console.log(type);
    console.log(attributes);
    if (type === 'information_operations') {
      history.push(`add-info?task_id=${attributes?.payload?.id}&type=${type}`);
    }
    if (type === 'public_informations') {
      history.push(
        `add-public-info?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'community-affairs') {
      history.push(
        `add-community-affair?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'salaam_polices') {
      history.push(
        `add-salaam-police?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'developments') {
      history.push(
        `add-development?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'plans_programs') {
      history.push(
        `add-program?task_id=${attributes?.payload?.id}&type=${type}`,
      );
    }
    if (type === 'admins') {
      history.push(`add-admin?task_id=${attributes?.payload?.id}&type=${type}`);
    }
  };
  return (
    <>
      <Modal
        // title={fullDetails.attributes.title}
        visible={showDialog}
        onOk={() => {
          setShowDialog(false);
        }}
        onCancel={() => {
          setShowDialog(false);
        }}
      >
        <div className='mt-5'>
          {fullDetails.attributes.type === 'announcements' && (
            <Badge.Ribbon
              color={
                fullDetails.attributes.payload.status === 'featured'
                  ? 'green'
                  : 'red'
              }
              text={fullDetails.attributes.payload.status}
              className='site-badge-count-109'
            />
          )}
          <p className='pt-2 pb-2 font-bold'>
            {fullDetails.attributes.payload.title}
          </p>
          <hr className='my-4' />
          <div className='pt-4'>
            {/* <h1 className='text-xl font-medium pb-2'>
            {fullDetails.attributes.payload.title ||
              fullDetails.attributes.payload.attributes?.title}
          </h1> */}
            <p className='py-2 text-lg'>
              {fullDetails.attributes.payload.description ||
                fullDetails.attributes.payload.attributes?.description}
            </p>

            <hr className='my-4' />
            {fullDetails.attributes.type === 'announcements' && (
              <div className='flex'>
                <p className='py-2'>
                  <span className='font-bold w-6/12'>Posted By:</span>{' '}
                  {fullDetails.attributes.payload.posted_by}
                </p>
                <p className='py-2 text-right w-6/12'>
                  <span className='font-bold'>Date published:</span>{' '}
                  {fullDetails.attributes.payload.date_published}
                </p>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <div>
        <div className='flex py-3 h-auto'>
          <h1 className='w-1/2 text-blue-900 text-3xl'>Notification</h1>
          <p className='w-1/2 text-gray-500 text-right text-lg font-medium'>
            View all notification
          </p>
        </div>
        <div className=''>
          {notificationData.map((e, k) => {
            const { attributes, type, title } = e;

            return (
              <Card
                className='rounded-xl h-auto header-card-view-all-notification'
                onClick={() => redirectionHandler(attributes.type, attributes)}
              >
                <div className='row'>
                  <div className='col-1'>
                    <Avatar
                      src={attributes.image}
                      className='w-24 h-auto'
                    />
                  </div>
                  <div className='col-9 px-5 text-left'>
                    <p className='h6 fw-bold'>{attributes.title}</p>
                    <label className='mb-3'>{attributes.body}</label>
                    <br />
                    <label className='fw-bold mb-2'>Type</label>:
                    <span className='mb-2'>
                      {' '}
                      {attributes.type.replace('_', '-')}
                    </span>
                    <br />
                    <label>{attributes.created.human}</label>
                  </div>
                  <div
                    className='col w-20 col-2'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      className='text-white'
                      style={{ backgroundColor: '#005a9a' }}
                      onClick={() => {
                        setFullDetails(e);
                        setShowDialog(true);
                      }}
                    >
                      View details
                    </Button>
                  </div>
                </div>
              </Card>
            );
          })}
          <Pagination
            className='mt-2'
            showTitle='Pigination'
            defaultCurrent={pagination.current_page}
            total={pagination.total_pages}
            pageSize={pagination.per_page}
            // pageSize={4}
            onChange={(e) => onPaginate(e)}
          />
        </div>
      </div>
    </>
  );
};

export default ViewAllNotification;
