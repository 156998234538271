import { PUBLICINFO } from './action';

const INITIAL_STATE = {
    loading: false,
    error: '',
    isLoggedIn: false,
};


const publicinfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        

        default:
            return state;
    }
}

export default publicinfo;


