import axios from 'axios';
import { VIEW_ALL_NOTIFICATION } from '../../shared/constant/url';
import { authHeader } from '../../shared/utils';

export const fetchAllNotification = async (page, per_page) => {
  console.log('authHeader: ', authHeader());
  const result = await axios.get(
    `${VIEW_ALL_NOTIFICATION}?page=${page ? page : ''}&per_page=${
      per_page ? per_page : ''
    }`,
    authHeader(),
  );
  return result;
};
