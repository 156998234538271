import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Select } from 'antd';
import { Link } from 'react-router-dom';
import FormWrapper from '../../../shared/components/FormWrapper';
import { getCategoriesReport } from '../../general/action';
import { useState } from 'react';
const { Option } = Select;
const FormSearch = (props) => {
  const dispatch = useDispatch();
  const {
    getAnnouncementList,
    categories,
    categoryID,
    setCategories,
    setCategoryID,
    setSearch,
    setReportTypeID,
    reportTypeID,
    reportType,
    setTableType,
    tableType,
    setHotReloadStart,
    hotReloadStart,
  } = props;

  const onFinish = (value) => {
    getAnnouncementList(value);
  };
  useEffect(() => {
    dispatch(getCategoriesReport(Callback));
  }, []);
  const Callback = (response) => {
    setCategories(response.data);
  };
  return (
    <FormWrapper>
      <Form
        onFinish={onFinish}
        className='md:flex'
      >
        <Link to='/add-reference'>
          <Button className='bg-blue-10 text-white h-11 w-full md:w-auto mb-5 md:mb-0 md:mr-5'>
            Add Record&nbsp;<i className='fas fa-plus'></i>
          </Button>
        </Link>
        <Form.Item className='md:w-80 mr-3'>
          <Select
            value={tableType}
            defaultValue=''
            style={{
              width: 180,
            }}
            onChange={(e) => {
              if (!hotReloadStart) {
                setHotReloadStart(true);
              }
              setTableType(e);
            }}
          >
            <Option
              hidden
              value=''
            >
              Select Table Type
            </Option>
            <Option value='report_types'>Report Type</Option>
            <Option value='ranks'>Rank</Option>
            <Option value='units'>Unit</Option>
            {/* {categories.map((data) => {
              return <Option value={data.id}>{data.name}</Option>;
            })} */}
          </Select>
        </Form.Item>
        {/* {tableType !== 'report_types' && (
          <Form.Item className='md:w-80 mr-3'>
            <Select
              value={reportTypeID}
              defaultValue=''
              style={{
                width: 180,
              }}
              onChange={(e) => setReportTypeID(e)}
            >
              <Option
                hidden
                value=''
              >
                Report Type
              </Option>
              {reportType.map((data) => {
                return <Option value={data.id}>{data.name}</Option>;
              })}
            </Select>
          </Form.Item>
        )} */}

        {tableType === 'report_types' && (
          <Form.Item className='md:w-80 mr-3'>
            <Select
              value={categoryID}
              defaultValue=''
              style={{
                width: 200,
                marginLeft: '25%',
              }}
              onChange={(e) => setCategoryID(e)}
            >
              <Option
                hidden
                value=''
              >
                Category Type
              </Option>
              {categories.map((data) => {
                return <Option value={data.id}>{data.name}</Option>;
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name='search'
          className='md:w-80 md:mr-20'
        >
          <Input
            placeholder='Search here'
            className='h-11'
            onChange={(e) => {
              if (!hotReloadStart) {
                setHotReloadStart(true);
              }
              setSearch(e.target.value);
            }}
          />
        </Form.Item>
        <Button htmlType='submit'></Button>
      </Form>
    </FormWrapper>
  );
};

export default FormSearch;
