import { getAuthHeader, getError, header } from '../../shared/utils';
import {
  API_SHOW_ADMIN,
  API_ADMIN_LIST,
  API_REPORT_TYPE,
  API_SHOW_DEVELOPMENT,
  API_DEVELOPMENT_LIST,
  API_SHOW_PLAN_PROGRAM,
  API_PLAN_PROGRAM_LIST,
  API_SHOW_SALAAM_POLICE,
  API_SALAAM_POLICE_LIST,
  API_COMMUNITY_AFFAIR_LIST,
  API_SHOW_COMMUNITY_AFFAIR,
  API_PUBLIC_INFORMATION_LIST,
  API_SHOW_PUBLIC_INFORMATION,
  API_SHOW_INFORMATION_OPERATION,
  API_LIST_INFORMATION_OPERATION,
  API_INFORMATION_OPERATION_STATUS,
  API_LIST_CATEGORIES_LIST,
  API_LIST_ARCHIVE_LIST,
  API_URL,
} from '../../shared/constant/url';

export const GENERAL = {
  PUBLIC_INFO_START: 'SUMMARY_START',
  PUBLIC_INFO_SUCCESS: 'PUBLIC_INFO_SUCCESS',
  PUBLIC_INFO_ERROR: 'PUBLIC_INFO_ERROR',

  LIST_INFO_START: 'LIST_INFO_START',
  INFO_STATUS: 'INFO_STATUS',
  LIST_INFO_SUCCESS: 'LIST_INFO_SUCCESS',
  LIST_INFO_ERROR: 'LIST_INFO_ERROR',

  LISTARCHIVE_INFO_START: 'LISTARCHIVE_INFO_START',

  LISTARCHIVE_INFO_SUCCESS: 'LISTARCHIVE_INFO_SUCCESS',
  LISTARCHIVE_INFO_ERROR: 'LISTARCHIVE_INFO_ERROR',

  LIST_ADMIN_START: 'LIST_ADMIN_START',
  LIST_ADMIN_SUCCESS: 'LIST_ADMIN_SUCCESS',
  LIST_ADMIN_ERROR: 'LIST_ADMIN_ERROR',

  LIST_PLAN_PROGRAM_START: 'LIST_PLAN_PROGRAM_START',
  LIST_PLAN_PROGRAM_SUCCESS: 'LIST_PLAN_PROGRAM_SUCCESS',
  LIST_PLAN_PROGRAM_ERROR: 'LIST_PLAN_PROGRAM_ERROR',

  LIST_DEVELOPMENT_START: 'LIST_DEVELOPMENT_START',
  LIST_DEVELOPMENT_SUCCESS: 'LIST_DEVELOPMENT_SUCCESS',
  LIST_DEVELOPMENT_ERROR: 'LIST_DEVELOPMENT_ERROR',

  LIST_SALAAM_POLICE_START: 'LIST_SALAAM_POLICE_START',
  LIST_SALAAM_POLICE_SUCCESS: 'LIST_SALAAM_POLICE_SUCCESS',
  LIST_SALAAM_POLICE_ERROR: 'LIST_SALAAM_POLICE_ERROR',

  LIST_COMMUNITY_AFFAIR_START: 'LIST_COMMUNITY_AFFAIR_START',
  LIST_COMMUNITY_AFFAIR_SUCCESS: 'LIST_COMMUNITY_AFFAIR_SUCCESS',
  LIST_COMMUNITY_AFFAIR_ERROR: 'LIST_COMMUNITY_AFFAIR_ERROR',

  LIST_REPORT_START: 'LIST_REPORT_START',
  LIST_REPORT_SUCCESS: 'LIST_REPORT_SUCCESS',
  LIST_REPORT_ERROR: 'LIST_REPORT_ERROR',

  INFO_SHOW_START: 'INFO_SHOW_START',
  INFO_SHOW_SUCCESS: 'INFO_SHOW_SUCCESS',
  INFO_SHOW_ERROR: 'INFO_SHOW_ERROR',

  ADMIN_SHOW_START: 'ADMIN_SHOW_START',
  ADMIN_SHOW_SUCCESS: 'ADMIN_SHOW_SUCCESS',
  ADMIN_SHOW_ERROR: 'ADMIN_SHOW_ERROR',

  PLAN_PROGRAM_SHOW_START: 'PLAN_PROGRAM_SHOW_START',
  PLAN_PROGRAM_SHOW_SUCCESS: 'PLAN_PROGRAM_SHOW_SUCCESS',
  PLAN_PROGRAM_SHOW_ERROR: 'PLAN_PROGRAM_SHOW_ERROR',

  DEVELOPMENT_SHOW_START: 'DEVELOPMENT_SHOW_START',
  DEVELOPMENT_SHOW_SUCCESS: 'DEVELOPMENT_SHOW_SUCCESS',
  DEVELOPMENT_SHOW_ERROR: 'DEVELOPMENTSHOW_ERROR',

  SALAAM_POLICE_SHOW_START: 'SALAAM_POLICE_START',
  SALAAM_POLICE_SHOW_SUCCESS: 'SALAAM_POLICE_SUCCESS',
  SALAAM_POLICE_SHOW_ERROR: 'SALAAM_POLICE_ERROR',

  COMMUNITY_AFFAIR_SHOW_START: 'COMMUNITY_AFFAIR_START',
  COMMUNITY_AFFAIR_SHOW_SUCCESS: 'COMMUNITY_AFFAIR_SUCCESS',
  COMMUNITY_AFFAIR_SHOW_ERROR: 'COMMUNITY_AFFAIR_ERROR',

  PUBLIC_INFO_SHOW_START: 'PUBLIC_SHOW_START',
  PUBLIC_INFO_SHOW_SUCCESS: 'PUBLIC_SHOW_SUCCESS',
  PUBLIC_INFO_SHOW_ERROR: 'PUBLIC_SHOW_ERROR',
};

export const getReportType = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_REPORT_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_REPORT_TYPE}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LIST_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_REPORT_ERROR, payload: error });
      });
  };
};

export const showAdmin = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.ADMIN_SHOW_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_ADMIN}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: GENERAL.ADMIN_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: GENERAL.ADMIN_SHOW_ERROR, payload: error });
      });
  };
};

export const showPlanProgram = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PLAN_PROGRAM_SHOW_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_PLAN_PROGRAM}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: GENERAL.PLAN_PROGRAM_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: GENERAL.PLAN_PROGRAM_SHOW_ERROR, payload: error });
      });
  };
};

export const showInfoOperation = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.INFO_SHOW_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_INFORMATION_OPERATION}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: GENERAL.INFO_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: GENERAL.INFO_SHOW_ERROR, payload: error });
      });
  };
};

export const showSalaamPolice = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.SALAAM_POLICE_SHOW_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_SALAAM_POLICE}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: GENERAL.SALAAM_POLICE_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: GENERAL.SALAAM_POLICE_SHOW_ERROR, payload: error });
      });
  };
};

export const showDevelopment = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.DEVELOPMENT_SHOW_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_DEVELOPMENT}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: GENERAL.DEVELOPMENT_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: GENERAL.DEVELOPMENT_SHOW_ERROR, payload: error });
      });
  };
};

export const showCommunityAffair = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.COMMUNITY_AFFAIR_SHOW_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_COMMUNITY_AFFAIR}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: GENERAL.COMMUNITY_AFFAIR_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: GENERAL.COMMUNITY_AFFAIR_SHOW_ERROR, payload: error });
      });
  };
};

export const showPublicInfo = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_SHOW_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_PUBLIC_INFORMATION}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: GENERAL.PUBLIC_INFO_SHOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: GENERAL.PUBLIC_INFO_SHOW_ERROR, payload: error });
      });
  };
};
export const getCategoriesReport = (callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_INFO_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };

    fetch(API_LIST_CATEGORIES_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);

        dispatch({
          type: GENERAL.LIST_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const getReferenceReport = (callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_INFO_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };

    fetch(API_LIST_CATEGORIES_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);

        dispatch({
          type: GENERAL.LIST_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};

export const getInfoOperationList = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_INFO_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };

    fetch(
      params
        ? `${API_LIST_INFORMATION_OPERATION}search=${
            params.search ? params.search : ''
          }&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${
            params?.formatDateEnd ? params?.formatDateEnd : ''
          }&report_type=${params.report_type ? params.report_type : ''}` +
            '&per_page=1000'
        : API_LIST_INFORMATION_OPERATION + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LIST_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        console.log(error.response.status);
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const getArchiveList = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_INFO_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };

    fetch(
      params
        ? `${API_LIST_ARCHIVE_LIST}category=${
            params.category ? params.category : ''
          }&report_type=${
            params.report_type ? params.report_type : ''
          }&search=${params.search ? params.search : ''}&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${params?.formatDateEnd ? params?.formatDateEnd : ''}` +
            '&per_page=1000'
        : API_LIST_ARCHIVE_LIST + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LISTARCHIVE_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const IOapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(
      `${API_INFORMATION_OPERATION_STATUS}${params.id}/status`,
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const PIapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(
      `${API_URL}/v1/public-informations/${params.id}/status`,
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const CAapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(`${API_URL}/v1/community-affairs/${params.id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const SPapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(`${API_URL}/v1/salaam-polices/${params.id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const DAapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(`${API_URL}/v1/developments/${params.id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const PPapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(`${API_URL}/v1/plans-programs/${params.id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const AAapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(`${API_URL}/v1/admins/${params.id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};
export const ArchiveapproveStatus = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: params.status, remarks: params?.remarks }),
    };

    fetch(`${API_URL}/v1/archives/${params.id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: GENERAL.INFO_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_INFO_ERROR, payload: error });
      });
  };
};

export const getDevelopmentList = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_DEVELOPMENT_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(
      params
        ? `${API_DEVELOPMENT_LIST}
              filter_by=${params.filter_by ? params.filter_by : ''}
              &search=${params.search ? params.search : ''}
              &start_date=${
                params?.formatDateStart ? params?.formatDateStart : ''
              }
              &end_date=${params?.formatDateEnd ? params?.formatDateEnd : ''}
              &report_type=${params.report_type ? params.report_type : ''}` +
            '&per_page=1000'
        : API_DEVELOPMENT_LIST + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LIST_DEVELOPMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_DEVELOPMENT_ERROR, payload: error });
      });
  };
};

export const getSalaamPoliceList = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_SALAAM_POLICE_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(
      params
        ? `${API_SALAAM_POLICE_LIST}filter_by=${
            params.filter_by ? params.filter_by : ''
          }&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${
            params?.formatDateEnd ? params?.formatDateEnd : ''
          }&search=${params.search ? params.search : ''}&report_type=${
            params.report_type ? params.report_type : ''
          }` + '&per_page=1000'
        : API_SALAAM_POLICE_LIST + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LIST_SALAAM_POLICE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_SALAAM_POLICE_ERROR, payload: error });
      });
  };
};

export const getPublicInfo = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.PUBLIC_INFO_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(
      params
        ? `${API_PUBLIC_INFORMATION_LIST}filter_by=${
            params.filter_by ? params.filter_by : ''
          }&search=${params.search ? params.search : ''}&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${
            params?.formatDateEnd ? params?.formatDateEnd : ''
          }&report_type=${params.report_type ? params.report_type : ''}` +
            '&per_page=1000'
        : API_PUBLIC_INFORMATION_LIST + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.PUBLIC_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.PUBLIC_INFO_ERROR, payload: error });
      });
  };
};

export const getCommunityAffairList = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_COMMUNITY_AFFAIR_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(
      params
        ? `${API_COMMUNITY_AFFAIR_LIST}filter_by=${
            params.filter_by ? params.filter_by : ''
          }&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${
            params?.formatDateEnd ? params?.formatDateEnd : ''
          }&search=${params.search ? params.search : ''}&report_type=${
            params.report_type ? params.report_type : ''
          }` + '&per_page=1000'
        : API_COMMUNITY_AFFAIR_LIST + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LIST_COMMUNITY_AFFAIR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_COMMUNITY_AFFAIR_ERROR, payload: error });
      });
  };
};

export const getPlanProgramList = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_PLAN_PROGRAM_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(
      params
        ? `${API_PLAN_PROGRAM_LIST}filter_by=${
            params.filter_by ? params.filter_by : ''
          }&search=${params.search ? params.search : ''}&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${
            params?.formatDateEnd ? params?.formatDateEnd : ''
          }&report_type=${params.report_type ? params.report_type : ''}` +
            '&per_page=1000'
        : API_PLAN_PROGRAM_LIST + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LIST_PLAN_PROGRAM_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_PLAN_PROGRAM_ERROR, payload: error });
      });
  };
};

export const getAdminList = (params) => {
  return (dispatch) => {
    dispatch({ type: GENERAL.LIST_ADMIN_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(
      params
        ? `${API_ADMIN_LIST}filter_by=${
            params.filter_by ? params.filter_by : ''
          }&search=${params.search ? params.search : ''}&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${
            params?.formatDateEnd ? params?.formatDateEnd : ''
          }&report_type=${params.report_type ? params.report_type : ''}` +
            '&per_page=1000'
        : API_ADMIN_LIST + 'per_page=1000',
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GENERAL.LIST_ADMIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: GENERAL.LIST_ADMIN_ERROR, payload: error });
      });
  };
};
