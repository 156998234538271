import React from 'react';
import { Table, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';
import styled from '../../../shared/components/Style';
import TableWrapper from '../../../shared/components/TableWrapper';

const TableRecentReport = () => {
  const state = useSelector((state) => state);
  const { listRecentReport, loading } = state.dashboard;

  const columns = [
    {
      title: 'Control No.',
      dataIndex: 'control_number',
      key: 'id',
    },
    {
      title: 'Report Type',
      dataIndex: 'report_type',
      key: 'id',
    },
    {
      title: 'Date Submitted',
      dataIndex: 'date_submitted',
      key: 'id',
    },
    {
      title: 'Submitted By',
      dataIndex: 'submitted_by',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'x',
      key: 'x',
      render: (data, rowData) => renderStatus(rowData),
    },
    // {
    //     title: 'Action',
    //     dataIndex: 'x',
    //     key: 'x',
    //     render: (data, rowData) => renderAction(rowData)
    // },
  ];

  const menu = (
    <Menu>
      <Menu.Item>
        <p>Approve</p>
      </Menu.Item>
      <Menu.Item>
        <p>Decline</p>
      </Menu.Item>
    </Menu>
  );

  const renderStatus = (data) => {
    switch (data.status) {
      case 'pending':
        return <p style={styled.pending}>Pending</p>;

      case 'approved':
        return <p style={styled.approved}>Approved</p>;

      case 'declined':
        return <p style={styled.declined}>Declined</p>;
      default:
        return;
    }
  };

  const renderAction = (data) => {
    return (
      <Dropdown
        overlay={menu}
        placement='bottomCenter'
      >
        <button style={{ width: '2rem' }}>
          <i
            style={{ ...styled.btn, margin: '0', width: '100%' }}
            className='fas fa-caret-down bg-green-10'
          ></i>
        </button>
      </Dropdown>
    );
  };

  return (
    <div className='sub-container p-4'>
      <p
        className='font-bold mb-2'
        style={{ color: '#005a9a' }}
      >
        Recent Report Activity
      </p>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={listRecentReport}
          loading={loading}
          scroll={{ y: 250, x: 500 }}
        />
      </TableWrapper>
    </div>
  );
};

export default TableRecentReport;
