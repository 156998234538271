import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Form, Input, Select, Button, Skeleton, message } from 'antd';

import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
} from '../../shared/custom-react-native';

import { names } from './data';
import { getReportType } from './action';
import UI from '../../shared/react-native/UI/js';
import FormWrapper from '../../shared/components/FormWrapper';

import './tablelist.scss';

const FormAddEditRecord = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { pathname } = history.location;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const task_id = urlParams.get('task_id');
  const type = urlParams.get('type');
  const state = useSelector((state) => state);
  const [fileList, setFileList] = useState([]);
  const [fileListlength, setFileListlength] = useState(0);
  const [controlNumber, setControlNumber] = useState('');
  const [user, setUser] = useState({ role: 'police' });
  const [isLoading, setIsLoading] = useState(false);
  const [fileErrorhandler, setfileErrorhandler] = useState('');

  const [reportTypes, setReportTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [objData, setObjData] = useState({});

  const {
    loading,
    showInfo,
    showAdmin,
    reportType,
    showPublicInfo,
    showPlanProgram,
    showDevelopment,
    showSalaamPolice,
    showCommunityAffair,
  } = state.general;

  const {
    addAdmin,
    editAdmin,
    addPlanRecord,
    editPlanRecord,
    editInfoRecord,
    addNewPubliInfo,
    addNewInfoRecord,
    addCommunityAffair,
    editCommunityAffair,
    addDevelopmentRecord,
    editPublicInfoRecord,
    addSalaamPoliceRecord,
    editDevelopmentRecord,
    editSalaamPoliceRecord,
  } = props;

  const onFinish = (value) => {
    let newvalue = { ...value, task_id: task_id };

    if (fileListlength === 0) {
      setfileErrorhandler('Please select a file!');
      return;
    }

    message.loading('Please wait while we process your request.');

    setIsLoading(true);

    if (id) {
      switch (pathname) {
        case `/edit-info/${id}`:
          return dispatch(editInfoRecord(value, fileList, id, callback));

        case `/edit-public-info/${id}`:
          return dispatch(editPublicInfoRecord(value, fileList, id, callback));

        case `/edit-community-affair/${id}`:
          value.report_data = objData;
          return dispatch(editCommunityAffair(value, fileList, id, callback));

        case `/edit-salaam-police/${id}`:
          return dispatch(
            editSalaamPoliceRecord(value, fileList, id, callback)
          );

        case `/edit-development/${id}`:
          return dispatch(editDevelopmentRecord(value, fileList, id, callback));

        case `/edit-program/${id}`:
          return dispatch(editPlanRecord(value, fileList, id, callback));

        case `/edit-admin/${id}`:
          return dispatch(editAdmin(value, fileList, id, callback));

        default:
          return;
      }
    } else {
      switch (pathname) {
        case '/add-info':
          return dispatch(addNewInfoRecord(newvalue, fileList, callback));

        case '/add-public-info':
          return dispatch(addNewPubliInfo(newvalue, fileList, callback));

        case '/add-community-affair':
          newvalue.report_data = JSON.stringify(objData);
          return dispatch(addCommunityAffair(newvalue, fileList, callback));

        case '/add-salaam-police':
          return dispatch(addSalaamPoliceRecord(newvalue, fileList, callback));

        case '/add-development':
          return dispatch(addDevelopmentRecord(newvalue, fileList, callback));

        case '/add-program':
          return dispatch(addPlanRecord(newvalue, fileList, callback));

        case '/add-admin':
          return dispatch(addAdmin(newvalue, fileList, callback));

        default:
          return;
      }
    }
  };

  const callback = (response, error) => {
    const res = response?.message;
    let err = response?.errors;
    // console.log(response);
    setIsLoading(false);

    if (err) {
      if (err.control_number) {
        form.setFields([
          {
            name: 'control_number',
            errors: [`${err.control_number}`],
          },
        ]);
      }
      if (err.report_type) {
        form.setFields([
          {
            name: 'category',
            errors: [`${err.report_type}`],
          },
        ]);
      }
      if (err.file) {
        form.setFields([
          {
            name: 'file',
            errors: [`${err.file}`],
          },
        ]);
      }
      if (err.date_submitted) {
        form.setFields([
          {
            name: 'date_submitted',
            errors: [`${err.date_submitted}`],
          },
        ]);
      }
      if (err['file.0']) {
        for (let i = 0; i < fileListlength; i++) {
          if (err[`file.${i}`]) {
            message.error(`${err[`file.${i}`][0]}`);
          }
        }
      }

      return;
    }

    if (res === 'Information Operation added successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Public Information added successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Information Operation updated successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Public Information updated successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Community Affair updated successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Community Affair added successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Salaam Police added successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Salaam Police updated successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Development added successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Development updated successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Plan and program added successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Plan and program updated successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Admin added successfully.') {
      onResetFieldValue();
      message.success(res);
    } else if (res === 'Admin updated successfully.') {
      onResetFieldValue();
      message.success(res);
    }

    setIsLoading(false);
    history.goBack();
  };

  const onResetFieldValue = () => {
    getControlNumber();
    setFileList([]);
    form.setFieldsValue({
      category: '',
      control_number: '',
      date_submitted: '',
      subject: '',
    });
    setObjData({});
  };

  const getControlNumber = async () => {
    const data = localStorage.getItem('user');
    if (data) {
      const me = JSON.parse(data);
      setUser(me);
    }

    UI.apiRequest({
      method: 'get',
      url: 'control-number',
      params: {
        category: localStorage.getItem('active_tab'),
      },
      onSuccess: (data) => {
        setControlNumber(data.data);
      },
      onFail: (data) => {},
      isMultiPart: false,
    });
  };

  // const renderReportType = () => {
  //   return reportType?.map((item) => {
  //     return (
  //       <option key={item.id} value={item.id}>
  //         {item.name}
  //       </option>
  //     );
  //   });
  // };

  // const renderFileUpload = () => {
  //   switch (pathname) {
  //     case `/edit-info/${id}`:
  //       return renderFileName(showInfo);

  //     case `/edit-public-info/${id}`:
  //       return renderFileName(showPublicInfo);

  //     case `/edit-community-affair/${id}`:
  //       return renderFileName(showCommunityAffair);

  //     case `/edit-program/${id}`:
  //       return renderFileName(showPlanProgram);

  //     case `/edit-salaam-police/${id}`:
  //       return renderFileName(showSalaamPolice);

  //     case `/edit-admin/${id}`:
  //       return renderFileName(showAdmin);

  //     case '/add-info':
  //       return renderFileName('');

  //     case '/add-public-info':
  //       return renderFileName('');

  //     case '/add-community-affair':
  //       return renderFileName('');

  //     case '/add-salaam-police':
  //       return renderFileName('');

  //     case '/add-development':
  //       return renderFileName('');

  //     case '/add-program':
  //       return renderFileName('');

  //     case '/add-admin':
  //       return renderFileName('');

  //     default:
  //       return;
  //   }
  // };

  // const renderFileName = (data) => {
  //   if (data.file?.name < 1 || fileList < 1) {
  //     return (
  //       <div className='bg-gray-10 p-8 rounded-md'>
  //         <i className='fas fa-file-upload text-gray-20 text-5xl'></i>
  //       </div>
  //     );
  //   }
  // };

  const renderRedirection = () => {
    switch (pathname) {
      case '/add-info':
        return '/information-operations';

      case `/edit-info/${id}`:
        return '/information-operations';

      case '/add-public-info':
        return '/public-information';

      case `/edit-public-info/${id}`:
        return '/public-information';

      case '/add-community-affair':
        return '/community-affairs';

      case `/edit-community-affair/${id}`:
        return '/community-affairs';

      case '/add-salaam-police':
        return '/salaam-police';

      case '/add-program':
        return '/plan-program';

      case `/edit-program/${id}`:
        return '/plan-program';

      case `/edit-salaam-police/${id}`:
        return '/salaam-police';

      case '/add-development':
        return '/development';

      case '/add-admin':
        return '/admin';

      case `/edit-development/${id}`:
        return '/development';

      case `/edit-admin/${id}`:
        return '/admin';

      default:
        return;
    }
  };

  const renderButton = () => {
    return (
      <Link to={renderRedirection}>
        <Button className='bg-red-10 h-10 text-white w-32'>CANCEL</Button>
      </Link>
    );
  };

  // const select_file = async () => {
  //   console.log('Selecting File');
  //   const obj = await UI.get_file_with_extras();
  //   if (obj) {
  //     const { file, extension } = obj;

  //     if (
  //       extension === 'docx' ||
  //       extension === 'doc' ||
  //       extension === 'csv' ||
  //       extension === 'xls' ||
  //       extension === 'pdf' ||
  //       extension === 'jpg' ||
  //       extension === 'png' ||
  //       extension === 'jpeg' ||
  //       extension === 'xlsx' ||
  //       extension === 'mp4'
  //     ) {
  //       const size = file.size;
  //       if (size > 10000000) {
  //         message.error(
  //           'The file you uploaded exceeded the max. Given: ' +
  //             UI.get_file_size(file)
  //         );
  //       } else {
  //         setFileList([obj]);
  //       }
  //     } else {
  //       message.error('Invalid File Format');
  //     }
  //   }
  // };

  const download_file = async () => {
    if (fileList?.length > 0) {
      let url = fileList[0]?.file.url;

      const a = document.createElement('a');
      a.href = url;
      a.download = url.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setObjData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const renderList = () => {
    // BPATs
    if (selectedType === 36 || selectedType === '36') {
      return (
        <table className='table-list'>
          <tr>
            <th>Total Number of Barangays</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='total_number_of_barangays'
                value={objData?.total_number_of_barangays}
              />
            </td>
          </tr>
          <tr>
            <th>No of Barangays with BPATs</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='no_of_barangays_with_bpats'
                value={objData?.no_of_barangays_with_bpats}
              />
            </td>
          </tr>
          <tr>
            <th>Barangay Tanod</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='barangay_tanod'
                value={objData?.barangay_tanod}
              />
            </td>
          </tr>
          <tr>
            <th>Security Guards</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='security_guards'
                value={objData?.security_guards}
              />
            </td>
          </tr>
          <tr>
            <th>Anti Crime NGO Members</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='anti_crime_ngo_members'
                value={objData?.anti_crime_ngo_members}
              />
            </td>
          </tr>
          <tr>
            <th>Sectoral Group Members</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='sectoral_group_members'
                value={objData?.sectoral_group_members}
              />
            </td>
          </tr>
          <tr>
            <th>Tricycle Operators / Drivers Association Members</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='tricycle_operators_drivers_association_members'
                value={objData?.tricycle_operators_drivers_association_members}
              />
            </td>
          </tr>
          <tr>
            <th>Radio Group / Club Members</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='radio_group_club_members'
                value={objData?.radio_group_club_members}
              />
            </td>
          </tr>
        </table>
      );
    }

    // MOA
    if (selectedType === 39 || selectedType === '39') {
      return (
        <table className='table-list'>
          <tr>
            <th>MOA</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='moa'
                value={objData?.moa}
              />
            </td>
          </tr>
          <tr>
            <th>MOU</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='mou'
                value={objData?.mou}
              />
            </td>
          </tr>
          <tr>
            <th>Number of Police Stations</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='number_of_police_stations'
                value={objData?.number_of_police_stations}
              />
            </td>
          </tr>
          <tr>
            <th>Number of Beneficiaries</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='number_of_beneficiaries'
                value={objData?.number_of_beneficiaries}
              />
            </td>
          </tr>
          <tr>
            <th>Number of Stakeholders</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='number_of_stakeholders'
                value={objData?.number_of_stakeholders}
              />
            </td>
          </tr>
        </table>
      );
    }

    // BIDA
    if (selectedType === 180 || selectedType === '180') {
      return (
        <table className='table-list'>
          <tr>
            <th>No. of Info Materials Disseminated / Poster</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='no_of_info_materials_disseminated_poster'
                value={objData?.no_of_info_materials_disseminated_poster}
              />
            </td>
          </tr>
          <tr>
            <th>No. of Voluntary PWUDs Surrenderness</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='no_of_voluntary_pwuds_surrenderness'
                value={objData?.no_of_voluntary_pwuds_surrenderness}
              />
            </td>
          </tr>
          <tr>
            <th>No. of BIDA Advocate Recruited / Engage</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='no_of_bida_advocate_recruited_engage'
                value={objData?.no_of_bida_advocate_recruited_engage}
              />
            </td>
          </tr>
          <tr>
            <th>No. of Webinars / Lectures and Info Drive Conducted</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='no_of_webinars_lectures_and_info_drive_conducted'
                value={
                  objData?.no_of_webinars_lectures_and_info_drive_conducted
                }
              />
            </td>
          </tr>
          <tr>
            <th>Assessment</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='assessment'
                value={objData?.assessment}
              />
            </td>
          </tr>
        </table>
      );
    }

    // Inventory Help Desk
    if (selectedType === 181 || selectedType === '181') {
      return (
        <table className='table-list'>
          <tr>
            <th>No. of Unit / Police Stations</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='on_of_unit_police_stations'
                value={objData?.on_of_unit_police_stations}
              />
            </td>
          </tr>
          <tr>
            <th>No. of Help Desk Established</th>
            <td>
              <input
                required
                type='number'
                onChange={handleChange}
                name='no_of_help_desk_established'
                value={objData?.no_of_help_desk_established}
              />
            </td>
          </tr>
          <tr>
            <th>OFWs</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='ofws'
                value={objData?.ofws}
              />
            </td>
          </tr>
          <tr>
            <th>IPs</th>
            <td>
              <input
                required
                onChange={handleChange}
                name='ips'
                value={objData?.ips}
              />
            </td>
          </tr>
          <tr>
            <th>Foreign National Concerns</th>
            <td>
              <input
                required
                name='foreign_national_concerns'
                onChange={handleChange}
                value={objData?.foreign_national_concerns}
              />
            </td>
          </tr>
          <tr>
            <th>Tourist Concerns</th>
            <td>
              <input
                required
                name='tourist_concerns'
                onChange={handleChange}
                value={objData?.tourist_concerns}
              />
            </td>
          </tr>
          <tr>
            <th>Gender-Based Violence</th>
            <td>
              <input
                required
                name='gender_based_violence'
                onChange={handleChange}
                value={objData?.gender_based_violence}
              />
            </td>
          </tr>
          <tr>
            <th>Environmental Concerns</th>
            <td>
              <input
                required
                name='environmental_concerns'
                onChange={handleChange}
                value={objData?.environmental_concerns}
              />
            </td>
          </tr>
        </table>
      );
    }
  };

  const hasJsonStructure = (str) => {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]' || type === '[object Array]';
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (pathname === `/edit-info/${id}`) {
      console.log(showInfo?.file?.length | 0);
      setFileListlength(showInfo?.file?.length | 0);
      form.setFieldsValue({
        category: showInfo?.report_type_id,
        control_number: showInfo?.control_number,
        date_submitted: showInfo?.date_submitted,
        subject: showInfo?.subject,
        description: showInfo?.description,
      });
    } else if (pathname === `/edit-public-info/${id}`) {
      setFileListlength(showPublicInfo?.file?.length | 0);
      form.setFieldsValue({
        category: showPublicInfo?.report_type_id,
        control_number: showPublicInfo?.control_number,
        date_submitted: showPublicInfo?.date_submitted,
        subject: showPublicInfo?.subject,
        description: showPublicInfo?.description,
      });
    } else if (pathname === `/edit-community-affair/${id}`) {
      setFileListlength(showCommunityAffair?.file?.length | 0);
      form.setFieldsValue({
        category: showCommunityAffair.report_type_id,
        control_number: showCommunityAffair.control_number,
        date_submitted: showCommunityAffair.date_submitted,
        subject: showCommunityAffair.subject,
        description: showCommunityAffair?.description,
      });

      setSelectedType(showCommunityAffair.report_type_id);

      if (hasJsonStructure(showCommunityAffair?.report_data)) {
        setObjData(JSON.parse(showCommunityAffair?.report_data));
      }
    } else if (pathname === `/edit-salaam-police/${id}`) {
      setFileListlength(showSalaamPolice?.file?.length | 0);
      form.setFieldsValue({
        category: showSalaamPolice.report_type_id,
        control_number: showSalaamPolice.control_number,
        date_submitted: showSalaamPolice.date_submitted,
        subject: showSalaamPolice.subject,
        description: showSalaamPolice?.description,
      });
    } else if (pathname === `/edit-development/${id}`) {
      setFileListlength(showDevelopment?.file?.length | 0);
      form.setFieldsValue({
        category: showDevelopment.report_type_id,
        control_number: showDevelopment.control_number,
        date_submitted: showDevelopment.date_submitted,
        subject: showDevelopment.subject,
        description: showDevelopment?.description,
      });
    } else if (pathname === `/edit-program/${id}`) {
      setFileListlength(showPlanProgram?.file?.length | 0);
      form.setFieldsValue({
        category: showPlanProgram.report_type_id,
        control_number: showPlanProgram.control_number,
        date_submitted: showPlanProgram.date_submitted,
        subject: showPlanProgram.subject,
        description: showPlanProgram?.description,
      });
    } else if (pathname === `/edit-admin/${id}`) {
      setFileListlength(showAdmin?.file?.length | 0);
      form.setFieldsValue({
        category: showAdmin.report_type_id,
        control_number: showAdmin.control_number,
        date_submitted: showAdmin.date_submitted,
        subject: showAdmin.subject,
        description: showAdmin?.description,
      });
    } else {
      setFileList([]);
      form.setFieldsValue({
        category: '',
        control_number: '',
        date_submitted: '',
        subject: '',
      });
    }
  }, [
    showInfo,
    showPublicInfo,
    showCommunityAffair,
    showSalaamPolice,
    showDevelopment,
    showPlanProgram,
    showAdmin,
  ]);

  useEffect(() => {
    getControlNumber();

    switch (pathname) {
      case '/add-info':
        return dispatch(getReportType(names.io));

      case '/add-public-info':
        return dispatch(getReportType(names.pi));

      case '/add-community-affair':
        return dispatch(getReportType(names.ca));

      case '/add-salaam-police':
        return dispatch(getReportType(names.sp));

      case '/add-development':
        return dispatch(getReportType(names.de));

      case '/add-program':
        return dispatch(getReportType(names.pp));

      case `/edit-info/${id}`:
        return dispatch(getReportType(names.io));

      case `/edit-public-info/${id}`:
        return dispatch(getReportType(names.pi));

      case `/edit-community-affair/${id}`:
        return dispatch(getReportType(names.ca));

      case `/edit-salaam-police/${id}`:
        return dispatch(getReportType(names.sp));

      case `/edit-development/${id}`:
        return dispatch(getReportType(names.de));

      case `/edit-program/${id}`:
        return dispatch(getReportType(names.pp));

      case `/edit-admin/${id}`:
        return dispatch(getReportType(names.ad));

      default:
        return;
    }
  }, []);

  useEffect(() => {
    console.log('selectedType', selectedType);
  }, [selectedType]);

  useEffect(() => {
    if (state?.general?.reportType) {
      const { role } = state.profile.showProfile;

      if (
        role === 'encoder' &&
        (pathname === '/add-community-affair' ||
          pathname === `/add-community-affair/${id}`)
      ) {
        const encoderIds = [36, 39, 180, 181];

        const filteredTypes = reportType.filter((d) =>
          encoderIds.includes(d?.id)
        );

        setReportTypes(filteredTypes);
      } else {
        setReportTypes(state?.general?.reportType);
      }
    }
  }, [state]);

  return (
    <div className='sub-container p-5 md:p-10 flex justify-center'>
      <div className='w-full md:w-2/3'>
        {loading ? (
          <Skeleton />
        ) : (
          <FormWrapper>
            <Form form={form} onFinish={onFinish} className='add-edit-record'>
              <div className='md:flex justify-center'>
                <div className='md:w-1/2 md:mr-5'>
                  {id ? (
                    <div>
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Control Number
                      </p>
                      <Form.Item
                        name='control_number'
                        className='md:mb-14'
                        style={{
                          fontWeight: 'bold',
                        }}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter control number!',
                          },
                          { message: 'Invalid control number!' },
                        ]}
                      >
                        <Input
                          style={{
                            fontWeight: 'bold',
                            userSelect: 'none',
                            pointerEvents: 'none',
                          }}
                          placeholder='Enter Control Number'
                        />
                      </Form.Item>
                    </div>
                  ) : (
                    <div>
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Control Number
                      </p>
                      <Form.Item className='md:mb-14'>
                        <Input
                          style={{ fontWeight: 'bold' }}
                          value={controlNumber}
                          defaultValue={controlNumber}
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div>
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      Report Type
                    </p>
                    <Form.Item
                      name='category'
                      className='mb-14'
                      rules={[
                        { required: true, message: 'Please select category!' },
                      ]}
                    >
                      <Select
                        placeholder='Select Report Type'
                        onChange={(e) => {
                          setSelectedType(e);
                        }}
                      >
                        <option value={''} hidden>
                          Select Report Type
                        </option>
                        {reportTypes.map((data) => {
                          return (
                            <option key={data.id} value={data.id}>
                              {data.name}
                            </option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      Title / Subject
                    </p>
                    <Form.Item
                      className='mb-5'
                      name='subject'
                      rules={[
                        {
                          required: true,
                          message: 'Please input a Title/Subject!',
                        },
                      ]}
                    >
                      <Input
                        style={{ maxHeight: '44px' }}
                        placeholder='Enter Title/Subject'
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      Description
                    </p>
                    <Form.Item
                      name='description'
                      className='md:mb-14'
                      rules={[
                        {
                          required: true,
                          message: 'Please input description!',
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ minHeight: '6rem' }}
                        rows={5}
                        placeholder='Enter Description'
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <div>
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      {id ? 'Update File' : 'Upload File'}
                    </p>

                    <View
                      style={{
                        ...styles.choose_file_container,
                        flexDirection: 'column',
                        backgroundColor: '#d4d7db',
                      }}
                    >
                      <label
                        // onClick={() => {
                        //   select_file();
                        // }}
                        for='file'
                        style={{
                          ...styles.choose_file_button,
                          backgroundColor: 'white',
                          textAlign: 'center',
                        }}
                      >
                        <Text
                          style={{ ...styles.choose_file_text, color: 'black' }}
                        >
                          Choose File
                        </Text>
                      </label>
                      {UI.box(10)}
                      <Text>
                        {fileList && (
                          <>
                            {fileListlength > 0 && (
                              <>
                                {fileListlength} File
                                {fileListlength === 1 ? '' : 's'} Selected
                              </>
                            )}
                            {(fileListlength === 0 ||
                              fileListlength === null) && <>Upload file</>}
                          </>
                        )}
                        {!fileList && <>Upload file</>}
                      </Text>
                      <input
                        id='file'
                        type='file'
                        style={{ visibility: 'hidden' }}
                        onChange={(e) => {
                          let docuArr = [];

                          Array.from(e.target.files).forEach((file) => {
                            console.log(file);
                            const { size } = file;
                            if (size > 10000000) {
                              message.error(
                                'The file you uploaded exceeded the max. Given: ' +
                                  UI.get_file_size(file)
                              );
                            } else {
                              docuArr.push(file);
                            }
                          });
                          setFileListlength(docuArr.length);
                          setFileList(docuArr);
                        }}
                        multiple
                      />
                      {/* <Text>Image</Text> */}
                    </View>

                    {UI.box(10)}

                    <View
                      className='text-center text-danger'
                      style={{ display: 'blocked' }}
                    >
                      <Text className='mb-3'>{fileErrorhandler}</Text>
                    </View>
                    <View>
                      <Text>
                        The file must be a file of type: jpeg, png, doc, docx,
                        csv, xls, pdf, xlsx, mp4
                      </Text>
                    </View>

                    {UI.box(10)}
                    {(pathname === `/edit-info/${id}` ||
                      pathname === `/edit-public-info/${id}` ||
                      pathname === `/edit-community-affair/${id}` ||
                      pathname === `/edit-salaam-police/${id}` ||
                      pathname === `/edit-development/${id}` ||
                      pathname === `/edit-program/${id}` ||
                      pathname === `/edit-admin/${id}`) &&
                      user.role === 'admin' && (
                        <View style={{ width: '100%', alignItems: 'center' }}>
                          <TouchableOpacity
                            onClick={() => {
                              download_file();
                            }}
                            style={{
                              ...styles.choose_file_button,
                              backgroundColor: '#001529',
                              width: 220,
                              alignSelf: 'center',
                            }}
                          >
                            <Text style={styles.choose_file_text}>
                              Download
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                  </div>

                  <div className='table-wrapper'>{renderList()}</div>
                </div>
              </div>
              <div
                style={{ pointerEvents: isLoading ? 'none' : 'all' }}
                className='flex justify-end mt-10'
              >
                <Button
                  onClick={() => {}}
                  htmlType='submit'
                  className='bg-blue-10 h-10 text-white w-32 mr-2'
                >
                  {isLoading ? 'Please wait...' : 'SUBMIT'}
                </Button>
                {renderButton()}
              </div>
            </Form>
          </FormWrapper>
        )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  choose_file_text: {
    fontWeight: 'bold',
    fontSize: 14,
    color: 'white',
  },
  choose_file_button: {
    padding: 10,
    backgroundColor: '#7d7d7d',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
  },
  choose_file_container: {
    width: '100%',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#dbdbdb',
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default FormAddEditRecord;
