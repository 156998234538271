import { getAuthHeader, getError, axios } from "../../shared/utils";
import {
    API_PENDING_REPORT_LIST,
    API_RECENT_REPORT_LIST
} from "../../shared/constant/url";


export const DASHBOARD = {

  LIST_PENDING_REPORT_START: "LIST_PENDING_REPORT_START",
  LIST_PENDING_REPORT_SUCCESS: "LIST_PENDING_REPORT_SUCCESS",
  LIST_PENDING_REPORT_ERROR: "LIST_PENDING_REPORT_ERROR",

  LIST_RECENT_REPORT_START: "LIST_RECENT_REPORT_START",
  LIST_RECENT_REPORT_SUCCESS: "LIST_RECENT_REPORT_SUCCESS",
  LIST_RECENT_REPORT_ERROR: "LIST_RECENT_REPORT_ERROR",

}


export const getPendingReportList = (params) => {
    return (dispatch) => {
      dispatch({ type: DASHBOARD.LIST_PENDING_REPORT_START });
      let requestOption = {
        method: "GET",
        headers: getAuthHeader(),
      };
          fetch(params ? 
            `${API_PENDING_REPORT_LIST}filter_by=${params.filter_by ? params.filter_by : ""}` 
            : API_PENDING_REPORT_LIST, requestOption)
            .then((response) => response.json())
            .then((response) => {
              dispatch({
                type: DASHBOARD.LIST_PENDING_REPORT_SUCCESS,
                payload: response.data,
              });
            })
            .catch((error) => {
              // callback(null, getError(error));
              dispatch({ type: DASHBOARD.LIST_PENDING_REPORT_ERROR, payload: error });
            });
      };
    };

    export const getRecentReportList = (params) => {
        return (dispatch) => {
          dispatch({ type: DASHBOARD.LIST_RECENT_REPORT_START });
          let requestOption = {
            method: "GET",
            headers: getAuthHeader(),
          };
              fetch( API_RECENT_REPORT_LIST, requestOption)
                .then((response) => response.json())
                .then((response) => {
                  dispatch({
                    type: DASHBOARD.LIST_RECENT_REPORT_SUCCESS,
                    payload: response.data,
                  });
                })
                .catch((error) => {
                  // callback(null, getError(error));
                  dispatch({ type: DASHBOARD.LIST_RECENT_REPORT_ERROR, payload: error });
                });
          };
        };