import { PROFILE } from './action';

const INITIAL_STATE = {
    loading: false,
    error: '',
    isLoggedIn: false,
    showProfile: "",
};


const profile = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case PROFILE.SHOW_PROFILE_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case PROFILE.SHOW_PROFILE_SUCCESS:
            return { ...state, loading: false, showProfile: action.payload, isLoggedIn: true }
        case PROFILE.SHOW_PROFILE_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        default:
            return state;
    }
}

export default profile;


