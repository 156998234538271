import React, { Fragment, useState, useEffect } from 'react';
import FormLogin from '../components/FormLogin';
import StandardLanding from '../../../shared/components/StandardLanding';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser';
import {
  ImageBackground,
  View,
  Text,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/react-native/UI/js';
import { Modal, Button } from 'react-bootstrap';
import './Login.css';
import { updatePrivacyPolicy } from '../action';

const Login = () => {
  const [w, setW] = useState(UI.getWidth());
  const [show, setShow] = useState(false);
  const [isCheckedDataPrivacy, setIsCheckedDataPrivacy] = useState(false);
  const [content, setContent] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAgree = () => {
    setIsCheckedDataPrivacy(true);
    setShow(false);
  };
  useEffect(() => {
    setW(UI.getWidth());
    window.addEventListener('resize', resize);

    return function cleanup() {
      window.removeEventListener('resize', resize);
    };
  }, [w]);

  const resize = () => {
    setW(UI.getWidth());
  };
  useEffect(() => {
    privatePolicy();
  }, []);
  const privatePolicy = async () => {
    const result = await updatePrivacyPolicy();
    const { data } = result;
    console.log(data.data?.attributes?.value);
    setContent(data.data?.attributes?.value);
  };
  return (
    <Fragment>
      {/* <div className="p-0" style={{ position: 'relative', height: '100vh', top: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100%' }}>

      </div> */}

      <Modal
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className='px-3'>
          <div className='d-flex justify-center'>
            <img
              src='./images/pnplogo.png'
              alt='pnp-img'
            />
          </div>
          <h5
            className='text-center my-4 fw-bold'
            style={{ color: '#4E4E4E', fontSize: '20px' }}
          >
            Privacy Policy
          </h5>
          <p
            className='px-3'
            style={{ color: '#4E4E4E' }}
          >
            {ReactHtmlParser(content)}
          </p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-center'>
          <Button
            className='btn btn-primary px-5 py-2'
            onClick={handleAgree}
          >
            Agree
          </Button>
          <Button
            className='btn  text-primary px-5 py-2 '
            style={{ backgroundColor: 'white' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='login_page'>
        <div className='container fluid'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='home'>
                <div className='home__brand'>
                  <img src='/images/Group7920.png' />
                  <div className='home_message'>
                    <div
                      className='d-flex'
                      style={{ gap: '20px', flexWrap: 'wrap' }}
                    >
                      <img
                        src='./images/Image7.png'
                        alt='img123'
                        style={{ width: '265px', heigth: '265px' }}
                      />
                      <img
                        src='./images/Image6.png'
                        alt='img51'
                        style={{ width: '265px', heigth: '265px' }}
                      />
                    </div>
                    <div className='text-center mt-4'>
                      <p className='h2'>PHILIPPINE NATIONAL POLICE</p>
                      <p className='h2'>POLICE COMMUNITY RELATIONS</p>
                      <p className='h2'>MANAGEMENT INFORMATION SYSTEM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 '>
              <div className='login__form'>
                Login
                <div
                  className='bg-white'
                  style={{ borderRadius: '5px' }}
                >
                  <FormLogin
                    handleShow={handleShow}
                    isCheckedDataPrivacy={isCheckedDataPrivacy}
                    setIsCheckedDataPrivacy={setIsCheckedDataPrivacy}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '5%',
            letterSpacing: '0.5px',
            fontSize: '18px',
          }}
        >
          <p className='text-white'>
            ©2021 Directorate for Police Community Relations
          </p>
        </div>
      </div>

      <div
        className='bg-black'
        style={{
          position: 'fixed',
          top: '0',
          zIndex: '-999',
          width: '100%',
          height: '100%',
          minHeight: '100vh',
        }}
      >
        <div
          style={{
            background: 'url("images/cover.png")',
            opacity: '0.75',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
          className='w-full h-screen'
        />
      </div>
    </Fragment>
  );
};

export default Login;
