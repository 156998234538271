import React, { useState, useEffect, useRef } from 'react';
import { Form, Select, Table, Button, Dropdown, Menu } from 'antd';
import TableWrapper from '../../../shared/components/TableWrapper';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './TableSummary.css';
import { View, StyleSheet, Text } from '../../../shared/custom-react-native';
import UI from '../../../shared/react-native/UI/js';
import FormWrapper from '../../../shared/components/FormWrapper';
import { DateRangePicker } from 'react-date-range';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import { TreeSelect } from 'antd';
import { treeData, useDateRange } from '../../../shared/hooks/useDateRange';
import { BiReset } from 'react-icons/bi';
import 'jspdf-autotable';
import Moment from 'moment';

const TableSummary = (props) => {
  const { summary, categories, isLoading, setIsLoading } = props;
  const ref = useRef(null);
  const [columnData, setColumnData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isshowDate, setisshowDate] = useState(true);
  const [hotReloadStart, setHotReloadStart] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [form] = Form.useForm();
  const [filterValue, setFilterValue] = useState('');
  const [filterDateValue, setFilterDateValue] = useState('');
  const componentRef = useRef();
  const state = useSelector((state) => state);
  const { listUnit } = state.account;
  const [currentCategory, setCurrentCategory] = useState('');
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setisshowDate(true);
    }
  };

  const load_data = async (id, filter_by = '') => {
    setIsLoading(true);
    setCurrentCategory(id);
    UI.apiRequest({
      method: 'get',
      url: 'summary-reports',
      params: {
        search: '',
        per_page: '1000',
        category: id,
        start_date: filter_by.formatDateStart ? filter_by.formatDateStart : '',
        end_date: filter_by.formatDateEnd ? filter_by.formatDateEnd : '',
      },
      onSuccess: (d) => {
        const { data, columns } = d;
        process_data(columns, data);
        setAllData(data);
      },
      onFail: (data) => {},
      isMultiPart: false,
      onFinish: () => {
        form.setFields([{ name: 'category', value: id }]);
        setIsLoading(false);
      },
    });
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    if (hotReloadStart) {
      const formatDateStart = Moment(selectionRange[0].startDate).format(
        'MM/DD/YYYY',
      );
      const formatDateEnd = Moment(selectionRange[0].endDate).format(
        'MM/DD/YYYY',
      );
      const params = { formatDateStart, formatDateEnd };
      console.log(params);
      // dispatch(getInfoOperationList(params));
      load_data(currentCategory, params);
    }
  }, [selectionRange]);
  useEffect(() => {
    if (categories.length > 0) {
      load_data(categories[0].id, 'daily');
    }

    //process_data(cols, rows);
  }, [categories]);

  const process_data = (data_col, data_row) => {
    let data = data_col.map((item) => {
      const obj = {
        title: item.title,
        dataIndex: item.key,
        render: (row, rowData) => {
          return renderTableItem(rowData[item.key]);
        },
      };
      return obj;
    });

    let header = data_col.map((item) => {
      const obj = {
        label: item.title,
        key: item.key,
      };
      return obj;
    });
    setColumnData(data);
    setHeaderData(header);

    // console.log("data_row", data_row);
    setRowData(data_row);
  };

  const renderTableItem = (text) => {
    return (
      <div>
        <p className='border px-5 py-2 text-center rounded-md'>{text}</p>
      </div>
    );
  };

  const filterByUnit = (e) => {
    setFilterValue(e);

    if (e) {
      const filteredData = allData.filter((data) => {
        return data.unit == e;
      });

      setRowData(filteredData);
      return;
    }

    setRowData(allData);
  };

  const onFilterByDate = (e) => {
    setFilterDateValue(e);
    load_data(currentCategory, e);
  };

  const downloadPDF = () => {
    if (columnData && rowData) {
      const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [5000, 1000],
      });
      doc.setFontSize(10);

      const filteredColumnData = columnData.map((item) => item.dataIndex);
      const values = rowData.map((elemento) => Object.values(elemento));

      let content = {
        head: [filteredColumnData],
        body: values,
      };

      // console.log(values);

      doc.text('Summary Report', 10, 10);
      doc.autoTable(content);
      doc.save('Summary.pdf');

      // console.log(doc);
    }
  };
  const useDateRange = (dispatch, key) => (e) => {
    console.log(e);
    let date = new Date().getFullYear();

    let params = {
      formatDateStart: Moment(`01/01/${date}`).format('MM/DD/YYYY'),
      formatDateEnd: Moment(new Date()).format('MM/DD/YYYY'),
    };
    if (e === 'first_quarter') {
      params = {
        formatDateStart: `01/01/${date}`,
        formatDateEnd: `03/31/${date}`,
      };
    }
    if (e === 'second_quarter') {
      params = {
        formatDateStart: `04/01/${date}`,
        formatDateEnd: `06/30/${date}`,
      };
    }
    if (e === 'third_quarter') {
      params = {
        formatDateStart: `07/01/${date}`,
        formatDateEnd: `09/30/${date}`,
      };
    }
    if (e === 'fourth_quarter') {
      params = {
        formatDateStart: `10/01/${date}`,
        formatDateEnd: `12/31/${date}`,
      };
    }
    if (e === 'annually') {
      params = {
        formatDateStart: `01/01/${date}`,
        formatDateEnd: `12/31/${date}`,
      };
    }
    if (e === 'jan') {
      params = {
        formatDateStart: `01/01/${date}`,
        formatDateEnd: `01/31/${date}`,
      };
    }
    if (e === 'feb') {
      params = {
        formatDateStart: `02/01/${date}`,
        formatDateEnd: `03/01/${date}`,
      };
    }
    if (e === 'mar') {
      params = {
        formatDateStart: `03/01/${date}`,
        formatDateEnd: `03/31/${date}`,
      };
    }
    if (e === 'april') {
      params = {
        formatDateStart: `04/01/${date}`,
        formatDateEnd: `04/30/${date}`,
      };
    }
    if (e === 'may') {
      params = {
        formatDateStart: `05/01/${date}`,
        formatDateEnd: `05/31/${date}`,
      };
    }
    if (e === 'jun') {
      params = {
        formatDateStart: `06/01/${date}`,
        formatDateEnd: `06/30/${date}`,
      };
    }
    if (e === 'jul') {
      params = {
        formatDateStart: `07/01/${date}`,
        formatDateEnd: `07/31/${date}`,
      };
    }
    if (e === 'aug') {
      params = {
        formatDateStart: `08/01/${date}`,
        formatDateEnd: `08/31/${date}`,
      };
    }
    if (e === 'sept') {
      params = {
        formatDateStart: `09/01/${date}`,
        formatDateEnd: `09/30/${date}`,
      };
    }
    if (e === 'oct') {
      params = {
        formatDateStart: `10/01/${date}`,
        formatDateEnd: `10/31/${date}`,
      };
    }
    if (e === 'nov') {
      params = {
        formatDateStart: `11/01/${date}`,
        formatDateEnd: `11/30/${date}`,
      };
    }
    if (e === 'dec') {
      params = {
        formatDateStart: `12/01/${date}`,
        formatDateEnd: `12/31/${date}`,
      };
    }

    console.log(params);
    load_data(currentCategory, params);
  };
  return (
    <div className='sub-container  p-5 md:p-10'>
      <View style={{ paddingLeft: 20, display: 'flex' }}>
        <FormWrapper className='flex'>
          <Form
            form={form}
            className='flex flex-row flex-wrap gap-2'
          >
            <Form.Item
              name={'category'}
              className='flex-1 md:w-auto'
            >
              <Select
                placeholder='Categories'
                onChange={(e) => {
                  load_data(e, filterDateValue);
                }}
              >
                {categories.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </Select>
            </Form.Item>
            {UI.box(30)}

            <div className='d-flex'>
              {' '}
              <Form.Item className='flex-1 md:w-auto'>
                <p className='absolute z-50 flex-none bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md flex self-center'>
                  Filter by:
                </p>
                <Select
                  className='flex-1'
                  value={filterValue}
                  style={{ padding: '5px 10px 5px 71px' }}
                  placeholder='Daily'
                  onChange={filterByUnit}
                >
                  {allData.map((data) => {
                    return <option value={data.unit}>{data.unit}</option>;
                  })}
                </Select>
              </Form.Item>
              <Button
                hidden
                htmlType='submit'
              ></Button>
              <div
                onClick={() => {
                  filterByUnit('');
                }}
              >
                <Button className='bg-blue-10 mx-2 w-full md:w-auto text-white h-11 md:mr-5 mb-5 md:mb-0'>
                  Reset Filter
                </Button>
              </div>
            </div>

            {UI.box(30)}

            <div className='d-flex h-11 mb-4'>
              <TreeSelect
                size='middle'
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={treeData}
                placeholder='Please select'
                onChange={useDateRange('public_information')}
              />

              <Button
                className='bg-blue-10 mx-2 text-white h-11'
                onClick={() => {
                  onFilterByDate('');
                }}
              >
                <BiReset size={18} />
              </Button>
            </div>
            <div className='d-flex'>
              {' '}
              <Form.Item className='custom-date-label'>
                <p className='absolute z-50 flex  bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md'>
                  Filter by:
                </p>
                <Button
                  className='buttonDate '
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #C5C5C5',
                    height: '44px',
                    borderLeft: 'none',
                    minWidth: '190px',
                  }}
                  placeholder='Date'
                  onClick={() => {
                    return setisshowDate((prev) => {
                      if (!hotReloadStart) {
                        setHotReloadStart(true);
                      }
                      if (prev) {
                        setisshowDate(false);
                      } else {
                        setisshowDate(true);
                      }
                    });
                  }}
                >
                  Date
                </Button>
              </Form.Item>
              <Button
                hidden
                htmlType='submit'
              ></Button>
              <div
                onClick={() => {
                  onFilterByDate('');
                }}
              >
                <Button className='bg-blue-10 mx-2 w-full md:w-auto text-white h-11 md:mr-5 mb-5 md:mb-0'>
                  Reset Filter
                </Button>
              </div>
            </div>
            {UI.box(30)}

            <Form.Item className='flex-1 md:w-auto'>
              <ReactToPrint
                trigger={() => (
                  <Button className='bg-blue-10 w-full md:w-auto text-white h-11 md:mr-5 mb-5 md:mb-0'>
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />

              {rowData && columnData && (
                <Dropdown
                  className='bg-blue-10 w-full md:w-auto text-white h-11 md:mr-5 mb-5 md:mb-0'
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <CSVLink
                          data={rowData}
                          headers={headerData}
                          filename={'Summary'}
                        >
                          Excel
                        </CSVLink>
                      </Menu.Item>
                      <Menu.Item>
                        <p onClick={downloadPDF}>PDF</p>
                      </Menu.Item>
                    </Menu>
                  }
                  placement='bottomCenter'
                >
                  <Button>Export</Button>
                </Dropdown>
              )}
            </Form.Item>
          </Form>
        </FormWrapper>
      </View>
      <TableWrapper className='px-2'>
        <Table
          id='summaryTable'
          loading={isLoading}
          columns={columnData}
          dataSource={rowData}
          pagination={true}
          scroll={{ x: 1000 }}
          ref={componentRef}
        />
        {/* 
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={[]}
          pagination={true}
          scroll={{ x: 1000 }}
        /> */}
      </TableWrapper>
      <div
        className='container'
        style={{
          display: isshowDate ? 'none' : 'block',
        }}
        ref={ref}
      >
        <DateRangePicker
          editableDateInputs={true}
          onChange={(item) => setSelectionRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={selectionRange}
        />
      </div>
    </div>
  );
};

export default TableSummary;
