import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Form, Input, Skeleton, Button, message } from 'antd';
import FormWrapper from '../../../shared/components/FormWrapper';
import banner from '../../../shared/images/Group 36.png';
import './FormEditPassword.css';
import UI from '../../../shared/react-native/UI/js';

const FormEditPassword = () => {
  const [form] = Form.useForm();
  const his = useHistory();

  const onSetFieldValue = () => {
    form.setFieldsValue({
      current_password: '',
      new_password: '',
      new_confirm_password: '',
    });
  };

  const onFinish = async (value) => {
    const { current_password, new_password, new_confirm_password } = value;

    if (new_password != new_confirm_password) {
      message.error('Passwords do not match.');
      return;
    }

    if (new_password.length < 8) {
      message.error('Password requires atleast 8 characters.');
      return;
    }

    const res = await UI.apiRequest({
      method: 'patch',
      url: 'profile/change-password',
      params: {
        current_password,
        new_password,
        new_confirm_password,
      },
      onSuccess: (data) => {
        message.success(data.message);
        his.push('/');
      },
      onFail: (data) => {
        message.error(data.errors.new_password);
      },
      isMultiPart: false,
    });
    console.log(res);
  };

  return (
    <div className='sub-container p-10'>
      <div className='profile-banner'>
        <img
          src={banner}
          className='w-full h-52 object-cover banner-image'
        />
        <div className='lockbanner'>
          <img
            src='./images/lockbanner.png'
            alt='img'
            style={{ height: '150px', width: '150px' }}
          />
        </div>
      </div>
      <FormWrapper>
        <Form
          form={form}
          onFinish={onFinish}
        >
          <div className='flex justify-between'>
            <div className='w-80'>
              <p className='text-gray-20 mb-5'>Current Password</p>
              <Form.Item
                name='current_password'
                className='mb-10'
                rules={[
                  {
                    required: true,
                    message: 'Please enter current password here!',
                  },
                ]}
              >
                <Input.Password placeholder='Enter Current Password' />
              </Form.Item>
            </div>

            <div className='w-80'>
              <p className='text-gray-20 mb-5'>New Password</p>
              <Form.Item
                name='new_password'
                className='mb-10'
                rules={[
                  {
                    required: true,
                    message: 'Please enter new password here!',
                  },
                ]}
              >
                <Input.Password placeholder='Enter New Password' />
              </Form.Item>
            </div>
            <div className='w-80'>
              <p className='text-gray-20 mb-5'>Confirm Password</p>
              <Form.Item
                name='new_confirm_password'
                className='mb-10'
                rules={[
                  {
                    required: true,
                    message: 'Please confirm password!',
                  },
                ]}
              >
                <Input.Password placeholder='Confirm Password' />
              </Form.Item>
            </div>
          </div>
          <div className='flex justify-end mt-10'>
            <Button
              htmlType='submit'
              className='bg-blue-10 h-10  text-white w-32 mr-2'
            >
              SUBMIT
            </Button>
            <Link to='/'>
              <Button className='bg-red-10 h-10 text-white w-32'>CANCEL</Button>
            </Link>
          </div>
        </Form>
      </FormWrapper>
    </div>
  );
};

export default FormEditPassword;
