import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import banner from "../../../shared/images/unsplash.png";
import { Button } from 'react-bootstrap';
import StandardLanding from "../../../shared/components/StandardLanding";
import { useDispatch, useSelector } from "react-redux";
import { onShowAnnouncement } from "../action";

import {
  ImageBackground,
  View,
  Text,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/react-native/UI/js";

const ShowAnnouncement = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { loading, showAnnouncement, listAnnouncement } = state.announcement;

  useEffect(() => {
    if (id) {
      dispatch(onShowAnnouncement(id, callback));
    }
  }, []);

  const callback = () => { };

  const renderAnnouncement = () => {
    let data = localStorage.getItem("announcement_item");
    let item = { title: "", date_published: "", description: "" };

    if (data) {
      item = JSON.parse(data);
    }

    return (
      <div className="sub-container">
        <ImageBackground
          source={item.file.url}
          style={{
            width: "100%",
            height: 250,
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
          }}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.45)",
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15,
              flexDirection: "column-reverse",
              padding: 20,
            }}
          >
            <Text style={{ fontSize: 20, fontWeight: "bold", color: "white" }}>
              {item?.title}
            </Text>
          </View>
        </ImageBackground>

        <View style={{ padding: 20 }}>
          <Text style={{ color: "#9F9F9F", fontSize: 14, fontWeight: "bold" }}>
            {item?.date_published}
          </Text>

          {UI.box(20)}

          <Text style={{ color: "#333333", fontSize: 15, fontWeight: "bold" }}>
            {item?.description}
          </Text>
        </View>
      </div>
    );
  };

  return (
    <StandardLanding>
      <div className="main-container mb-20 content-crop">
        <div className="flex justify-between">
          <p className="lg-title text-blue-10">ANNOUNCEMENTS</p>
          <p className="md-title text-gray-20">View announcement</p>
        </div>
        <div className="mt-2">{renderAnnouncement()}</div>
      </div>
      <Link to="/">
        <Button variant="danger" size="lg">Back</Button>
      </Link>
    </StandardLanding>
  );
};

export default ShowAnnouncement;
