import React from "react";
import { Form, Input, Checkbox, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FormWrapper from "../../../shared/components/FormWrapper";

import { resetPassword } from "../action";
import {
  ImageBackground,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/react-native/UI/js";

const FormResetPassword = (props) => {
  const { query } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { token, email } = query;

  const onFinish = (value) => {
    value["token"] = token;
    value["email"] = email;
    dispatch(resetPassword(value, callback));
  };

  const callback = (response, error) => {
    let res = response?.message;
    let err = response?.errors;

    console.log(response, error);

    if (res === "Password has been successfully changed") {
      onResetField();
      message.success("Password successfully updated");

      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } else {
      message.error(response.message);
      if (err) {
        if (err.password) {
          form.setFields([
            {
              name: "password",
              errors: [`${err.password}`],
            },
          ]);
        }
        if (err.password_confirmation) {
          form.setFields([
            {
              name: "password_confirmation",
              errors: [`${err.password_confirmation}`],
            },
          ]);
        }
      }
    }
  };

  const onResetField = () => {
    form.setFieldsValue({
      email: "",
      Password: "",
    });
  };

  return (
    <FormWrapper>
      <div className="pt-4 pr-3 pb-4 pl-3">
        <Form className="mt-0 mr-4 mb-0 ml-4" form={form} onFinish={onFinish}>
          <p className="text-black font-bold mb-2" style={{ fontSize: "1.25rem" }}>Reset Password</p>
          <div>
            <span className="text-gray-20 font-bold ">New Password</span>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                style={{ marginTop: 10 }}
                placeholder="Enter Password Here"
                className="h-14"
              />
            </Form.Item>
          </div>
          <div>
            <span className="text-gray-20 font-bold">Confirm Password</span>
            <Form.Item
              name="password_confirmation"
              rules={[
                { required: true, message: "Please confirm your password!" },
              ]}
            >
              <Input.Password
                style={{ marginTop: 10 }}
                placeholder="Confirm Password Here"
                className="h-14"
              />
            </Form.Item>
          </div>
        </Form>
        <div className="w-100 d-flex justify-content-center ">
          <button
            className="btn btn-lg btn-dark w-100 mt-2 mr-4 mb-0 ml-4"
            onClick={() => {
              form.submit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </FormWrapper>
  );
};

export default FormResetPassword;
