import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableAnnouncement from '../components/TableAnnouncement';
import { getAnnouncementList, updateAnnouncementStatus } from '../action';

import UI from '../../../shared/react-native/UI/js';

function Landing() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const { loading, listAnnouncement } = state.announcement;

  const [announcementList, setAnnouncementList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = useState('');
  const [search, setSearch] = useState('');
  const [hotReloadStart, setHotReloadStart] = useState(false);

  const role = state?.profile?.showProfile?.role;

  useEffect(() => {
    console.log('test');

    dispatch(getAnnouncementList(null, callbackList));
  }, []);
  useEffect(() => {
    console.log(hotReloadStart);
  }, [hotReloadStart]);
  const callbackList = (response) => {
    const { data } = response;
    console.log(data);
    const newData = data.map((data) => {
      return {
        id: data.attributes?.id,
        category: data.relationships?.category?.name,
        subcategory: data.relationships?.sub_category?.name,
        title: data.attributes?.title,
        description: data.attributes?.description,
        date: data.attributes?.timestamp,
      };
    });

    setAnnouncementList(newData);
  };

  useEffect(() => {
    if (hotReloadStart) {
      const identifier = setTimeout(() => {
        const params = { search: search };
        dispatch(getAnnouncementList(params, callbackList));
      }, 500);

      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);
  useEffect(() => {
    if (hotReloadStart) {
      if (categoryID) {
        const params = { category: categoryID };
        dispatch(getAnnouncementList(params, callbackList));
      }
    }
  }, [categoryID]);
  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>FREQUENCY REPORT</p>
          <p className='md-title text-gray-20'>
            View recent report activity here
          </p>
        </div>
        <div className='mt-2'>
          <TableAnnouncement
            loading={loading}
            listAnnouncement={announcementList}
            getAnnouncementList={getAnnouncementList}
            updateAnnouncementStatus={updateAnnouncementStatus}
            setAnnouncementList={setAnnouncementList}
            categoryID={categoryID}
            setCategories={setCategories}
            setCategoryID={setCategoryID}
            categories={categories}
            setSearch={setSearch}
            setHotReloadStart={setHotReloadStart}
            hotReloadStart={hotReloadStart}
            search={search}
            callbackList={callbackList}
          />
        </div>
      </div>
    </StandardLanding>
  );
}

export default Landing;
