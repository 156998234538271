import React, { useEffect, useState } from 'react';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableInformationOperation from '../components/TableInformationOperation';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInfoOperation } from '../action';
import {
  getReportType,
  getArchiveList,
  getCategoriesReport,
} from '../../general/action';

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const role = state.profile.showProfile?.role;
  const { reportType, listInfo, loading, listArchive } = state.general;
  // console.log(state.general);
  const { listUnit } = state.account;

  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = useState('');
  const [reportTypeID, setReportTypeID] = useState('');

  useEffect(() => {
    const name = 'information_operations';
    const params = { category: name };
    dispatch(getArchiveList(params));
  }, []);

  useEffect(() => {
    if (categoryID && reportTypeID) {
      console.log('check cat');
      const params = { category: categoryID, report_type: reportTypeID };
      dispatch(getArchiveList(params));
    }
  }, [categoryID, reportTypeID]);

  useEffect(() => {
    if (categoryID && reportTypeID) {
      console.log('check cat 2');
      console.log(categoryID, ' ', reportType[0]?.id);
      const params = { category: categoryID, report_type: reportType[0]?.id };
      dispatch(getArchiveList(params));
    }
  }, [reportType]);
  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>ARCHIVE</p>
          <p className='md-title text-gray-20'>
            View tabulated summary of reports here
          </p>
        </div>
        <div className='mt-2'>
          {state && (
            <TableInformationOperation
              loading={loading}
              listInfo={listInfo}
              reportType={reportType}
              deleteInfoOperation={deleteInfoOperation}
              getInfoOperationList={listArchive}
              role={role}
              categories={categories}
              reportTypeID={reportTypeID}
              categoryID={categoryID}
              setCategories={setCategories}
              setCategoryID={setCategoryID}
              setReportTypeID={setReportTypeID}
            />
          )}
        </div>
      </div>
    </StandardLanding>
  );
};

export default Landing;
