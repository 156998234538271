export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
export const API_URL = `${BASE_URL}/api`;

export const API_LOGIN = `${API_URL}/v1/login`;
export const API_FORGOT_PASSWORD = `${API_URL}/v1/forgot-password`;
export const API_RESET_PASSWORD = `${API_URL}/v1/reset-password?`;

export const API_LIST_CATEGORIES_LIST = `${API_URL}/v1/categories?`;
export const API_LIST_INFORMATION_OPERATION = `${API_URL}/v1/information-operations?`;
export const API_LIST_ARCHIVE_LIST = `${API_URL}/v1/archives?`;
export const API_DELETE_INFORMATION_OPERATION = `${API_URL}/v1/information-operations/`;
export const API_DELETE_ARCHIVE = `${API_URL}/v1/archives`;
export const API_ADD_INFORMATION_OPERATION = `${API_URL}/v1/information-operations`;
export const API_SHOW_INFORMATION_OPERATION = `${API_URL}/v1/information-operations/`;
export const API_EDIT_INFORMATION_OPERATION = `${API_URL}/v1/information-operations/`;
export const API_REPORT_TYPE = `${API_URL}/v1/report-types?category=`;
export const API_UPDATE_INFORMATION_OPERATION_STATUS = `${API_URL}/v1/information-operations/`;
export const API_INFORMATION_OPERATION_STATUS = `${API_URL}/v1/information-operations/`;

export const API_PUBLIC_INFORMATION_LIST = `${API_URL}/v1/public-informations?`;
export const API_SHOW_PUBLIC_INFORMATION = `${API_URL}/v1/public-informations/`;
export const API_ADD_PUBLIC_INFORMATION = `${API_URL}/v1/public-informations`;
export const API_EDIT_PUBLIC_INFORMATION = `${API_URL}/v1/public-informations/`;
export const API_DELETE_PUBLIC_INFORMATION = `${API_URL}/v1/public-informations/`;
export const API_UPDATE_PUBLIC_INFORMATION_STATUS = `${API_URL}/v1/public-informations/`;

export const API_COMMUNITY_AFFAIR_LIST = `${API_URL}/v1/community-affairs?`;
export const API_SHOW_COMMUNITY_AFFAIR = `${API_URL}/v1/community-affairs/`;
export const API_ADD_COMMUNITY_AFFAIR = `${API_URL}/v1/community-affairs`;
export const API_EDIT_COMMUNITY_AFFAIR = `${API_URL}/v1/community-affairs/`;
export const API_DELETE_COMMUNITY_AFFAIR = `${API_URL}/v1/community-affairs/`;
export const API_UPDATE_COMMUNITY_AFFAIR_STATUS = `${API_URL}/v1/community-affairs/`;

export const API_SALAAM_POLICE_LIST = `${API_URL}/v1/salaam-polices?`;
export const API_SHOW_SALAAM_POLICE = `${API_URL}/v1/salaam-polices/`;
export const API_ADD_SALAAM_POLICE = `${API_URL}/v1/salaam-polices`;
export const API_EDIT_SALAAM_POLICE = `${API_URL}/v1/salaam-polices/`;
export const API_DELETE_SALAAM_POLICE = `${API_URL}/v1/salaam-polices/`;
export const API_UPDATE_SALAAM_POLICE_STATUS = `${API_URL}/v1/salaam-polices/`;

export const API_DEVELOPMENT_LIST = `${API_URL}/v1/developments?`;
export const API_SHOW_DEVELOPMENT = `${API_URL}/v1/developments/`;
export const API_ADD_DEVELOPMENT = `${API_URL}/v1/developments`;
export const API_EDIT_DEVELOPMENT = `${API_URL}/v1/developments/`;
export const API_DELETE_DEVELOPMENT = `${API_URL}/v1/developments/`;
export const API_UPDATE_DEVELOPMENT_STATUS = `${API_URL}/v1/developments/`;

export const API_PLAN_PROGRAM_LIST = `${API_URL}/v1/plans-programs?`;
export const API_SHOW_PLAN_PROGRAM = `${API_URL}/v1/plans-programs/`;
export const API_ADD_PLAN_PROGRAM = `${API_URL}/v1/plans-programs`;
export const API_EDIT_PLAN_PROGRAM = `${API_URL}/v1/plans-programs/`;
export const API_DELETE_PLAN_PROGRAM = `${API_URL}/v1/plans-programs/`;
export const API_UPDATE_PLAN_PROGRAM_STATUS = `${API_URL}/v1/plans-programs/`;

export const API_ADMIN_LIST = `${API_URL}/v1/admins?`;
export const API_SHOW_ADMIN = `${API_URL}/v1/admins/`;
export const API_ADD_ADMIN = `${API_URL}/v1/admins`;
export const API_EDIT_ADMIN = `${API_URL}/v1/admins/`;
export const API_DELETE_ADMIN = `${API_URL}/v1/admins/`;
export const API_UPDATE_ADMIN_STATUS = `${API_URL}/v1/admins/`;

export const API_ANNOUNCEMENT_LIST = `${API_URL}/v1/announcements?search=`;
export const API_SHOW_ANNOUNCEMENT = `${API_URL}/v1/announcements/`;
export const API_GET_REFERENCE_TABLE = `${API_URL}/v1/reference-tables`;
export const API_GET_FREQUENCY_REPORT = `${API_URL}/v1/frequency-reports`;
export const API_GET_ACTIVITYLOG = `${API_URL}/v1/activities`;
export const API_FEATURED_ANNOUNCEMENT = `${API_URL}/v1/dashboard/featured-announcement/`;

export const API_ADD_ANNOUNCEMENT = `${API_URL}/v1/announcements`;
export const API_EDIT_ANNOUNCEMENT = `${API_URL}/v1/announcements/`;
export const API_UPDATE_ANNOUNCEMENT_STATUS = `${API_URL}/v1/announcements/`;
export const API_DELETE_ANNOUNCEMENT_STATUS = `${API_URL}/v1/announcements/`;

export const API_FEATURE_ANNOUNCEMENT = `${API_URL}/v1/announcements/`;
export const API_ACCOUNT_LIST = `${API_URL}/v1/users?`;
export const API_SHOW_ACCOUNT = `${API_URL}/v1/users/`;

export const API_SHOW_USER_ACCOUNT = `${API_URL}/v1/profile`;

export const API_ADD_ACCOUNT = `${API_URL}/v1/users`;

export const API_EDIT_ACCOUNT = `${API_URL}/v1/users/`;
export const API_EDIT_USER_ACCOUNT = `${API_URL}/v1/profile/`;

export const API_UPDATE_ACCOUNT_STATUS = `${API_URL}/v1/users/`;

export const API_UNIT_LIST = `${API_URL}/v1/units`;
export const API_ROLE_LIST = `${API_URL}/v1/roles`;
export const API_POSITION_LIST = `${API_URL}/v1/ranks`;

export const API_SHOW_PROFILE = `${API_URL}/v1/profile`;

export const API_PENDING_REPORT_LIST = `${API_URL}/v1/dashboard/pending-reports?`;
export const API_RECENT_REPORT_LIST = `${API_URL}/v1/dashboard/recent-reports`;

export const API_SUMMARY = `${API_URL}/v1/summary-reports?category=information_operations`;
export const VIEW_ALL_NOTIFICATION = `${API_URL}/v1/notifications`;
export const PRIVACYPOLICY = `${API_URL}/v1/settings/privacy-policy`;
