import {
  authHeader,
  getAuthHeader,
  getError,
  header,
} from '../../shared/utils';
import {
  API_ANNOUNCEMENT_LIST,
  API_SHOW_ANNOUNCEMENT,
  API_ADD_ANNOUNCEMENT,
  API_EDIT_ANNOUNCEMENT,
  API_UPDATE_ANNOUNCEMENT_STATUS,
  API_FEATURED_ANNOUNCEMENT,
  API_GET_REFERENCE_TABLE,
} from '../../shared/constant/url';

export const ANNOUNCEMENT = {
  LIST_ANNOUNCEMENT_START: 'LIST_ANNOUNCEMENT_START',
  LIST_ANNOUNCEMENT_SUCCESS: 'LIST_ANNOUNCEMENT_SUCCESS',
  LIST_ANNOUNCEMENT_ERROR: 'LIST_ANNOUNCEMENT_ERROR',

  SHOW_ANNOUNCEMENT_START: 'SHOW_ANNOUNCEMENT_START',
  SHOW_ANNOUNCEMENT_SUCCESS: 'SHOW_ANNOUNCEMENT_SUCCESS',
  SHOW_ANNOUNCEMENT_ERROR: 'SHOW_ANNOUNCEMENT_ERROR',

  ADD_ANNOUNCEMENT_START: 'ADD_ANNOUNCEMENT_START',
  ADD_ANNOUNCEMENT_SUCCESS: 'ADD_ANNOUNCEMENT_SUCCESS',
  ADD_ANNOUNCEMENT_ERROR: 'ADD_ANNOUNCEMENT_ERROR',

  EDIT_ANNOUNCEMENT_START: 'EDIT_ANNOUNCEMENT_START',
  EDIT_ANNOUNCEMENT_SUCCESS: 'EDIT_ANNOUNCEMENT_SUCCESS',
  EDIT_ANNOUNCEMENT_ERROR: 'EDIT_ANNOUNCEMENT_ERROR',

  UPDATE_ANNOUNCEMENT_START: 'UPDATE_ANNOUNCEMENT_START',
  UPDATE_ANNOUNCEMENT_SUCCESS: 'UPDATE_ANNOUNCEMENT_SUCCESS',
  UPDATE_ANNOUNCEMENT_ERROR: 'UPDATE_ANNOUNCEMENT_ERROR',
};

export const getFeaturedAnnouncementList = (params) => {
  return (dispatch) => {
    dispatch({ type: ANNOUNCEMENT.LIST_ANNOUNCEMENT_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };

    fetch(`${API_FEATURED_ANNOUNCEMENT}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ANNOUNCEMENT.LIST_ANNOUNCEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({
          type: ANNOUNCEMENT.LIST_ANNOUNCEMENT_ERROR,
          payload: error,
        });
      });
  };
};

export const getAnnouncementList = (params, callback = null) => {
  let token = localStorage.getItem('token');
  return (dispatch) => {
    dispatch({ type: ANNOUNCEMENT.LIST_ANNOUNCEMENT_START });

    let requestOption = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Accept': 'application/json',
      },
    };

    // console.log(`${API_ANNOUNCEMENT_LIST}${params ? params.search : ""}` +
    // "&per_page=1000")

    fetch(
      `${API_GET_REFERENCE_TABLE}?category_type=${
        params.category_type ? params.category_type : ''
      }&table_type=${params.table_type ? params.table_type : ''}&search=${
        params.search ? params.search : ''
      }&per_page=1000`,
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);

        dispatch({
          type: ANNOUNCEMENT.LIST_ANNOUNCEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({
          type: ANNOUNCEMENT.LIST_ANNOUNCEMENT_ERROR,
          payload: error,
        });
      });
  };
};

export const onShowAnnouncement = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ANNOUNCEMENT.SHOW_ANNOUNCEMENT_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(`${API_GET_REFERENCE_TABLE}/${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ANNOUNCEMENT.SHOW_ANNOUNCEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, getError(error));
        dispatch({
          type: ANNOUNCEMENT.SHOW_ANNOUNCEMENT_ERROR,
          payload: error,
        });
      });
  };
};

export const addAnnouncement = (params, callback = null) => {
  console.log(params);
  return (dispatch) => {
    dispatch({ type: ANNOUNCEMENT.ADD_ANNOUNCEMENT_START });
    let formData = new FormData();

    formData.append('category_type', params.category);
    formData.append('name', params.name);
    formData.append('table_type', params.table_type);
    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_GET_REFERENCE_TABLE, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        console.log(response);
        dispatch({
          type: ANNOUNCEMENT.ADD_ANNOUNCEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ANNOUNCEMENT.ADD_ANNOUNCEMENT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editAnnouncement = (params, id, callback = null) => {
  console.log(params);
  return (dispatch) => {
    dispatch({ type: ANNOUNCEMENT.EDIT_ANNOUNCEMENT_START });
    let formData = new FormData();

    if (params.category) {
      formData.append('category', params.category);
    }
    if (params.name) {
      formData.append('name', params.name);
    }

    formData.append('_method', 'PUT');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_GET_REFERENCE_TABLE}/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ANNOUNCEMENT.EDIT_ANNOUNCEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ANNOUNCEMENT.EDIT_ANNOUNCEMENT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const updateAnnouncementStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ANNOUNCEMENT.UPDATE_ANNOUNCEMENT_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: 'PATCH',
      body: data,
      headers: header(),
    };
    fetch(`${API_UPDATE_ANNOUNCEMENT_STATUS}${id}/feature`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: ANNOUNCEMENT.UPDATE_ANNOUNCEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({
          type: ANNOUNCEMENT.UPDATE_ANNOUNCEMENT_ERROR,
          payload: error,
        });
      });
  };
};
