import React from 'react';
import { Form, Input, Checkbox, Button, message } from 'antd';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import FormWrapper from '../../../shared/components/FormWrapper';

import { login } from '../action';
import { useState } from 'react';

const FormLogin = (props) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onChange = (e) => {
    console.log(e.target.checked);
  };
  const onChangePrivacy = (e) => {
    if (!props.isCheckedDataPrivacy) {
      props.handleShow();
    } else {
      props.setIsCheckedDataPrivacy(false);
    }
  };
  const onFinish = (value) => {
    if (!props.isCheckedDataPrivacy) {
      message.error('Check Data Privacy');
      return;
    }
    dispatch(login(value, callback));
  };

  const callback = (response, error) => {
    let res = response.message;

    if (res === "Login successfully" || res === 'User login successfully.') {
      onResetField();
      message.success('Login successfully');
      localStorage.setItem('token', response.access_token);
      JSON.stringify(
        localStorage.setItem('user', JSON.stringify(response.data)),
      );
    }

    if (response.errors) {
      message.error(response.errors.detail);
      onResetField();
    }
  };

  const onResetField = () => {
    form.setFieldsValue({
      email: '',
      password: '',
    });
  };

  return (
    <FormWrapper>
      <div className='pt-4 pr-3 pb-4 pl-3'>
        <Form
          className='mt-0 mr-4 mb-0 ml-4'
          form={form}
          onFinish={onFinish}
        >
          <div
            className='text-black font-bold mb-4'
            style={{ fontSize: '1.25rem' }}
          >
            Please Login Here
          </div>
          <div>
            <span className='text-dark font-bold'>Email</span>
            <Form.Item
              className='mt-2 mb-2'
              name='email'
              rules={[
                { required: true, message: 'Please input your email' },
                { type: 'email', message: 'Invalid email format!' },
              ]}
            >
              <Input
                placeholder='Email'
                className='form-control'
              />
            </Form.Item>
          </div>
          <div>
            <span className='text-dark font-bold'>Password</span>
            <Form.Item
              className='mt-2 mb-2'
              name='password'
              rules={[
                { required: true, message: 'Please input your password' },
              ]}
            >
              <Input.Password
                placeholder='Password'
                className='h-14'
              />
            </Form.Item>
          </div>
          <div
            className='d-flex'
            style={{ flexWrap: 'wrap' }}
          >
            <div className='mb-4'>
              <Checkbox
                checked={props.isCheckedDataPrivacy}
                onChange={onChangePrivacy}
                className='text-gray-20'
              >
                Data Privacy
              </Checkbox>
              <Button htmlType='submit'></Button>
            </div>
            {/* <div className='mb-4'>
              <Checkbox
                className='text-gray-20'
                onChange={onChange}
              >
                Remember me
              </Checkbox>
              <Button htmlType='submit'></Button>
            </div> */}
          </div>
        </Form>
        <div className='w-100 d-flex justify-content-center '>
          <button
            className='btn btn-lg  w-100 mt-0 mr-4 mb-0 ml-4 text-white'
            style={{ backgroundColor: '#015e98' }}
            onClick={() => {
              form.submit();
            }}
          >
            Log In
          </button>
        </div>
        <Link
          className=''
          to='/forgot-password'
        >
          <p
            className='text-red-10 text-center mt-3'
            style={{ fontSize: '16px' }}
          >
            Forgot Password?
          </p>
        </Link>
      </div>
    </FormWrapper>
  );
};

export default FormLogin;
