import React from "react";
import styled from "@emotion/styled";

function TableWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default TableWrapper;

const StyledDiv = styled.div({
    ".ant-table-thead > tr > th": {
      background: "#ECECEC"
    },
});
