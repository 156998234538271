import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import FormAddEditRecord from '../../general/FormAddEditRecord';
import { addNewInfoRecord, editInfoRecord } from '../action';
import { showInfoOperation } from '../../general/action';

const Landing = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(showInfoOperation(id, callback));
    }
  }, []);

  const callback = (response) => {
    console.log(response);
  };

  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>PRESS RELEASE & BRIEFING</p>
          <p className='md-title text-gray-20'>Add new report here</p>
        </div>
        <div className='mt-2'>
          <FormAddEditRecord
            editInfoRecord={editInfoRecord}
            addNewInfoRecord={addNewInfoRecord}
          />
        </div>
      </div>
    </StandardLanding>
  );
};

export default Landing;
