import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Select, Button, Input, message } from 'antd';
import FormWrapper from '../../../shared/components/FormWrapper';
import './FormSearch.scss';
import {
  getInfoOperationList,
  getPublicInfo,
  getCommunityAffairList,
  getSalaamPoliceList,
  getDevelopmentList,
  getPlanProgramList,
  getAdminList,
} from '../../general/action';
import UI from '../../../shared/react-native/UI/js';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Moment from 'moment';
import { ArchiveAll } from '../action';
import { useRef } from 'react';
import { Fragment } from 'react';
import { TreeSelect } from 'antd';
import { treeData, useDateRange } from '../../../shared/hooks/useDateRange';
import { BiReset } from 'react-icons/bi';
const FormSearch = (props) => {
  const { reportType } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const user = JSON.parse(localStorage.getItem('user'));
  const [form] = Form.useForm();
  const [filterValue, setFilterValue] = useState(null);
  const [unitValue, setUnitValue] = useState(null);
  const [search, setSearch] = useState('');
  const [allUnits, setAllUnits] = useState();
  const [isshowDate, setisshowDate] = useState(true);
  const [reportTypeID, setReportTypeID] = useState();
  const { Option } = Select;
  const [hotReloadStart, setHotReloadStart] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  useEffect(() => {
    if (props) {
      const { listUnit } = props;
      setAllUnits(listUnit);

      // console.log(props);
    }
  }, [allUnits, props]);
  useEffect(() => {
    // date range
    if (hotReloadStart) {
      const formatDateStart = Moment(selectionRange[0].startDate).format(
        'MM/DD/YYYY',
      );
      const formatDateEnd = Moment(selectionRange[0].endDate).format(
        'MM/DD/YYYY',
      );
      const params = { formatDateStart, formatDateEnd };
      dispatch(getSalaamPoliceList(params));
    }
  }, [selectionRange]);
  useEffect(() => {
    if (hotReloadStart) {
      const identifier = setTimeout(() => {
        const params = {
          search: search,
        };
        dispatch(getSalaamPoliceList(params));
      }, 500);

      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);
  const renderReportType = () => {
    return reportType?.map((item) => {
      return (
        <option
          key={item.id}
          value={item.id}
        >
          {item.name}
        </option>
      );
    });
  };

  const onFinish = (value) => {
    let newValue = {};

    if (value == 'clear') {
      newValue.search = '';
    } else {
      newValue.search = search;
    }

    switch (pathname) {
      case '/information-operations':
        return dispatch(getInfoOperationList(newValue));
        break;
      case '/public-information':
        return dispatch(getPublicInfo(newValue));
        break;
      case '/community-affairs':
        return dispatch(getCommunityAffairList(newValue));
        break;
      case '/salaam-police':
        return dispatch(getSalaamPoliceList(newValue));
        break;
      case '/development':
        return dispatch(getDevelopmentList(newValue));
        break;
      case '/plan-program':
        return dispatch(getPlanProgramList(newValue));
        break;
      case '/admin':
        return dispatch(getAdminList(newValue));
        break;
    }
  };

  const onFilterByDate = (e) => {
    if (props) {
      props.onDateChange(e);
    }

    setFilterValue(e);
  };

  const filterByUnit = (e) => {
    if (props) {
      props.onUnitChange(e);
    }

    setUnitValue(e);
  };

  const onFilterByReportType = (e) => {
    setReportTypeID(e);
    if (props) {
      props.onReportTypeChange(e);
    }
  };

  const ArchiveAllHandler = () => {
    if (!reportTypeID) {
      message.error('Select Report Type');
      return;
    }
    // if (!selectionRange.startDate && !selectionRange.endDate) {
    //   message.error('Select Date Range');
    //   return;
    // }
    const params = {
      report_type: reportTypeID,
      // start_date: Moment(selectionRange[0].startDate).format('MM/DD/YYYY'),
      // end_date: Moment(selectionRange[0].endDate).format('MM/DD/YYYY'),
    };
    console.log(params);
    dispatch(ArchiveAll(params, callbackArchive));
    setReportTypeID('');
  };
  const callbackArchive = (response) => {
    console.log(response);
    message.info(response.message);
    dispatch(getSalaamPoliceList());
  };
  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setisshowDate(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <FormWrapper>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <div className='row'>
          <div className='col-lg col-md-6  gap h-11 mb-4'>
            <Form.Item style={{ width: '180px' }}>
              <Select
                defaultValue={''}
                value={reportTypeID}
                onChange={(e) => {
                  setReportTypeID(e);
                  dispatch(getSalaamPoliceList({ report_type: e }));
                }}
              >
                {' '}
                <Option value={''}>Report Type</Option>
                {renderReportType()}
              </Select>
            </Form.Item>

            <Link to={'/add-salaam-police'}>
              <Button className='bg-blue-10 w-full text-white h-11 '>
                Add Record&nbsp;<i className='fas fa-plus'></i>
              </Button>
            </Link>
          </div>
          {user.role === 'super_admin' && (
            <div className='col-lg col-md-6  gap  h-11 mb-4'>
              <Fragment>
                <Select
                  placeholder='Unit'
                  value={unitValue}
                  style={{ width: '100%' }}
                  onChange={filterByUnit}
                >
                  {allUnits &&
                    allUnits.map((data) => {
                      return <option value={data.name}>{data.name}</option>;
                    })}
                </Select>

                <Form.Item>
                  <Button
                    hidden
                    htmlType='submit'
                  ></Button>
                  <div
                    onClick={() => {
                      filterByUnit(allUnits[0]?.name);
                    }}
                  >
                    <Button className='bg-blue-10 text-white h-11 '>
                      Reset Filter
                    </Button>
                  </div>
                </Form.Item>
              </Fragment>
            </div>
          )}
          <div className='col-lg col-md-6  gap mb-4'>
            <Form.Item className='custom-date'>
              <p className='absolute z-50 flex  bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md'>
                Filter by:
              </p>
              <Button
                className='buttonDate '
                style={{
                  cursor: 'pointer',
                  border: '1px solid #C5C5C5',
                  height: '44px',
                  borderLeft: 'none',
                  minWidth: '190px',
                }}
                placeholder='Date'
                onClick={() => {
                  return setisshowDate((prev) => {
                    if (!hotReloadStart) {
                      setHotReloadStart(true);
                    }
                    if (prev) {
                      setisshowDate(false);
                    } else {
                      setisshowDate(true);
                    }
                  });
                }}
              >
                Date
              </Button>
            </Form.Item>

            <div
              onClick={() => {
                dispatch(getSalaamPoliceList());
              }}
            >
              <Button className='bg-blue-10 w-full text-white h-11 '>
                Reset Filter
              </Button>
            </div>
          </div>
          <div className='col-lg col-md-6   gap h-11 mb-4'>
            <TreeSelect
              size='middle'
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder='Please select'
              onChange={useDateRange(dispatch, 'salaam_police')}
            />

            <Button
              className='bg-blue-10 text-white h-11'
              onClick={() => {
                dispatch(getSalaamPoliceList());
              }}
            >
              <BiReset size={18} />
            </Button>
          </div>
          <div className='col-lg col-md-6   gap'>
            <Form.Item name='search'>
              <Input
                onChange={(e) => {
                  if (!hotReloadStart) {
                    setHotReloadStart(true);
                  }
                  setSearch(e.target.value);
                }}
                value={search}
                placeholder='Search here'
              />
              <i className='fas fa-search absolute text-gray-20 -ml-8 mt-2 text-lg p-0'></i>
            </Form.Item>
            {user.role === 'super_admin' && (
              <Button
                onClick={ArchiveAllHandler}
                className='bg-blue-10  text-white h-11'
              >
                Archive All
              </Button>
            )}
          </div>
        </div>
      </Form>
      <div
        className='container'
        style={{ display: isshowDate ? 'none' : 'block' }}
        ref={ref}
      >
        <DateRangePicker
          editableDateInputs={true}
          onChange={(item) => setSelectionRange([item.selection])}
          moveRangeOnFirstSelection={false}
          showMonthAndYearPickers={true}
          ranges={selectionRange}
        />
      </div>
    </FormWrapper>
  );
};

export default FormSearch;
