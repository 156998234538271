import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import FormAddEdit from '../components/FormAddEdit';
import {
  showAccount,
  showUserAccount,
  getPositionList,
  getRoleList,
  getUnitList,
  addAccount,
  editAccount,
  editUserAccount,
} from '../action';

const Landing = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const data = localStorage.getItem('user');
      console.log(data);
      let role = 'admin';
      if (data) {
        let user = JSON.parse(data);
        role = user.role;
      }

      if (pathname === `/edit-account/${id}`) {
        dispatch(showAccount(id, callback));
      } else if (pathname === `/edit-profile/${id}`) {
        dispatch(showUserAccount(id, callback));
      } else if (pathname === '/add-account') {
        return 'CREATE ACCOUNT';
      }
    }

    dispatch(getPositionList());
    dispatch(getRoleList());
    dispatch(getUnitList());
  }, []);

  const callback = () => {};

  const renderTitle = () => {
    if (pathname === `/edit-account/${id}`) {
      return 'UPDATE ACCOUNT';
    } else if (pathname === `/edit-profile/${id}`) {
      return 'EDIT PROFILE';
    } else if (pathname === '/add-account') {
      return 'CREATE ACCOUNT';
    }
  };
  const renderSubTitle = () => {
    if (pathname === `/edit-account/${id}`) {
      return 'UPDATE ACCOUNT';
    } else if (pathname === `/edit-profile/${id}`) {
      return 'Edit your profile here';
    } else if (pathname === '/add-account') {
      return 'Create user account here';
    }
  };

  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>{renderTitle()}</p>
          <p className='md-title text-gray-20'>{renderSubTitle()}</p>
        </div>
        <div className='mt-2'>
          <FormAddEdit
            addAccount={addAccount}
            editAccount={editAccount}
            editUserAccount={editUserAccount}
          />
        </div>
      </div>
    </StandardLanding>
  );
};

export default Landing;
