import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StandardLanding from "../../../shared/components/StandardLanding";
import TableAnnouncement from "../components/TableAnnouncement";
import { getAnnouncementList, updateAnnouncementStatus } from "../action";

import UI from "../../../shared/react-native/UI/js";

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { loading, listAnnouncement } = state.announcement;
  const [announcementList, setAnnouncementList] = useState([]);

  useEffect(() => {
    // dispatch(getAnnouncementList());
    get_announcement();
  }, []);

  useEffect(() => {
    get_announcement();
  }, [getAnnouncementList]);

  // useEffect(() => {
  //   get_announcement();
  // },[getAnnouncementList])

  const get_announcement = async () => {
    UI.apiRequest({
      method: "get",
      url: "announcements",
      params: {
        search: "",
        per_page: "1000",
        order_by: "DESC",
      },
      onSuccess: (data) => {
        setAnnouncementList(data.data);
      },
      onFail: (data) => { },
      isMultiPart: false,
    });
  };

  const search_announcement = async (value) => {
    UI.apiRequest({
      method: "get",
      url: "announcements",
      params: {
        search: `${value.search}`,
        per_page: "1000",
        order_by: "ASC",
      },
      onSuccess: (data) => {
        setAnnouncementList(data.data);
      },
      onFail: (data) => { },
      isMultiPart: false,
    });
  };

  return (
    <StandardLanding>
      <div className="main-container content-crop">
        <div className="flex justify-between">
          <p className="lg-title text-blue-10">ANNOUNCEMENTS</p>
          <p className="md-title text-gray-20">
            View announcement list here
          </p>
        </div>
        <div className="mt-2">
          <TableAnnouncement
            loading={loading}
            listAnnouncement={announcementList}
            getAnnouncementList={getAnnouncementList}
            updateAnnouncementStatus={updateAnnouncementStatus}
            refreshData={get_announcement}
            searchData={e => { search_announcement(e); }}
          />
        </div>
      </div>
    </StandardLanding>
  );
};

export default Landing;
