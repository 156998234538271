import React, { useState, useEffect } from 'react';
import { Table, Popconfirm, message, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from '../../../shared/components/Style';
import FormSearch from './FormSearch';
import TableWrapper from '../../../shared/components/TableWrapper';
import { BsFillPencilFill } from 'react-icons/bs';
import axios from 'axios';
import {
  API_DELETE_ANNOUNCEMENT_STATUS,
  API_FEATURE_ANNOUNCEMENT,
  API_GET_REFERENCE_TABLE,
} from '../../../shared/constant/url';
import { getAuthHeader, getError, header } from '../../../shared/utils';
import { render } from '@testing-library/react';
import { GENERAL } from '../../general/action';
import { INFO } from '../../info-operation/action';
import { AiFillEye } from 'react-icons/ai';
const TableAnnouncement = (props) => {
  const [id, setId] = useState('');
  const dispatch = useDispatch();
  const {
    loading,
    listAnnouncement,
    getAnnouncementList,
    updateAnnouncementStatus,
    refreshData,
    searchData,
    setAnnouncementList,
    categories,
    categoryID,
    setCategories,
    setCategoryID,
    setSearch,
    setHotReloadStart,
    hotReloadStart,
    search,
    callbackList,
  } = props;

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'id',
    },
    {
      title: 'Sub-Category',

      dataIndex: 'subcategory',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'id',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '15%',
      key: 'id',
    },
    {
      title: 'Date and Time',
      dataIndex: 'date',
      key: 'id',
    },

    {
      title: 'Action',
      dataIndex: 'x',
      key: 'x',
      render: (data, rowData) => renderAction(rowData),
    },
  ];

  const renderDatePosted = (data) => {
    return <p>{data.created?.string}</p>;
  };

  const renderStatus = (data) => {
    switch (data.status) {
      case 'featured':
        return (
          <p
            style={{
              ...styled.approved,
              padding: '5px',
              width: '125px',
              textTransform: 'uppercase',
            }}
          >
            Featured
          </p>
        );
        break;

      case 'not-featured':
        return (
          <p
            style={{
              ...styled.declined,
              padding: '5px',
              width: '125px',
              textTransform: 'uppercase',
            }}
          >
            Not Featured
          </p>
        );
        break;
    }
  };

  const feature = () => {
    let param = { status: 'featured' };
    dispatch(updateAnnouncementStatus(param, id, callback));
  };

  const unfeature = () => {
    let param = { status: 'unfeatured' };
    dispatch(updateAnnouncementStatus(param, id, callback));
  };

  // const menu = (
  //   <div className="flex flex-col">
  //     <button style={styled.approved} className="px-3" onClick={feature}>
  //       Feature
  //     </button>
  //     {/* <button style={styled.declined} onClick={unfeature}>Unfeature</button> */}
  //   </div>
  // );

  // // const onDeleteItem = (id) => {
  // //     dispatch(deleteCommunityAffair(id, callback))
  // // }

  // const renderDropdown = (data) => {
  //   if (data.status === "featured") {
  //     return <div></div>;
  //   } else {
  //     return (
  //       <Popover content={menu} trigger="click" placement="bottom">
  //         <button onClick={() => setId(data.id)}>
  //           <i style={styled.btn} className="fas fa-caret-down bg-green-10"></i>
  //         </button>
  //       </Popover>
  //     );
  //   }
  // };

  const callback = (response, error) => {
    let res = response.message;
    if (res === 'Request failed with status code 403') {
      message.error('You do not have the required authorization.');
    } else {
      const { data } = response;
      setAnnouncementList(data);
      // const params = { category: 'information_operations', search: '' };
      // dispatch(getAnnouncementList(params));
    }
  };

  const onDeleteItem = (id, data) => {
    console.log(data);
    let setStatus;
    if (data.status === 'active') {
      setStatus = 'inactive';
    }
    if (data.status === 'inactive') {
      setStatus = 'active';
    }
    dispatch({ type: INFO.DELETE_START });
    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: setStatus }),
    };
    fetch(`${API_GET_REFERENCE_TABLE}/${id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        const params = { category: categoryID, search: '' };
        dispatch(getAnnouncementList(params, callback));
        dispatch({
          type: INFO.DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: INFO.DELETE_ERROR, payload: error });
      });
    const headers = {
      headers: getAuthHeader(),
    };
  };
  const onFeature = (id, data) => {
    console.log(data);
    let setStatus;
    if (data.status === 'active') {
      setStatus = 'inactive';
    }
    if (data.status === 'inactive') {
      setStatus = 'active';
    }

    let requestOption = {
      method: 'PATCH',
      headers: header(),
      body: JSON.stringify({ status: setStatus }),
    };

    fetch(`${API_GET_REFERENCE_TABLE}/${id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data);

        callback(response, null);
      })
      .catch((error) => {
        // callback(null, getError(error));
      });
  };

  const renderAction = (data) => {
    // console.log(data);
    return (
      <div
        className='d-flex flex-row flex-nowrap align-items-center'
        style={{ gap: '1em' }}
      >
        <Link
          to={`/view-frequency/${data.id}`}
          style={{ width: '2rem' }}
        >
          <AiFillEye
            className='py-2 px-1 rounded'
            style={{ backgroundColor: '#339ce2' }}
            color='white'
            size={35}
          />
        </Link>

        <Link
          to={`/edit-frequency/${data.id}`}
          style={{ width: '2rem' }}
        >
          <BsFillPencilFill
            className=' py-2 px-1 rounded'
            color='white'
            size={35}
            style={{
              background: 'black',
              cursor: 'pointer',
            }}
          />
        </Link>
        {/* <Popconfirm
          placement='top'
          title='Are you sure you want to delete this?'
          onConfirm={() => onDeleteItem(data.id, data)}
          okText='Yes'
          cancelText='No'
        >
          <button>
            <div className='bg-success p-2 text-center rounded'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20.284 19.27'
              >
                <path
                  id='icons8_documents'
                  d='M6.282,5A2.285,2.285,0,0,0,4,7.282V21.988A2.285,2.285,0,0,0,6.282,24.27H8.473a3.282,3.282,0,0,1-.923-2.282V7.282A3.282,3.282,0,0,1,8.473,5Zm4.564,0A2.285,2.285,0,0,0,8.564,7.282V21.988a2.285,2.285,0,0,0,2.282,2.282H22a2.285,2.285,0,0,0,2.282-2.282V12.606H18.959a2.285,2.285,0,0,1-2.282-2.282V5Zm7.353.446v4.879a.762.762,0,0,0,.761.761h4.879Zm-4.817,9.7h6.085a.761.761,0,0,1,0,1.521H13.381a.761.761,0,1,1,0-1.521Zm0,3.55h4.564a.761.761,0,0,1,0,1.521H13.381a.761.761,0,1,1,0-1.521Z'
                  transform='translate(-4 -5)'
                  fill='#fff'
                />
              </svg>
            </div>
          </button>
        </Popconfirm>
        {data.status === 'not-featured' && (
          <Popconfirm
            placement='top'
            title='Are you sure you want to feature this?'
            onConfirm={() => onFeature(data.id, data)}
            okText='Yes'
            cancelText='No'
          >
            <div
              style={{ width: '2rem' }}
              // onClick={(e) => handleFeature(id, data.status)}
            >
              <i
                style={{ ...styled.btn, margin: '0', width: '100%' }}
                className={`fas fa-book bg-green-10 `}
              ></i>
            </div>
          </Popconfirm>
        )} */}
      </div>
    );
  };

  const handleFeature = (id, status) => {
    if (status == 'featured') {
      return;
    }

    let param = { status: 'featured' };
    dispatch(updateAnnouncementStatus(param, id, callback));
  };

  const renderRefresh = (value) => {
    searchData(value);
  };

  return (
    <div className='sub-container p-5'>
      <FormSearch
        getAnnouncementList={getAnnouncementList}
        categories={categories}
        categoryID={categoryID}
        setCategories={setCategories}
        setCategoryID={setCategoryID}
        setSearch={setSearch}
        search={search}
        setAnnouncementList={setAnnouncementList}
        setHotReloadStart={setHotReloadStart}
        hotReloadStart={hotReloadStart}
        callbackList={callbackList}
      />
      <TableWrapper>
        <Table
          loading={loading}
          // dataSource={allList}
          dataSource={listAnnouncement}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </TableWrapper>
    </div>
  );
};

export default TableAnnouncement;
