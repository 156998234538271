import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';
import { Link } from 'react-router-dom';
import CardWrapper from '../../../shared/components/CardWrapper';

import TouchableOpacity, {
  View,
  StyleSheet,
  Text,
  ImageBackground,
  Shadow,
} from '../../../shared/custom-react-native';

import UI from '../../../shared/react-native/UI/js';

const CardAnnouncement = (props) => {
  const { listAnnouncement } = props;
  const [announcementImage, setAnnouncementImage] = useState(
    '/images/login_banner.jpg',
  );

  const [cardBgX, setCardBgX] = useState(UI.measure('card_bg').width);
  const [cardBgY, setCardBgY] = useState(UI.measure('card_bg').height);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return function cleanup() {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const onResize = () => {
    setCardBgX(UI.measure('card_bg').width);
    setCardBgY(UI.measure('card_bg').height);
    setAnnouncementImage(listAnnouncement?.file?.url);
  };

  const renderAnnouncement = () => {
    const item = listAnnouncement;

    if (listAnnouncement) {
      if (item.status === 'featured') {
        return (
          <div
            style={{
              backgroundColor: 'white',
              width: '100%',
              borderRadius: 15,
              ...Shadow._3(),
            }}
          >
            <ImageBackground
              id={'card_bg'}
              imageFit={'cover'}
              source={item.file.url}
              style={styles.card_bg}
            >
              <View
                style={{
                  height: 180,
                  width: '100%',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  borderTopRightRadius: 15,
                  borderTopLeftRadius: 15,
                  padding: 25,
                }}
              >
                <Text style={styles.card_text}>Featured Announcement</Text>
              </View>
            </ImageBackground>

            <div
              style={{ padding: 20 }}
              className='card-content'
            >
              <p className='mb-2 font-bold text-blue-10'>{item.title}</p>
              <p className='h-16 overflow-hidden'>{item.description}</p>
              <div className='flex justify-between mt-5'>
                <Link
                  onClick={() => {
                    localStorage.setItem(
                      'announcement_item',
                      JSON.stringify(item),
                    );
                  }}
                  to={`/announcement/${item.id}`}
                >
                  <Button className='bg-blue-10 text-white h-10'>
                    View Announcement
                  </Button>
                </Link>

                <div
                  style={{ marginLeft: 10 }}
                  className='text-gray-20'
                >
                  <p>Date Published:</p>
                  <p>{item.created?.string}</p>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div
          style={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: 15,
            ...Shadow._3(),
          }}
        >
          <ImageBackground
            id={'card_bg'}
            imageFit={'cover'}
            // source={item.file.url}
            style={styles.card_bg}
          >
            <View
              style={{
                height: 180,
                width: '100%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                padding: 25,
              }}
            >
              <Text style={styles.card_text}>Featured Announcement</Text>
            </View>
          </ImageBackground>

          <div
            style={{ padding: 20 }}
            className='card-content'
          >
            <p className='mb-2 font-bold text-blue-10'>No Announcement</p>
          </div>
        </div>
      );
    }
  };

  return <CardWrapper>{renderAnnouncement()}</CardWrapper>;
};

//<Text style={styles.card_text}>Featured Announcement</Text>

const styles = StyleSheet.create({
  card_text: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
  },
  card_bg: {
    height: 180,
    width: '100%',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
});

export default CardAnnouncement;
