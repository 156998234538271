import React, { useEffect } from 'react';
import StandardLanding from '../../../shared/components/StandardLanding';
import TablePublicInfo from '../components/TablePublicInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getReportType, getPublicInfo } from '../../general/action';
import { getUnitList } from '../../user-accounts/action';

const Landing = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { reportType, publicInfo, loading } = state.general;
    const { listUnit } = state.account;

    useEffect(() => {
        const name = 'public_informations';
        dispatch(getReportType(name));
        dispatch(getPublicInfo());
        dispatch(getUnitList());
    }, []);

    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">PUBLIC INFORMATION</p>
                    <p className="md-title text-gray-20">View tabulated summary of reports here</p>
                </div>
                <div className="mt-2">
                    <TablePublicInfo
                        loading={loading}
                        reportType={reportType}
                        publicInfo={publicInfo}
                        listUnit={listUnit}
                    />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
