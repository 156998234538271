import React, { useState, useEffect } from 'react';
import { Table, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
// import FormSearch from "./FormSearch";
import FormSearch from './newFormSearch';
import styled from '../../../shared/components/Style';
import TableWrapper from '../../../shared/components/TableWrapper';
import { AiFillEye } from 'react-icons/ai';
import {
  BsFillPencilFill,
  BsCheckLg,
  BsArrowCounterclockwise,
} from 'react-icons/bs';
const TableAccount = (props) => {
  const { listUnit, listAccount, loading } = props;

  const [dataListInfo, setDataListInfo] = useState();
  const [renderedInfo, setRenderedInfo] = useState();

  const [unitFilter, setUnitFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  useEffect(() => {
    if (props) {
      if (!renderedInfo) {
        if (props.listAccount.length > 0) {
          setRenderedInfo(props.listAccount);
        }
      }

      if (dataListInfo != listAccount) {
        // console.log("Changed");
        setDataListInfo(listAccount);
        setRenderedInfo(listAccount);
      }

      setDataListInfo(props.listAccount);
    }
  }, [props, listAccount, renderedInfo]);

  useEffect(() => {
    renderSearch(statusFilter, unitFilter, dateFilter);
  }, [statusFilter, unitFilter, dateFilter]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'id',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'id',
      render: (data, rowData) => rowData.rank && rowData.rank.name,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'id',
      render: (data, rowData) => rowData.unit && rowData.unit.name,
    },
    {
      title: 'Contact No.',
      dataIndex: 'contact_number',
      key: 'id',
    },
    {
      title: 'Action',
      dataIndex: 'x',
      key: 'x',
      render: (data, rowData) => renderAction(rowData),
    },
  ];

  const renderSearch = (status = '', unit = '', date = '') => {
    let temporaryData = dataListInfo;
    let finalData;

    if (temporaryData) {
      if (status && !unit) {
        const filteredData = temporaryData.filter((data) => {
          return data.status == status;
        });
        finalData = filteredData;
      }

      if (unit && !status) {
        const filteredData = temporaryData.filter((data) => {
          return data.unit.name == unit;
        });
        finalData = filteredData;
      }

      if (date && !unit && !status) {
        const resData = selectDateType(date, temporaryData);
        finalData = resData;
      }

      if (status && unit) {
        const filteredData = temporaryData.filter((data) => {
          return data.status == status && data.unit.name == unit;
        });
        finalData = filteredData;
      }

      if (status && date) {
        const unitFiltered = temporaryData.filter((data) => {
          return data.status == status;
        });

        const resData = selectDateType(date, unitFiltered);
        finalData = resData;
      }

      if (unit && date) {
        const unitFiltered = temporaryData.filter((data) => {
          return data.unit == unit;
        });

        const resData = selectDateType(date, unitFiltered);
        finalData = resData;
      }

      if (status && unit && date) {
        const notFinalData = temporaryData.filter((data) => {
          return data.status == status && data.unit == unit;
        });

        const resData = selectDateType(date, notFinalData);
        finalData = resData;
      }
    }

    setRenderedInfo(finalData);
  };

  const selectDateType = (e, temporaryData) => {
    if (e == 'daily') {
      return weekFilterByDaily(temporaryData);
    } else if (e == 'weekly') {
      return weekFilterByWeekly(temporaryData);
    } else if (e == 'monthly') {
      return weekFilterByMonthly(temporaryData);
    }
  };

  const weekFilterByDaily = (temporaryData) => {
    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.created.string.split('-');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);
      const currentDate = new Date();

      const dataDay = newDate.getDate();
      const currentDay = currentDate.getDate();

      const getDataMonth = newDate.toLocaleString('en-US', {
        month: 'long',
      });
      const getCurrentMonth = currentDate.toLocaleString('en-US', {
        month: 'long',
      });

      const getDataYear = newDate.getFullYear();
      const getCurrentYear = currentDate.getFullYear();

      return (
        dataDay == currentDay &&
        getDataMonth == getCurrentMonth &&
        getDataYear == getCurrentYear
      );
    });

    return filteredData;
  };

  const weekFilterByWeekly = (temporaryData) => {
    const currentDate = new Date();

    let dataWeek = [];
    for (let i = 1; i <= 7; i++) {
      let first = currentDate.getDate() - currentDate.getDay() + i;
      let day = new Date(currentDate.setDate(first)).toISOString().slice(0, 10);
      dataWeek.push(day);
    }

    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.created.string.split('-');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);

      const convertedDay = () => {
        return (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
      };
      const convertedMonth = () => {
        return (
          (newDate.getMonth() < 10 ? '0' : '') +
          (parseInt(newDate.getMonth()) + 1).toString()
        );
      };

      const fullDate = `${newDate.getFullYear()}-${convertedMonth()}-${convertedDay()}`;

      let isTrue = false;
      dataWeek.forEach((week) => {
        if (week == fullDate) {
          isTrue = true;
        }
      });

      return isTrue ? data : '';
    });

    return filteredData;
  };

  const weekFilterByMonthly = (temporaryData) => {
    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.created.string.split('-');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);
      const currentDate = new Date();

      const getDataMonth = newDate.toLocaleString('en-US', {
        month: 'long',
      });
      const getCurrentMonth = currentDate.toLocaleString('en-US', {
        month: 'long',
      });

      const getDataYear = newDate.getFullYear();
      const getCurrentYear = currentDate.getFullYear();

      return getDataMonth == getCurrentMonth && getDataYear == getCurrentYear;
    });

    return filteredData;
  };

  const renderAction = (data) => {
    return (
      <div
        className='d-flex flex-row flex-nowrap align-items-center'
        style={{ gap: '1em' }}
      >
        <Link
          to={`/edit-account/${data.id}?view=true`}
          style={{ width: '2rem' }}
        >
          <AiFillEye
            className='py-2 px-1 rounded'
            style={{ backgroundColor: '#339ce2' }}
            color='white'
            size={35}
          />
        </Link>
        <Link
          to={`/edit-account/${data.id}`}
          style={{ width: '2rem' }}
        >
          <BsFillPencilFill
            className=' py-2 px-1 rounded'
            color='white'
            size={35}
            style={{
              background: 'black',
              cursor: 'pointer',
            }}
          />
        </Link>
      </div>
    );
  };

  return (
    <div className='sub-container p-5'>
      <FormSearch
        listUnit={listUnit}
        onUnitChange={(e) => setUnitFilter(e)}
        onStatusChange={(e) => setStatusFilter(e)}
        onDateChange={(e) => setDateFilter(e)}
      />
      <TableWrapper>
        <Table
          loading={loading}
          dataSource={renderedInfo}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </TableWrapper>
    </div>
  );
};

export default TableAccount;
