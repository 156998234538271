import React from 'react';
import { Avatar, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './SideBar.css';
import {
  HomeOutlined,
  ProfileOutlined,
  InfoOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UsergroupDeleteOutlined,
  BorderOutlined,
  NotificationOutlined,
  SafetyOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';

const SideBar = (props) => {
  const his = useHistory();
  const { showProfile, collapse } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  var active_tab = localStorage.getItem('active_tab');
  var active_key = localStorage.getItem('default_key');

  const AdminSupervisorAccess = () => {
    return user && user.role === 'super_admin'
      ? {
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='14'
              height='14'
              viewBox='0 0 28.664 26.862'
            >
              <path
                id='Path_12'
                data-name='Path 12'
                d='M-9973.349-4243.6c-2.051,0-2.619-.814-2.9-4.1-.32-3.681-.354-3.751-1.771-4.53-1.7-.883-1.981-1.839-1.981-6.227,0-5.379.567-6.3,4.069-6.76.992-.106,2.513-.248,3.469-.282l1.662-.036v10.333c0,11.571,0,11.607-2.5,11.607Zm10.971-4.99-6.3-2.193v-15.358l6.121-2.159a59.85,59.85,0,0,1,7.079-2.159,2.485,2.485,0,0,1,1.661.673c.637.636.709,1.167.709,4.636,0,3.75.033,4,.884,4.777a2.581,2.581,0,0,1,.886,2.265,2.583,2.583,0,0,1-.886,2.265c-.813.778-.884,1.026-.884,4.422,0,3.114-.105,3.68-.709,4.282a2.511,2.511,0,0,1-1.486.708C-9955.691-4246.426-9958.875-4247.416-9962.378-4248.585Z'
                transform='translate(9980.001 4270.457)'
                fill='#fff'
              />
            </svg>
          ),
          label: 'Announcements',
          key: 'announcement',
        }
      : '';
  };
  const userAccount = () => {
    return user && user.role === 'super_admin'
      ? {
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='14'
              height='14'
              viewBox='0 0 21.797 25.644'
            >
              <path
                id='icons8-user-male'
                d='M18.219,4C12,4,8.282,7.475,8.282,13.3v2.258A3.153,3.153,0,0,0,7,18.1a3.211,3.211,0,0,0,2.46,3.118c1.279,5.067,4.608,8.422,8.438,8.422s7.159-3.355,8.438-8.422A3.211,3.211,0,0,0,28.8,18.1a3.141,3.141,0,0,0-1.282-2.541v-.985a8.01,8.01,0,0,0-.376-2.36,1.979,1.979,0,0,0,.849-.93,1.943,1.943,0,0,0-.023-1.625C26.245,6.064,22.693,4,18.219,4Zm-3.231,7.465a13.373,13.373,0,0,0,6.757,1.51,22.431,22.431,0,0,0,3.53-.272,6.1,6.1,0,0,1,.317,1.874V16.1a.96.96,0,0,0,.548.868,1.253,1.253,0,0,1,.734,1.133,1.283,1.283,0,0,1-1.245,1.281c-.027,0-.054,0-.076,0a.962.962,0,0,0-.942.764c-.935,4.459-3.7,7.574-6.713,7.574s-5.777-3.114-6.713-7.573a.963.963,0,0,0-.942-.765c-.022,0-.048,0-.075,0A1.284,1.284,0,0,1,8.923,18.1a1.257,1.257,0,0,1,.733-1.142.963.963,0,0,0,.55-.869V14.406a9.763,9.763,0,0,0,3.471-1.632,7.179,7.179,0,0,0,1.113-1.059c.071-.086.138-.169.2-.249Zm-.3,5.357A1.282,1.282,0,1,0,15.975,18.1,1.282,1.282,0,0,0,14.693,16.822Zm6.411,0A1.282,1.282,0,1,0,22.386,18.1,1.282,1.282,0,0,0,21.1,16.822Z'
                transform='translate(-7 -4)'
                fill='#fff'
              />
            </svg>
          ),
          label: 'User Management',
          key: 'user_accounts',
        }
      : '';
  };
  const summary = () => {
    return (user && user.role === 'super_admin') ||
      (user && user.role === 'supervisor')
      ? {
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='14'
              height='14'
              viewBox='0 0 26 24.7'
            >
              <path
                id='icons8_documents'
                d='M6.925,5A2.929,2.929,0,0,0,4,7.925v18.85A2.929,2.929,0,0,0,6.925,29.7H9.733A4.207,4.207,0,0,1,8.55,26.775V7.925A4.207,4.207,0,0,1,9.733,5Zm5.85,0A2.929,2.929,0,0,0,9.85,7.925v18.85A2.929,2.929,0,0,0,12.775,29.7h14.3A2.929,2.929,0,0,0,30,26.775V14.75H23.175a2.929,2.929,0,0,1-2.925-2.925V5Zm9.425.571v6.254a.976.976,0,0,0,.975.975h6.254ZM16.025,18h7.8a.975.975,0,1,1,0,1.95h-7.8a.975.975,0,1,1,0-1.95Zm0,4.55h5.85a.975.975,0,0,1,0,1.95h-5.85a.975.975,0,1,1,0-1.95Z'
                transform='translate(-4 -5)'
                fill='#fff'
              />
            </svg>
          ),
          label: 'Summary',
          key: 'summary',
        }
      : '';
  };
  const frequencyReport = () => {
    return user && user.role === 'supervisor'
      ? {
          label: 'Frequency Report',
          key: 'frequency_report',
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='14'
              height='14'
              viewBox='0 0 26 24.7'
            >
              <path
                id='icons8_documents'
                d='M6.925,5A2.929,2.929,0,0,0,4,7.925v18.85A2.929,2.929,0,0,0,6.925,29.7H9.733A4.207,4.207,0,0,1,8.55,26.775V7.925A4.207,4.207,0,0,1,9.733,5Zm5.85,0A2.929,2.929,0,0,0,9.85,7.925v18.85A2.929,2.929,0,0,0,12.775,29.7h14.3A2.929,2.929,0,0,0,30,26.775V14.75H23.175a2.929,2.929,0,0,1-2.925-2.925V5Zm9.425.571v6.254a.976.976,0,0,0,.975.975h6.254ZM16.025,18h7.8a.975.975,0,1,1,0,1.95h-7.8a.975.975,0,1,1,0-1.95Zm0,4.55h5.85a.975.975,0,0,1,0,1.95h-5.85a.975.975,0,1,1,0-1.95Z'
                transform='translate(-4 -5)'
                fill='#fff'
              />
            </svg>
          ),
        }
      : '';
  };
  const AdminCMS = () => {
    return user && user.role === 'super_admin'
      ? {
          label: 'ADMIN CMS',
          key: '2',
          children: [
            {
              label: 'Reference Table Maintenance',
              key: 'reference_table',
              icon: (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 26 24.7'
                >
                  <path
                    id='icons8_documents'
                    d='M6.925,5A2.929,2.929,0,0,0,4,7.925v18.85A2.929,2.929,0,0,0,6.925,29.7H9.733A4.207,4.207,0,0,1,8.55,26.775V7.925A4.207,4.207,0,0,1,9.733,5Zm5.85,0A2.929,2.929,0,0,0,9.85,7.925v18.85A2.929,2.929,0,0,0,12.775,29.7h14.3A2.929,2.929,0,0,0,30,26.775V14.75H23.175a2.929,2.929,0,0,1-2.925-2.925V5Zm9.425.571v6.254a.976.976,0,0,0,.975.975h6.254ZM16.025,18h7.8a.975.975,0,1,1,0,1.95h-7.8a.975.975,0,1,1,0-1.95Zm0,4.55h5.85a.975.975,0,0,1,0,1.95h-5.85a.975.975,0,1,1,0-1.95Z'
                    transform='translate(-4 -5)'
                    fill='#fff'
                  />
                </svg>
              ),
            },
            {
              label: 'Privacy Policy',
              key: 'privacy_policy',
              icon: (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 26 24.7'
                >
                  <path
                    id='icons8_documents'
                    d='M6.925,5A2.929,2.929,0,0,0,4,7.925v18.85A2.929,2.929,0,0,0,6.925,29.7H9.733A4.207,4.207,0,0,1,8.55,26.775V7.925A4.207,4.207,0,0,1,9.733,5Zm5.85,0A2.929,2.929,0,0,0,9.85,7.925v18.85A2.929,2.929,0,0,0,12.775,29.7h14.3A2.929,2.929,0,0,0,30,26.775V14.75H23.175a2.929,2.929,0,0,1-2.925-2.925V5Zm9.425.571v6.254a.976.976,0,0,0,.975.975h6.254ZM16.025,18h7.8a.975.975,0,1,1,0,1.95h-7.8a.975.975,0,1,1,0-1.95Zm0,4.55h5.85a.975.975,0,0,1,0,1.95h-5.85a.975.975,0,1,1,0-1.95Z'
                    transform='translate(-4 -5)'
                    fill='#fff'
                  />
                </svg>
              ),
            },
            {
              label: 'Activity Log',
              key: 'activity_log',
              icon: (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 26 24.7'
                >
                  <path
                    id='icons8_documents'
                    d='M6.925,5A2.929,2.929,0,0,0,4,7.925v18.85A2.929,2.929,0,0,0,6.925,29.7H9.733A4.207,4.207,0,0,1,8.55,26.775V7.925A4.207,4.207,0,0,1,9.733,5Zm5.85,0A2.929,2.929,0,0,0,9.85,7.925v18.85A2.929,2.929,0,0,0,12.775,29.7h14.3A2.929,2.929,0,0,0,30,26.775V14.75H23.175a2.929,2.929,0,0,1-2.925-2.925V5Zm9.425.571v6.254a.976.976,0,0,0,.975.975h6.254ZM16.025,18h7.8a.975.975,0,1,1,0,1.95h-7.8a.975.975,0,1,1,0-1.95Zm0,4.55h5.85a.975.975,0,0,1,0,1.95h-5.85a.975.975,0,1,1,0-1.95Z'
                    transform='translate(-4 -5)'
                    fill='#fff'
                  />
                </svg>
              ),
            },
          ],
        }
      : '';
  };
  const archiveAccess = () => {
    return user && user.role === 'super_admin'
      ? {
          label: 'Archive',
          key: 'archive',
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='14'
              height='14'
              viewBox='0 0 29.182 22.714'
            >
              <path
                id='folder-open'
                d='M7.494,40.112H24.336V37.678A2.434,2.434,0,0,0,21.9,35.245H13.791L10.546,32H2.434A2.434,2.434,0,0,0,0,34.434v16.65L4.59,41.9A3.233,3.233,0,0,1,7.494,40.112Zm20.042,1.622H7.494a1.616,1.616,0,0,0-1.45.9L0,54.714H22.668a1.623,1.623,0,0,0,1.451-.9l4.867-9.735A1.594,1.594,0,0,0,27.535,41.735Z'
                transform='translate(0 -32)'
                fill='#fff'
              />
            </svg>
          ),
        }
      : '';
  };
  const items = [
    {
      label: 'Dashboard',
      key: 'dashboard',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 26 28.167'
        >
          <path
            id='icons8_home'
            d='M30.194,32.167h-6.5a1.805,1.805,0,0,1-1.806-1.806v-6.5a1.444,1.444,0,0,0-1.444-1.444H17.556a1.444,1.444,0,0,0-1.444,1.444v6.5a1.805,1.805,0,0,1-1.806,1.806h-6.5A1.805,1.805,0,0,1,6,30.361V16.576A5.418,5.418,0,0,1,8.065,12.32L18.329,4.232a1.086,1.086,0,0,1,1.341,0L29.936,12.32A5.416,5.416,0,0,1,32,16.575V30.361A1.805,1.805,0,0,1,30.194,32.167Z'
            transform='translate(-6 -4)'
            fill='#fff'
          />
        </svg>
      ),
    },
    {
      label: 'Information Operations',
      key: 'information_operations',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 26 24.7'
        >
          <path
            id='icons8_documents'
            d='M6.925,5A2.929,2.929,0,0,0,4,7.925v18.85A2.929,2.929,0,0,0,6.925,29.7H9.733A4.207,4.207,0,0,1,8.55,26.775V7.925A4.207,4.207,0,0,1,9.733,5Zm5.85,0A2.929,2.929,0,0,0,9.85,7.925v18.85A2.929,2.929,0,0,0,12.775,29.7h14.3A2.929,2.929,0,0,0,30,26.775V14.75H23.175a2.929,2.929,0,0,1-2.925-2.925V5Zm9.425.571v6.254a.976.976,0,0,0,.975.975h6.254ZM16.025,18h7.8a.975.975,0,1,1,0,1.95h-7.8a.975.975,0,1,1,0-1.95Zm0,4.55h5.85a.975.975,0,0,1,0,1.95h-5.85a.975.975,0,1,1,0-1.95Z'
            transform='translate(-4 -5)'
            fill='#fff'
          />
        </svg>
      ),
    },
    {
      label: 'Public Information',
      key: 'public_informations',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 13.855 27'
        >
          <path
            id='icons8_information_2'
            d='M21.25,12.105A3.553,3.553,0,1,0,17.7,8.553,3.557,3.557,0,0,0,21.25,12.105Zm6.039,17.763H24.8V17.434a3.912,3.912,0,0,0-3.908-3.908H15.566a1.066,1.066,0,0,0,0,2.132H17.7V29.868H15.566a1.066,1.066,0,0,0,0,2.132H27.289a1.066,1.066,0,0,0,0-2.132Z'
            transform='translate(-14.5 -5)'
            fill='#fff'
          />
        </svg>
      ),
    },
    {
      label: 'Community Affairs',
      key: 'community_affairs',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 27.625 25.168'
        >
          <path
            id='Path_5'
            data-name='Path 5'
            d='M-2018.267-711.979a3.755,3.755,0,0,1,.032-3.771c.44-.5,1.037-.565,5.059-.565,3.8,0,4.65.094,5.153.534.722.628.754,2.828.063,3.8-.911,1.32-3.032,1.98-5.153,1.98S-2017.356-710.659-2018.267-711.979Zm11.689.063.063-1.037c.125-2.011,0-2.482-.943-3.425a5.652,5.652,0,0,1-.943-1.068,36.66,36.66,0,0,1,4.178-.126c2.828,0,4.305.126,4.557.378.534.534.471,2.828-.126,3.676-.754,1.069-2.325,1.6-4.681,1.6Zm-15.176,0c-2.357,0-3.928-.534-4.682-1.6-.6-.848-.66-3.142-.125-3.676.251-.251,1.728-.378,4.556-.378a36.668,36.668,0,0,1,4.179.126,4.609,4.609,0,0,1-.943,1.068c-.943.943-1.069,1.414-.943,3.425l.062,1.037Zm5.938-7.353a2.847,2.847,0,0,1-1.068-2.7,2.848,2.848,0,0,1,1.068-2.7,2.848,2.848,0,0,1,2.7-1.068,2.847,2.847,0,0,1,2.7,1.068,2.849,2.849,0,0,1,1.069,2.7,2.848,2.848,0,0,1-1.069,2.7,2.848,2.848,0,0,1-2.7,1.069A2.849,2.849,0,0,1-2015.816-719.268Zm9.175-1a3.536,3.536,0,0,1,0-4.651,2.666,2.666,0,0,1,2.325-.817,2.666,2.666,0,0,1,2.325.817,3.536,3.536,0,0,1,0,4.651,3.341,3.341,0,0,1-2.325.872A3.341,3.341,0,0,1-2006.641-720.273Zm-17.6,0a2.666,2.666,0,0,1-.817-2.326,2.666,2.666,0,0,1,.817-2.325,2.666,2.666,0,0,1,2.325-.817,2.666,2.666,0,0,1,2.325.817,3.536,3.536,0,0,1,0,4.651,3.341,3.341,0,0,1-2.325.872A3.341,3.341,0,0,1-2024.237-720.273Zm14.107-6.41a2.544,2.544,0,0,0-2.325-.943c-2.2,0-2.545-.534-2.545-3.708,0-2.042.125-2.828.534-3.268.44-.5,1.068-.565,6-.565,6.818,0,6.661-.094,6.661,3.707,0,3.3-.346,3.834-2.514,3.834a2.57,2.57,0,0,0-2.356.943,2.821,2.821,0,0,1-1.728.943A2.821,2.821,0,0,1-2010.129-726.683Z'
            transform='translate(2026.927 735.167)'
            fill='#fff'
          />
        </svg>
      ),
    },
    {
      label: 'Salaam Police',
      key: 'salaam_polices',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 29.477 32.951'
        >
          <path
            id='Path_6'
            data-name='Path 6'
            d='M20.6-91.528a18.769,18.769,0,0,0-3,1.986,50.4,50.4,0,0,1-5.32,3.522c-4.72,2.7-5.207,4.571-1.873,6.931,1.723,1.236,1.986,2.7.749,4.271a4.194,4.194,0,0,0-.749,2.548,2.6,2.6,0,0,0,1.161,2.585,5.087,5.087,0,0,0,1.5.974c.187,0,.337.3.337.637,0,2.248,3.484,7.081,6.032,8.392a9.78,9.78,0,0,0,7.418,0c2.548-1.311,6.032-6.144,6.032-8.392,0-.337.15-.637.337-.637a5.087,5.087,0,0,0,1.5-.974,2.6,2.6,0,0,0,1.161-2.585,4.194,4.194,0,0,0-.749-2.548c-1.236-1.573-1.012-3.035.749-4.271,1.573-1.124,2.023-1.986,1.8-3.709-.075-.862-.6-1.349-3.035-2.772a45.73,45.73,0,0,1-4.72-3.259c-2.96-2.548-4.121-3.109-6.406-3.072A9.354,9.354,0,0,0,20.6-91.528ZM32.142-76.955c0,.712-.262.749-8.991.749s-8.991-.037-8.991-.749.262-.749,8.991-.749S32.142-77.667,32.142-76.955ZM18.018-72.309c3.746,1.274,10.34.6,13.037-1.311.937-.674,1.012-.674,1.611,0,.937,1.012.749,1.911-.524,2.4-.9.337-1.236.787-1.686,2.36a13.181,13.181,0,0,1-4.271,6.406,5.721,5.721,0,0,1-6.069,0,13.181,13.181,0,0,1-4.271-6.406c-.45-1.573-.787-2.023-1.686-2.36-1.274-.487-1.461-1.386-.524-2.4.6-.674.674-.674,1.611,0A13.783,13.783,0,0,0,18.018-72.309Z'
            transform='translate(-8.264 91.905)'
            fill='#fff'
          />
        </svg>
      ),
    },
    {
      label: 'Family, Juvenile, Gender & Development',
      key: 'developments',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 29.761 27.666'
        >
          <path
            id='Path_7'
            data-name='Path 7'
            d='M-1997.875-847.133c-.6-.335-.67-.7-.67-3.852,0-3.282-.033-3.516-.669-3.516s-.67.234-.67,3.516c0,3.149-.067,3.517-.67,3.852a1.215,1.215,0,0,1-1.339,0c-.6-.335-.67-.7-.67-3.852,0-3.115-.067-3.516-.569-3.516a2.776,2.776,0,0,1-1.407-.837,2.826,2.826,0,0,0-1.072-.838,15.939,15.939,0,0,0-.3,4.354c0,3.986-.067,4.354-.67,4.689a1.215,1.215,0,0,1-1.339,0c-.57-.3-.67-.7-.67-2.847,0-2.277-.066-2.512-.669-2.512s-.67.235-.67,2.512c0,2.144-.1,2.546-.67,2.847a1.215,1.215,0,0,1-1.339,0c-.6-.335-.67-.7-.67-4.89v-4.555l-1.139.7a4.243,4.243,0,0,1-1.674.7c-.469,0-.536.5-.536,3.852,0,3.483-.067,3.852-.67,4.187a1.215,1.215,0,0,1-1.339,0c-.6-.335-.67-.7-.67-4.521,0-3.953-.033-4.187-.67-4.187s-.669.235-.669,4.187c0,3.818-.067,4.187-.67,4.521a1.217,1.217,0,0,1-1.34,0c-.6-.335-.669-.7-.669-4.354s-.067-4.019-.67-4.354c-.637-.335-.67-.7-.67-5.057v-4.723l1.139-1.138c1.071-1.072,1.239-1.139,3.55-1.139s2.479.067,3.551,1.139c1.139,1.138,1.139,1.138,1.139,4.354a9.318,9.318,0,0,0,.267,3.216,10.714,10.714,0,0,0,1.742-1,4.971,4.971,0,0,1,3.35-1,5.183,5.183,0,0,1,3.55,1.138l1.708,1.172.369-2.68a11.352,11.352,0,0,1,.9-3.417,4.454,4.454,0,0,1,7.034-.033c.57.9,1.608,7.235,1.441,9.077-.067,1.039-.235,1.307-.838,1.373-.736.1-.771.235-.771,3.584,0,3.149-.066,3.517-.669,3.852a1.337,1.337,0,0,1-.67.2A1.339,1.339,0,0,1-1997.875-847.133Zm-13.4-16.077a2.741,2.741,0,0,1-.669-2.009,2.741,2.741,0,0,1,.669-2.009,2.741,2.741,0,0,1,2.01-.67,2.738,2.738,0,0,1,2.009.67,2.74,2.74,0,0,1,.67,2.009,2.74,2.74,0,0,1-.67,2.009,2.738,2.738,0,0,1-2.009.67A2.741,2.741,0,0,1-2011.273-863.21Zm9.379-7.368a2.741,2.741,0,0,1,.669-2.01c1.106-1.105,3.316-.9,5.092.435.8.6,1.44,1.306,1.44,1.574s-.636.971-1.44,1.574a4.133,4.133,0,0,1-2.948,1.105C-2001.024-867.9-2001.894-868.736-2001.894-870.579Zm-20.1-1.339a2.745,2.745,0,0,1,.67-2.01,2.743,2.743,0,0,1,2.01-.669,2.744,2.744,0,0,1,2.01.669,2.745,2.745,0,0,1,.669,2.01,2.741,2.741,0,0,1-.669,2.009,2.745,2.745,0,0,1-2.01.67A2.378,2.378,0,0,1-2021.991-871.918Z'
            transform='translate(2024 874.598)'
            fill='#fff'
          />
        </svg>
      ),
    },
    {
      label: 'Plans and Programs',
      key: 'plans_programs',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 30.639 30.761'
        >
          <path
            id='Path_8'
            data-name='Path 8'
            d='M-2013.447-915.751c-2.341-1.192-2.553-2.255-2.553-11.915v-8.724h30.639v8.724c0,9.659-.214,10.723-2.553,11.915-.915.468-6.841.7-12.766.7S-2012.532-915.282-2013.447-915.751Zm12.766-23.192H-2016v-1.489a5.2,5.2,0,0,1,2.468-4.638c1.7-.979,23.83-.979,25.618-.043a5.249,5.249,0,0,1,2.553,4.682v1.489Z'
            transform='translate(2016 945.809)'
            fill='#fff'
          />
        </svg>
      ),
    },
    {
      label: 'Admin',
      key: 'admins',
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 28.767 35.3'
        >
          <path
            id='Path_9'
            data-name='Path 9'
            d='M22.47-90.769c-1.2.721-2.923,1.8-3.8,2.363a21.264,21.264,0,0,1-3.724,1.762,4.787,4.787,0,0,0-2.483,1.482c-.28.521-.36,2.683-.24,6.047.16,4.325.36,5.766,1.241,8.329,1.882,5.326,5.406,10.371,8.89,12.694a6.027,6.027,0,0,0,4.2,1.241,5.828,5.828,0,0,0,4.2-1.321c4-2.723,8.129-9.29,9.531-15.137.721-2.923.881-10.772.24-11.933a5.524,5.524,0,0,0-2.483-1.442,24.349,24.349,0,0,1-4.805-2.4C27.716-92.691,26.074-92.932,22.47-90.769Zm8.089,3.564a49.636,49.636,0,0,0,5.566,2.963l2.2.921-.08,3.123a40.583,40.583,0,0,1-.32,4.4l-.24,1.321H26.515v7.408c0,8.369-.04,8.409-2.883,6.247-3.083-2.323-5.766-6.407-7.568-11.453l-.681-2,5.566-.12,5.566-.12v-7.368a45.309,45.309,0,0,1,.32-7.408A25.1,25.1,0,0,1,30.559-87.205Z'
            transform='translate(-12.165 92.151)'
            fill='#fff'
          />
        </svg>
      ),
    },
    AdminSupervisorAccess(),
    summary(),
    userAccount(),
    archiveAccess(),
    frequencyReport(),
    AdminCMS(),
  ];
  const onClick = (e) => {
    console.log('click ', e.key);

    if (e.key === 'dashboard') {
      localStorage.setItem('active_tab', 'dashboard');
      localStorage.setItem('default_key', '1');
      his.push('/');
    }
    if (e.key === 'information_operations') {
      his.push('/information-operations');
      localStorage.setItem('active_tab', 'information_operations');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'public_informations') {
      console.log('check');
      his.push('/public-information');
      localStorage.setItem('active_tab', 'public_informations');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'community_affairs') {
      his.push('/community-affairs');
      localStorage.setItem('active_tab', 'community_affairs');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'salaam_polices') {
      his.push('/salaam-police');
      localStorage.setItem('active_tab', 'salaam_polices');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'developments') {
      his.push('/development');
      localStorage.setItem('active_tab', 'developments');
      localStorage.setItem('default_key', '1');
    }

    if (e.key === 'plans_programs') {
      his.push('/plan-program');
      localStorage.setItem('active_tab', 'plans_programs');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'admins') {
      his.push('/admin');
      localStorage.setItem('active_tab', 'admins');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'archive') {
      his.push('/archive');
      localStorage.setItem('active_tab', 'archive');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'announcement') {
      his.push('/announcement');
      localStorage.setItem('active_tab', 'announcement');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'summary') {
      his.push('/summary');
      localStorage.setItem('active_tab', 'summary');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'user_accounts') {
      his.push('/user-accounts');
      localStorage.setItem('active_tab', 'user_accounts');
      localStorage.setItem('default_key', '1');
    }
    if (e.key === 'frequency_report') {
      his.push('/frequency-report');
      localStorage.setItem('active_tab', 'frequency_report');
      localStorage.setItem('default_key', '2');
    }
    if (e.key === 'activity_log') {
      his.push('/activity-log');
      localStorage.setItem('active_tab', 'activity_log');
      localStorage.setItem('default_key', '2');
    }
    if (e.key === 'reference_table') {
      his.push('/reference-table-maintenance');
      localStorage.setItem('active_tab', 'reference_table');
      localStorage.setItem('default_key', '2');
    }
    if (e.key === 'privacy_policy') {
      his.push('/privacy-policy');
      localStorage.setItem('active_tab', 'privacy_policy');
      localStorage.setItem('default_key', '2');
    }
  };

  return (
    <>
      {collapse ? (
        ''
      ) : (
        <div>
          <div className='z-50 absolute p-5 text-center text-white'>
            <p style={{ fontSize: '16px', lineHeight: '120%' }}>
              Police Community Relations Management Information System (PCRMIS)
            </p>
            <div className='flex flex-col items-center mt-4'>
              <Avatar
                src={
                  showProfile?.file ? showProfile?.file : './images/pnplogo.png'
                }
                size={120}
              />
              <p className='mt-4 font-bold text-lg'>
                {showProfile && showProfile.full_name}
              </p>
              <p className=''>{showProfile && showProfile.rank.name}</p>
              <p className='text-capitalize'>
                {showProfile && showProfile?.role.replace('_', ' ')}
                {user && user.role === 'supervisor'
                  ? ' (' + user.unit.name + ')'
                  : ''}
              </p>
            </div>
          </div>
          <div
            className='sidebar-banner'
            style={{ height: '360px', opacity: '90%' }}
          >
            &nbsp;
          </div>
        </div>
      )}
      <div
        className='text-blue-10 pt-3 pb-3'
        style={{ height: '100vh' }}
      >
        <Menu
          onClick={onClick}
          theme='dark'
          mode='inline'
          defaultOpenKeys={active_key === '2' ? ['2'] : ['1']}
          defaultSelectedKeys={active_tab}
          items={items}
        >
          {/* <Menu.Item
            key='1'
            icon={<HomeOutlined />}
          >
            <Link to='/'>
              <p>Dashboard</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='2'
            icon={<ProfileOutlined />}
            aria-label='Close'
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'information_operations');
              }}
              to='/information-operations'
            >
              <p>Information Operations</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='3'
            icon={<InfoOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'public_informations');
              }}
              to='/public-information'
            >
              <p>Public Information</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='4'
            icon={<UsergroupAddOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'community_affairs');
              }}
              to='/community-affairs'
            >
              <p>Community Affairs</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='5'
            icon={<UserOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'salaam_polices');
              }}
              to='/salaam-police'
            >
              <p>Salaam Police</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='6'
            icon={<UsergroupDeleteOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'developments');
              }}
              to='/development'
            >
              <p className='text-sm'>Family, Juvenile, Gender & Development</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='7'
            icon={<BorderOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'plans_programs');
              }}
              to='/plan-program'
            >
              <p>Plans and Programs</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='12'
            icon={<FolderOpenOutlined />}
          >
            <Link to='/archive'>
              <p>Archive</p>
            </Link>
          </Menu.Item> */}
          {/* <Menu.Item
            key='8'
            icon={<SafetyOutlined />}
          >
            <Link to='/admin'>
              <p>Admin</p>
            </Link>
          </Menu.Item> */}

          {/* {user && (user.role === 'admin' || user.role === 'supervisor') ? (
            <>
              <Menu.Item
                key='9'
                icon={<NotificationOutlined />}
              >
                <Link to='/announcement'>
                  <p>Announcements</p>
                </Link>
              </Menu.Item>
            </>
          ) : (
            ''
          )}
          {user && user.role === 'admin' ? (
            <>
              <Menu.Item
                key='10'
                icon={<FileTextOutlined />}
              >
                <Link to='/summary'>
                  <p>Summary</p>
                </Link>
              </Menu.Item>
              <Menu.Item
                key='11'
                icon={<UsergroupAddOutlined />}
              >
                <Link to='/user-accounts'>
                  <p>User Accounts</p>
                </Link>
              </Menu.Item>
            </>
          ) : (
            ''
          )} */}
        </Menu>
        {/* <div
          style={{
            fontSize: '12px',
            position: 'absolute',
            bottom: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            minWidth: '280px',
          }}
        >
          <p className='text-white text-center mt-5 mb-4 text-wrap'>
            © 2022 Directorate for Police Community Relations <br /> All Rights
            Reserved.
          </p>
        </div> */}
      </div>
    </>
  );
};

export default SideBar;
