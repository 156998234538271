import React from 'react';
import { Form, Input, Checkbox, Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormWrapper from '../../../shared/components/FormWrapper';

import { forgotPassword } from '../action';
import {
  ImageBackground,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/react-native/UI/js';

const FormForgotPassword = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (value) => {
    dispatch(forgotPassword(value, callback));
  };

  const callback = (response, error) => {
    let res = response.message;
    if (res === 'We have e-mailed your password reset link!') {
      message.success(res);
    }

    if (res === 'Login successfully') {
      onResetField();
      message.success('Login successfully');
    }
  };

  const onResetField = () => {
    form.setFieldsValue({
      email: '',
      Password: '',
    });
  };

  return (
    <FormWrapper>
      <div className='pt-4 pr-3 pb-4 pl-3'>
        <Form
          className='mt-0 mr-4 mb-0 ml-4'
          form={form}
          onFinish={onFinish}
        >
          <p
            className='text-black font-bold mb-2'
            style={{ fontSize: '1.25rem' }}
          >
            Forgot Password
          </p>
          <p className='mb-3'>
            Enter your Email Address and we will send you a link to reset your
            password.
          </p>
          <div>
            <span className='text-gray-20 font-bold'>Email</span>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Invalid email format!' },
              ]}
            >
              <Input
                style={{ marginTop: 10 }}
                placeholder='Enter Email Here'
                className='h-14'
              />
            </Form.Item>
          </div>
        </Form>
        <div className='w-100 d-flex justify-content-center '>
          <button
            className='btn btn-lg  w-100 mt-2 text-white mr-4 mb-0 ml-4'
            style={{ backgroundColor: '#015e98' }}
            onClick={() => {
              form.submit();
            }}
          >
            Send Link
          </button>
        </div>
      </div>
    </FormWrapper>
  );
};
const styles = StyleSheet.create({
  blue_btn: {
    height: 55,
    width: '100%',
    backgroundColor: '#001529',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -100,
  },
  btn_text: {
    fontSize: 22,
    fontWeight: 'bold',
    color: 'white',
  },
});

export default FormForgotPassword;
