import { getAuthHeader, header, getError } from '../../shared/utils';
import {
  API_ADD_COMMUNITY_AFFAIR,
  API_EDIT_COMMUNITY_AFFAIR,
  API_DELETE_COMMUNITY_AFFAIR,
  API_UPDATE_COMMUNITY_AFFAIR_STATUS,
} from '../../shared/constant/url';

export const COMMUNITY = {
  ADD_COMMUNITY_AFFAIR_START: 'ADD_COMMUNITY_AFFAIR_START',
  ADD_COMMUNITY_AFFAIR_SUCCESS: 'ADD_COMMUNITY_AFFAIR_SUCCESS',
  ADD_COMMUNITY_AFFAIR_ERROR: 'ADD_COMMUNITY_AFFAIR_ERROR',

  EDIT_COMMUNITY_AFFAIR_START: 'EDIT_COMMUNITY_AFFAIR_START',
  EDIT_COMMUNITY_AFFAIR_SUCCESS: 'EDIT_COMMUNITY_AFFAIR_SUCCESS',
  EDIT_COMMUNITY_AFFAIR_ERROR: 'EDIT_COMMUNITY_AFFAIR_ERROR',

  DELETE_COMMUNITY_AFFAIR_START: 'DELETE_COMMUNITY_AFFAIR_START',
  DELETE_COMMUNITY_AFFAIR_SUCCESS: 'DELETE_COMMUNITY_AFFAIR_SUCCESS',
  DELETE_COMMUNITY_AFFAIR_ERROR: 'DELETE_COMMUNITY_AFFAIR_ERROR',

  UPDATE_COMMUNITY_AFFAIR_START: 'UPDATE_COMMUNITY_AFFAIR_START',
  UPDATE_COMMUNITY_AFFAIR_SUCCESS: 'UPDATE_COMMUNITY_AFFAIR_SUCCESS',
  UPDATE_COMMUNITY_AFFAIR_ERROR: 'UPDATE_COMMUNITY_AFFAIR_ERROR',
};
export const ArchiveAll = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: COMMUNITY.ADD_COMMUNITY_AFFAIR_START });
    let formData = new FormData();

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
    };
    fetch(
      `${API_ADD_COMMUNITY_AFFAIR}/archive?report_type=${
        params.report_type ? params.report_type : ''
      }&start_date=${params.start_date ? params.start_date : ''}&end_date=${
        params.end_date ? params.end_date : ''
      }`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: COMMUNITY.ADD_COMMUNITY_AFFAIR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: COMMUNITY.ADD_COMMUNITY_AFFAIR_ERROR,
          payload: getError(error),
        });
      });
  };
};
export const addCommunityAffair = (params, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: COMMUNITY.ADD_COMMUNITY_AFFAIR_START });
    let formData = new FormData();
    formData.append('subject', params.subject);
    formData.append('report_type', params.category);
    formData.append('report_data', params.report_data);
    formData.append('date_submitted', params.date_submitted);

    if (params.task_id) {
      formData.append('task_id', params.task_id);
    }

    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }

    formData.append('description', params.description);
    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };

    fetch(API_ADD_COMMUNITY_AFFAIR, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: COMMUNITY.ADD_COMMUNITY_AFFAIR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: COMMUNITY.ADD_COMMUNITY_AFFAIR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editCommunityAffair = (params, file, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: COMMUNITY.EDIT_COMMUNITY_AFFAIR_START });
    let formData = new FormData();

    if (params.category) {
      formData.append('report_type', params.category);
    }
    if (params.date_submitted) {
      formData.append('date_submitted', params.date_submitted);
    }
    if (params.subject) {
      formData.append('subject', params.subject);
    }
    if (params.description) {
      formData.append('description', params.description);
    }

    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }

    formData.append('_method', 'PUT');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_EDIT_COMMUNITY_AFFAIR}${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: COMMUNITY.EDIT_COMMUNITY_AFFAIR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: COMMUNITY.EDIT_COMMUNITY_AFFAIR_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteCommunityAffair = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: COMMUNITY.DELETE_COMMUNITY_AFFAIR_START });
    let requestOption = {
      method: 'DELETE',
      headers: getAuthHeader(),
    };
    fetch(`${API_DELETE_COMMUNITY_AFFAIR}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: COMMUNITY.DELETE_COMMUNITY_AFFAIR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({
          type: COMMUNITY.DELETE_COMMUNITY_AFFAIR_ERROR,
          payload: error,
        });
      });
  };
};

export const updateCommunityAffairStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: COMMUNITY.UPDATE_COMMUNITY_AFFAIR_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: 'PATCH',
      body: data,
      headers: header(),
    };
    fetch(`${API_UPDATE_COMMUNITY_AFFAIR_STATUS}${id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: COMMUNITY.UPDATE_COMMUNITY_AFFAIR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({
          type: COMMUNITY.UPDATE_COMMUNITY_AFFAIR_ERROR,
          payload: error,
        });
      });
  };
};
