import { getAuthHeader, header, getError } from '../../shared/utils';
import {
  API_ADD_PUBLIC_INFORMATION,
  API_EDIT_PUBLIC_INFORMATION,
  API_DELETE_PUBLIC_INFORMATION,
  API_UPDATE_PUBLIC_INFORMATION_STATUS,
} from '../../shared/constant/url';

export const PUBLICINFO = {
  ADD_PUBLIC_INFO_START: 'ADD_PUBLIC_INFO_START',
  ADD_PUBLIC_INFO_SUCCESS: 'ADD_PUBLIC_INFO_SUCCESS',
  ADD_PUBLIC_INFO_ERROR: 'ADD_PUBLIC_INFO_ERROR',

  EDIT_PUBLIC_INFO_START: 'EDIT_PUBLIC_INFO_START',
  EDIT_PUBLIC_INFO_SUCCESS: 'EDIT_PUBLIC_INFO_SUCCESS',
  EDIT_PUBLIC_INFO_ERROR: 'EDIT_PUBLIC_INFO_ERROR',

  DELETE_PUBLIC_INFO_START: 'DELETE_PUBLIC_INFO_START',
  DELETE_PUBLIC_INFO_SUCCESS: 'DELETE_PUBLIC_INFO_SUCCESS',
  DELETE_PUBLIC_INFO_ERROR: 'DELETE_PUBLIC_INFO_ERROR',

  UPDATE_PUBLIC_INFO_START: 'UPDATE_PUBLIC_INFO_START',
  UPDATE_PUBLIC_INFO_SUCCESS: 'UPDATE_PUBLIC_INFO_SUCCESS',
  UPDATE_PUBLIC_INFO_ERROR: 'UPDATE_PUBLIC_INFO_ERROR',
};

export const addNewPubliInfo = (params, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PUBLICINFO.ADD_PUBLIC_INFO_START });
    let formData = new FormData();
    formData.append('report_type', params.category);
    formData.append('date_submitted', params.date_submitted);
    formData.append('subject', params.subject);
    if (params.task_id) {
      formData.append('task_id', params.task_id);
    }
    for (let i = 0; i < file.length; i++) {
      formData.append(`file[${i}]`, file[i]);
    }
    formData.append('description', params.description);

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_PUBLIC_INFORMATION, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        callback(response, null);
        dispatch({
          type: PUBLICINFO.ADD_PUBLIC_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PUBLICINFO.ADD_PUBLIC_INFO_ERROR,
          payload: getError(error),
        });
      });
  };
};
export const ArchiveAll = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PUBLICINFO.ADD_PUBLIC_INFO_START });
    let formData = new FormData();

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
    };
    fetch(
      `${API_ADD_PUBLIC_INFORMATION}/archive?report_type=${
        params.report_type ? params.report_type : ''
      }&start_date=${params.start_date ? params.start_date : ''}&end_date=${
        params.end_date ? params.end_date : ''
      }`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PUBLICINFO.ADD_PUBLIC_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PUBLICINFO.ADD_PUBLIC_INFO_ERROR,
          payload: getError(error),
        });
      });
  };
};
export const editPublicInfoRecord = (params, file, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PUBLICINFO.EDIT_PUBLIC_INFO_START });
    let formData = new FormData();

    if (params.category) {
      formData.append('report_type', params.category);
    }
    if (params.date_submitted) {
      formData.append('date_submitted', params.date_submitted);
    }
    if (params.subject) {
      formData.append('subject', params.subject);
    }
    if (params.description) {
      formData.append('description', params.description);
    }
    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }

    formData.append('_method', 'PUT');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };

    fetch(`${API_EDIT_PUBLIC_INFORMATION}${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: PUBLICINFO.EDIT_PUBLIC_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: PUBLICINFO.EDIT_PUBLIC_INFO_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deletePublicInfo = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PUBLICINFO.DELETE_PUBLIC_INFO_START });
    let requestOption = {
      method: 'DELETE',
      headers: getAuthHeader(),
    };
    fetch(`${API_DELETE_PUBLIC_INFORMATION}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: PUBLICINFO.DELETE_PUBLIC_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: PUBLICINFO.DELETE_PUBLIC_INFO_ERROR, payload: error });
      });
  };
};

export const updatePublicInfoStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: PUBLICINFO.UPDATE_PUBLIC_INFO_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: 'PATCH',
      body: data,
      headers: header(),
    };
    fetch(`${API_UPDATE_PUBLIC_INFORMATION_STATUS}${id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: PUBLICINFO.UPDATE_PUBLIC_INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: PUBLICINFO.UPDATE_PUBLIC_INFO_ERROR, payload: error });
      });
  };
};
