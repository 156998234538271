import { getAuthHeader, header, getError } from '../../shared/utils';
import {
  API_ADD_ADMIN,
  API_EDIT_ADMIN,
  API_DELETE_ADMIN,
  API_UPDATE_ADMIN_STATUS,
} from '../../shared/constant/url';

export const ADMIN = {
  ADD_ADMIN_START: 'ADD_ADMIN_START',
  ADD_ADMIN_SUCCESS: 'ADD_ADMIN_SUCCESS',
  ADD_ADMIN_ERROR: 'ADD_ADMIN_ERROR',

  EDIT_ADMIN_START: 'EDIT_ADMIN_START',
  EDIT_ADMIN_SUCCESS: 'EDIT_ADMIN_SUCCESS',
  EDIT_ADMIN_ERROR: 'EDIT_ADMIN_ERROR',

  DELETE_ADMIN_START: 'DELETE_ADMIN_START',
  DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_ERROR: 'DELETE_ADMIN_ERROR',

  UPDATE_ADMIN_START: 'UPDATE_ADMIN_START',
  UPDATE_ADMIN_SUCCESS: 'UPDATE_ADMIN_SUCCESS',
  UPDATE_ADMIN_ERROR: 'UPDATE_ADMIN_ERROR',
};

export const addAdmin = (params, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADMIN.ADD_ADMIN_START });
    let formData = new FormData();
    formData.append('report_type', params.category);
    formData.append('date_submitted', params.date_submitted);
    formData.append('subject', params.subject);
    if (params.task_id) {
      formData.append('task_id', params.task_id);
    }
    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }
    formData.append('description', params.description);
    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_ADMIN, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ADMIN.ADD_ADMIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        callback(null, error);
        dispatch({
          type: ADMIN.ADD_ADMIN_ERROR,
          payload: getError(error),
        });
      });
  };
};
export const ArchiveAll = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADMIN.ADD_ADMIN_START });
    let formData = new FormData();

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
    };
    fetch(
      `${API_ADD_ADMIN}/archive?report_type=${
        params.report_type ? params.report_type : ''
      }&start_date=${params.start_date ? params.start_date : ''}&end_date=${
        params.end_date ? params.end_date : ''
      }`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ADMIN.ADD_ADMIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ADMIN.ADD_ADMIN_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editAdmin = (params, file, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADMIN.EDIT_ADMIN_START });
    let formData = new FormData();

    if (params.category) {
      formData.append('report_type', params.category);
    }
    if (params.date_submitted) {
      formData.append('date_submitted', params.date_submitted);
    }
    if (params.subject) {
      formData.append('subject', params.subject);
    }
    if (params.description) {
      formData.append('description', params.description);
    }

    if (file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file[${i}]`, file[i]);
      }
    }

    formData.append('_method', 'PUT');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_EDIT_ADMIN}${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ADMIN.EDIT_ADMIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ADMIN.EDIT_ADMIN_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteAdmin = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADMIN.DELETE_ADMIN_START });
    let requestOption = {
      method: 'DELETE',
      headers: getAuthHeader(),
    };
    fetch(`${API_DELETE_ADMIN}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: ADMIN.DELETE_ADMIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: ADMIN.DELETE_ADMIN_ERROR, payload: error });
      });
  };
};

export const updateAdminStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ADMIN.UPDATE_ADMIN_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: 'PATCH',
      body: data,
      headers: header(),
    };
    fetch(`${API_UPDATE_ADMIN_STATUS}${id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: ADMIN.UPDATE_ADMIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: ADMIN.UPDATE_ADMIN_ERROR, payload: error });
      });
  };
};
