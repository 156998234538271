import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import FormAddEditRecord from '../../general/FormAddEditRecord';
import { showPublicInfo } from '../../general/action';
import { addNewPubliInfo, editPublicInfoRecord } from '../action';

const Landing = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(showPublicInfo(id, callback));
        }
    }, []);

    const callback = () => {

    };

    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">PICE CONDUCTED</p>
                    <p className="md-title text-gray-20">Add new report here</p>
                </div>
                <div className="mt-2">
                    <FormAddEditRecord
                        editPublicInfoRecord={editPublicInfoRecord}
                        addNewPubliInfo={addNewPubliInfo}
                    />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
