import { DASHBOARD } from './action';

const INITIAL_STATE = {
    loading: false,
    error: '',
    isLoggedIn: false,
    listPendingReport: [],
    listRecentReport: []
};


const dashboard = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case DASHBOARD.LIST_PENDING_REPORT_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case DASHBOARD.LIST_PENDING_REPORT_SUCCESS:
            return { ...state, loading: false, listPendingReport: action.payload, isLoggedIn: true }
        case DASHBOARD.LIST_PENDING_REPORT_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        case DASHBOARD.LIST_RECENT_REPORT_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case DASHBOARD.LIST_RECENT_REPORT_SUCCESS:
            return { ...state, loading: false, listRecentReport: action.payload, isLoggedIn: true }
        case DASHBOARD.LIST_RECENT_REPORT_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        default:
            return state;
    }
}

export default dashboard;


