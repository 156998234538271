import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Select, Button, Input, message } from 'antd';

import FormWrapper from '../../../shared/components/FormWrapper';
import {
  getInfoOperationList,
  getPublicInfo,
  getCommunityAffairList,
  getSalaamPoliceList,
  getDevelopmentList,
  getPlanProgramList,
  getAdminList,
  getCategoriesReport,
  getReportType,
  getArchiveList,
} from '../../general/action';
import UI from '../../../shared/react-native/UI/js';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import './FormSearch.scss';
import Moment from 'moment';
import { logDOM } from '@testing-library/react';
import { Callbacks } from 'jquery';
import { ArchiveAll } from '../action';
import { useRef } from 'react';
import { TreeSelect } from 'antd';
import { treeData, useDateRange } from '../../../shared/hooks/useDateRange';
import { BiReset } from 'react-icons/bi';
const { Option } = Select;
const FormSearch = (props) => {
  const {
    reportType,
    dataRangePicker,
    categories,
    categoryID,
    setCategories,
    setCategoryID,
    setReportTypeID,
    reportTypeID,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { pathname } = history.location;
  const user = JSON.parse(localStorage.getItem('user'));
  const [form] = Form.useForm();
  const [filterValue, setFilterValue] = useState(null);
  const [unitValue, setUnitValue] = useState(null);
  const [search, setSearch] = useState('');
  const [allUnits, setAllUnits] = useState();
  const [isshowDate, setisshowDate] = useState(true);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [hotReloadStart, setHotReloadStart] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setisshowDate(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    dispatch(getCategoriesReport(Callback));
  }, []);
  useEffect(() => {
    const name = categoryID;
    dispatch(getReportType(name));
  }, [categoryID]);
  useEffect(() => {
    if (props) {
      const { listUnit } = props;
      setAllUnits(listUnit);

      // console.log(props);
    }
  }, [allUnits, props]);

  useEffect(() => {
    // date range
    if (hotReloadStart) {
      const formatDateStart = Moment(selectionRange[0]?.startDate).format(
        'MM/DD/YYYY',
      );
      const formatDateEnd = Moment(selectionRange[0]?.endDate).format(
        'MM/DD/YYYY',
      );
      const params = {
        formatDateStart,
        formatDateEnd,
        report_type: reportTypeID,
        category: categoryID,
      };

      if (reportTypeID && categoryID) {
        console.log('check');
        dispatch(getArchiveList(params));
      }
    }
  }, [selectionRange]);
  useEffect(() => {
    console.log(categoryID);

    if (hotReloadStart) {
      const identifier = setTimeout(() => {
        const params = {
          search: search,
          category: categoryID,
        };
        dispatch(getArchiveList(params));
      }, 500);

      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);

  const renderReportType = () => {
    return reportType?.map((item) => {
      return (
        <option
          key={item.id}
          value={item.name}
        >
          {item.name}
        </option>
      );
    });
  };

  const onFinish = (value) => {
    let newValue = {};
    console.log('check');
    if (value == 'clear') {
      newValue.search = '';
    } else {
      newValue.search = search;
    }
    console.log(newValue);
    switch (pathname) {
      case '/information-operations':
        return dispatch(getArchiveList(newValue));
        break;
      case '/public-information':
        return dispatch(getPublicInfo(newValue));
        break;
      case '/community-affairs':
        return dispatch(getCommunityAffairList(newValue));
        break;
      case '/salaam-police':
        return dispatch(getSalaamPoliceList(newValue));
        break;
      case '/development':
        return dispatch(getDevelopmentList(newValue));
        break;
      case '/plan-program':
        return dispatch(getPlanProgramList(newValue));
        break;
      case '/admin':
        return dispatch(getAdminList(newValue));
        break;
    }
  };

  const Callback = (response) => {
    setCategories(response.data);
  };

  const onFilterByDate = (e) => {
    if (props) {
      props.onDateChange(e);
    }

    setFilterValue(e);
  };

  const filterByUnit = (e) => {
    if (props) {
      props.onUnitChange(e);
    }

    setUnitValue(e);
  };

  const onFilterByReportType = (e) => {
    if (props) {
      props.onReportTypeChange(e);
    }
  };

  const renderButton = () => {
    return (
      <Link to={'/add-info'}>
        <Button className='bg-blue-10 w-full md:w-auto text-white h-11 md:mr-5 md:mb-0'>
          Add Record&nbsp;<i className='fas fa-plus'></i>
        </Button>
      </Link>
    );
  };
  const ArchiveAllHandler = () => {
    if (!reportTypeID) {
      message.error('Select Report Type');
      return;
    }
    // if (!selectionRange.startDate && !selectionRange.endDate) {
    //   message.error('Select Date Range');
    //   return;
    // }
    const params = {
      category: categoryID,
      report_type: reportTypeID,
      start_date: Moment(selectionRange[0]?.startDate).format('MM/DD/YYYY'),
      end_date: Moment(selectionRange[0]?.endDate).format('MM/DD/YYYY'),
    };
    console.log(params);
    dispatch(ArchiveAll(params, callbackArchive));
  };
  const callbackArchive = (response) => {
    console.log(response);
    message.info(response.message);
    dispatch(getArchiveList());
  };
  const useDateRange = (dispatch, key) => (e) => {
    console.log(e);
    let date = new Date().getFullYear();

    let params = {
      formatDateStart: Moment(`01/01/${date}`).format('MM/DD/YYYY'),
      formatDateEnd: Moment(new Date()).format('MM/DD/YYYY'),
    };
    if (e === 'first_quarter') {
      params = {
        formatDateStart: `01/01/${date}`,
        formatDateEnd: `03/31/${date}`,
      };
    }
    if (e === 'second_quarter') {
      params = {
        formatDateStart: `04/01/${date}`,
        formatDateEnd: `06/30/${date}`,
      };
    }
    if (e === 'third_quarter') {
      params = {
        formatDateStart: `07/01/${date}`,
        formatDateEnd: `09/30/${date}`,
      };
    }
    if (e === 'fourth_quarter') {
      params = {
        formatDateStart: `10/01/${date}`,
        formatDateEnd: `12/31/${date}`,
      };
    }
    if (e === 'annually') {
      params = {
        formatDateStart: `01/01/${date}`,
        formatDateEnd: `12/31/${date}`,
      };
    }
    if (e === 'jan') {
      params = {
        formatDateStart: `01/01/${date}`,
        formatDateEnd: `01/31/${date}`,
      };
    }
    if (e === 'feb') {
      params = {
        formatDateStart: `02/01/${date}`,
        formatDateEnd: `03/01/${date}`,
      };
    }
    if (e === 'mar') {
      params = {
        formatDateStart: `03/01/${date}`,
        formatDateEnd: `03/31/${date}`,
      };
    }
    if (e === 'april') {
      params = {
        formatDateStart: `04/01/${date}`,
        formatDateEnd: `04/30/${date}`,
      };
    }
    if (e === 'may') {
      params = {
        formatDateStart: `05/01/${date}`,
        formatDateEnd: `05/31/${date}`,
      };
    }
    if (e === 'jun') {
      params = {
        formatDateStart: `06/01/${date}`,
        formatDateEnd: `06/30/${date}`,
      };
    }
    if (e === 'jul') {
      params = {
        formatDateStart: `07/01/${date}`,
        formatDateEnd: `07/31/${date}`,
      };
    }
    if (e === 'aug') {
      params = {
        formatDateStart: `08/01/${date}`,
        formatDateEnd: `08/31/${date}`,
      };
    }
    if (e === 'sept') {
      params = {
        formatDateStart: `09/01/${date}`,
        formatDateEnd: `09/30/${date}`,
      };
    }
    if (e === 'oct') {
      params = {
        formatDateStart: `10/01/${date}`,
        formatDateEnd: `10/31/${date}`,
      };
    }
    if (e === 'nov') {
      params = {
        formatDateStart: `11/01/${date}`,
        formatDateEnd: `11/30/${date}`,
      };
    }
    if (e === 'dec') {
      params = {
        formatDateStart: `12/01/${date}`,
        formatDateEnd: `12/31/${date}`,
      };
    }

    if (reportTypeID && categoryID) {
      console.log('check');
      dispatch(
        getArchiveList({
          ...params,
          report_type: reportTypeID,
          category: categoryID,
        }),
      );
    }
  };
  return (
    <FormWrapper>
      <Form
        form={form}
        onFinish={onFinish}
        style={{ overflow: 'scroll' }}
      >
        <div className='formsearch-wrapper'>
          <div className='formsearch-wrapper__filter-1'>
            {' '}
            <Form.Item>
              <Select
                value={categoryID}
                defaultValue={''}
                style={{
                  width: 180,
                }}
                onChange={(e) => setCategoryID(e)}
              >
                <Option
                  hidden
                  value=''
                >
                  Select Category Type
                </Option>
                {categories.map((data) => {
                  return <Option value={data.id}>{data.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Select
                value={reportTypeID}
                defaultValue=''
                style={{
                  width: 180,
                }}
                onChange={(e) => setReportTypeID(e)}
              >
                <Option
                  hidden
                  value=''
                >
                  Select Report Type
                </Option>
                {reportType?.map((data) => {
                  return <Option value={data.id}>{data.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <div className='d-flex h-11'>
              <TreeSelect
                size='middle'
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={treeData}
                placeholder='Please select'
                onChange={useDateRange(dispatch, 'public_information')}
              />

              <Button
                className='bg-blue-10 mx-2 text-white h-11'
                onClick={() => {
                  setCategoryID('');
                  setReportTypeID('');
                  const name = 'information_operations';
                  const params = { category: name };
                  dispatch(getArchiveList(params));
                }}
              >
                <BiReset size={18} />
              </Button>
            </div>
            <div className='d-flex '>
              <Form.Item className='custom-date-label'>
                <p className='absolute z-50  flex   bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md'>
                  Filter by:
                </p>
                <Button
                  className='buttonDate '
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #C5C5C5',
                    height: '44px',
                    borderLeft: 'none',
                    minWidth: '190px',
                  }}
                  placeholder='Date'
                  onClick={() => {
                    return setisshowDate((prev) => {
                      if (!hotReloadStart) {
                        setHotReloadStart(true);
                      }
                      if (prev) {
                        setisshowDate(false);
                      } else {
                        setisshowDate(true);
                      }
                    });
                  }}
                >
                  Date
                </Button>
              </Form.Item>
              <Button
                onClick={() => {
                  setCategoryID('');
                  setReportTypeID('');
                  const name = 'information_operations';
                  const params = { category: name };
                  dispatch(getArchiveList(params));
                }}
                className='bg-blue-10 w-full mx-2  text-white h-11 '
              >
                Reset Filter
              </Button>
            </div>
          </div>
          <div className='formsearch-wrapper__filter-2'>
            <Form.Item
              name='search'
              className='md:w-60 md:mr-5'
            >
              <Input
                onChange={(e) => {
                  if (!hotReloadStart) {
                    setHotReloadStart(true);
                  }
                  if (!categoryID) {
                    message.error('Please select a category!');
                    setSearch('');
                    return;
                  }
                  setSearch(e.target.value);
                }}
                value={search}
                placeholder='Search here'
                style={{ maxHeight: '44px' }}
              />
              <i className='fas fa-search absolute text-gray-20 -ml-8 mt-2 text-lg p-0'></i>
            </Form.Item>
            <Button
              onClick={ArchiveAllHandler}
              className='bg-blue-10 w-full md:w-auto text-white h-11 md:mr-5 mb-0'
            >
              Restore All
            </Button>
          </div>
        </div>
      </Form>
      <div
        className='container'
        style={{ display: isshowDate ? 'none' : 'block' }}
        ref={ref}
      >
        <DateRangePicker
          editableDateInputs={true}
          onChange={(item) => setSelectionRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={selectionRange}
        />
      </div>
    </FormWrapper>
  );
};

export default FormSearch;
