import React, { useState, useEffect } from 'react';
import StandardLanding from '../../shared/components/StandardLanding';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Modal } from 'antd';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser';
import './styles.css';
import { getPrivacyPolicy, updatePrivacyPolicy } from './request';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

function PrivacyPolicy() {
  const [datainput, setDatainput] = useState();
  const [content, setContent] = useState();
  const confirmOK = async () => {
    const params = { value: datainput };
    const result = await updatePrivacyPolicy(params);
    const { data } = result;
  };
  useEffect(() => {
    privatePolicydata();
  }, []);
  const privatePolicydata = async () => {
    const result = await getPrivacyPolicy();
    const { data } = result;

    setContent(data?.data?.attributes?.value);
  };

  const showConfirm = () => {
    confirm({
      title: 'Do you Want to confirm these items to update?',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        confirmOK();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  return (
    <StandardLanding>
      {' '}
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>PRIVACY POLICY</p>
          <p className='md-title text-gray-20'>
            View and Edit Privacy Policy here
          </p>
        </div>
        <div className='mt-2'>
          <div
            className=' shadow-lg rounded'
            style={{
              borderRadius: '56px!important',
            }}
          >
            <CKEditor
              data={content ? content : ''}
              style={{ height: '500px' }}
              editor={ClassicEditor}
              onChange={(event, editor) => {
                const data = editor?.getData();
                console.log(data, event, editor);
                setDatainput(data);
              }}
            />

            <div
              className='flex justify-end p-5  mt-10 bg-white'
              style={{ border: 'none' }}
              s
            >
              <Button
                htmlType='submit'
                className='bg-blue-10 h-10  text-white w-32 mr-2'
                onClick={showConfirm}
              >
                SUBMIT
              </Button>

              <Button className='bg-red-10 h-10 text-white w-32'>CANCEL</Button>
            </div>
          </div>
        </div>
      </div>
    </StandardLanding>
  );
}

export default PrivacyPolicy;
