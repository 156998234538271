import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableDevelopment from '../components/TableDevelopment';
import { deleteDevelopmentRecord } from '../action';
import { getReportType, getDevelopmentList } from '../../general/action';
import { getUnitList } from '../../user-accounts/action';

const Landing = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { reportType, loading, listDevelopment } = state.general;
    const { listUnit } = state.account;

    useEffect(() => {
        const name = 'developments';
        dispatch(getReportType(name));
        dispatch(getDevelopmentList());
        dispatch(getUnitList());
    }, []);

    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">FAMILY, JUVENILE, GENDER & DEVELOPMENT</p>
                    <p className="md-title text-gray-20">View tabulated summary of reports here</p>
                </div>
                <div className="mt-2">
                    <TableDevelopment
                        loading={loading}
                        reportType={reportType}
                        listDevelopment={listDevelopment}
                        listUnit={listUnit}
                        getDevelopmentList={getDevelopmentList}
                        deleteDevelopmentRecord={deleteDevelopmentRecord}
                    />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
