import React from "react";
import styled from "@emotion/styled";

function CardWrapper(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default CardWrapper;

const StyledDiv = styled.div({
  ".ant-card": {
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    borderRadius: ".4rem",
  },
  ".ant-card-body": {
    padding: "0px !important",
  },
  ".card": {
    ".card-img": {
      objectFit: "cover",
      objectPosition: "center",
    },
    ".card-content": {
      padding: "20px",
    },
    ".card-lg-title": {
      fontSize: "2rem",
      lineHeight: "2rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },

    ".card-md-title": {
      fontSize: "1.5rem;",
      lineHeight: "2rem;",
      fontWeight: "500",
      textTransform: "capitalize",
    },

    ".card-sm-title": {
      fontSize: "12px;",
      lineHeight: ".9rem;",
      fontWeight: "600",
      textTransform: "capitalize",
    },
  },
});
