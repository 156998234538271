/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableSummary from '../components/TableSummary';

import { getSummary } from '../action';

import UI from '../../../shared/react-native/UI/js';
import TableSummary_supervisor from '../components/TableSummary_supervisor';

const Landing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const user = JSON.parse(localStorage.getItem('user'));

  const { summary } = state.summary;
  const [dataSummary, setDataSummary] = useState();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(user.role);
    if (user.role === 'super_admin' || user.role === 'admin') {
      dispatch(getSummary({ type: '' }));
    }
    if (user.role === 'supervisor') {
      // dispatch(getSummary({ type: '' }));
      dispatch(getSummary({ type: 'encoder' }));
    }
    get_categories();
  }, []);
  // console.log(summary);
  const get_categories = async () => {
    UI.apiRequest({
      method: 'get',
      url: 'categories',
      params: {
        search: '',
        per_page: '1000',
      },
      onSuccess: (data) => {
        setCategories(data.data);
      },
      onFail: (data) => {},
      isMultiPart: false,
      onFinish: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>SUMMARY</p>
          <p className='md-title text-gray-20'>
            View tabulated summary of reports here
          </p>
        </div>

        {user.role !== 'supervisor' ? (
          <div className='mt-2'>
            <TableSummary
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              categories={categories}
              summary={summary}
              dataSummary={dataSummary}
            />
          </div>
        ) : (
          <div className='mt-2'>
            <TableSummary_supervisor
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              categories={categories}
              summary={summary}
              dataSummary={dataSummary}
            />
          </div>
        )}
      </div>
    </StandardLanding>
  );
};

export default Landing;
