import React from "react";
import Images from "../Images/js";
import { View } from "../../custom-react-native";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}` + "/api/v1/";

class ui extends Images {
  constructor(props) {
    super(props);
  }

  apiRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const newRequestAuth = axios.create({
      baseURL: BASE_URL,
      headers: headers,
      timeout: 10000,
    });

    if (props.isMultiPart) {
      newRequestAuth.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }

    let queryParams = await this.encodeParams(props.params);
    let params = props.params;

    if (props.isMultiPart) {
      if (props.params) {
        params = new FormData();
        Object.keys(props.params).map((key) => {
          params.append(key, props.params[key]);
        });
      }
    }

    const response = await newRequestAuth[props.method.toLowerCase()](
      props.url + "?" + queryParams,
      params
    ).catch((err) => {
      if (props.onFail) {
        props.onFail(err.response.data);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response.data);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    }
  };

  encodeParams = (parameters = {}) => {
    return new Promise((resolve, reject) => {
      let new_query_string = "";
      let index = 0;
      let parameter_array = [];
      let param_keys = Object.keys(parameters);
      if (param_keys.length == 0) {
        resolve("");
      }
      param_keys.map((param_key) => {
        index++;
        let param_value = parameters[param_key];
        if (!param_value) {
          param_value = "";
        }
        parameter_array.push(param_key + "=" + param_value);
        if (index == param_keys.length) {
          new_query_string = parameter_array.join("&");
          resolve(new_query_string);
        }
      });
    });
  };

  goBack = () => {
    window.history.back();
  };

  goTo = (url) => {
    this._this.props.history.push(url);
  };

  getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };
  getHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  };

  colors = {
    primary: "#04CCFF",
    secondary: "#004F99",
    black: "#5D5D5D",
    yellow: "#FCC203",
  };

  box = (size) => {
    return <div style={{ height: size, width: size }}></div>;
  };

  PadView = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <View
        style={{
          width: "100%",
          paddingRight: paddingX,
          paddingLeft: paddingX,
          ...props.style,
        }}
      >
        {props.children}
      </View>
    );
  };

  form_errors = [];
  form_messages = [];

  clear_forms = () => {
    const inputs = document.getElementsByTagName("input");
    this.clear_el(inputs);
    const textareas = document.getElementsByTagName("textarea");
    this.clear_el(textareas);
  };

  clear_el = (els) => {
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      el.value = "";
    }
  };

  pad2 = (number) => {
    return (number < 10 ? "0" : "") + number;
  };

  measure = (id) => {
    let el = document.getElementById(id);
    if (el) {
      let bounds = el.getBoundingClientRect(el);
      return bounds;
    }
    return {};
  };

  uniqid = (prefix = "", random = false) => {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${
      random ? `.${Math.trunc(Math.random() * 100000000)}` : ""
    }`;
  };

  Row = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let width = _this.state.width;
    if (props.basis) {
      width = props.basis;
    }
    let flexDirection = "row";
    if (isMobile) {
      flexDirection = "column";
    } else {
      flexDirection = "row";
    }
    if (props.breakpoint != undefined) {
      if (width <= props.breakpoint) {
        flexDirection = "column";
        if (props.breakpoint_2 != undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "row";
            if (props.breakpoint_3 != undefined) {
              if (width <= props.breakpoint_3) {
                flexDirection = "column";
              } else {
                flexDirection = "row";
              }
            }
          } else {
            flexDirection = "column";
          }
        }
      } else {
        flexDirection = "row";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };

  Column = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let width = _this.state.width;
    if (props.basis) {
      width = props.basis;
    }
    let flexDirection = "column";
    if (isMobile) {
      flexDirection = "row";
    } else {
      flexDirection = "column";
    }
    if (props.breakpoint != undefined) {
      if (width <= props.breakpoint) {
        flexDirection = "row";
        if (props.breakpoint_2 != undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "column";
            if (props.breakpoint_3 != undefined) {
              if (width <= props.breakpoint_3) {
                flexDirection = "row";
              } else {
                flexDirection = "column";
              }
            }
          } else {
            flexDirection = "row";
          }
        }
      } else {
        flexDirection = "column";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };

  timestampToDate = (timestamp) => {
    timestamp = parseInt(timestamp);
    var dateObj = new Date(timestamp);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let h_m_am_pm = dateObj.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
    });
    let am_pm = h_m_am_pm.split(" ")[1].toLowerCase();
    let h_m = h_m_am_pm.split(" ")[0];
    let h = h_m.split(":")[0];
    let m = h_m.split(":")[1];

    let month_string = "";
    if (month == 1) {
      month_string = "January";
    } else if (month == 2) {
      month_string = "February";
    } else if (month == 3) {
      month_string = "March";
    } else if (month == 4) {
      month_string = "April";
    } else if (month == 5) {
      month_string = "May";
    } else if (month == 6) {
      month_string = "June";
    } else if (month == 7) {
      month_string = "July";
    } else if (month == 8) {
      month_string = "August";
    } else if (month == 9) {
      month_string = "September";
    } else if (month == 10) {
      month_string = "October";
    } else if (month == 11) {
      month_string = "November";
    } else if (month == 12) {
      month_string = "December";
    }

    let obj = {
      year: year,
      month: month,
      day: day,
      am_pm: am_pm,
      h_m: h_m,
      hour: h,
      minute: m,
      month_string: month_string,
    };

    return obj;
  };

  generate_password = () => {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  validate_email = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  rand = () => {
    return Math.random() * 100;
  };

  get_param = (param_name) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get(param_name);
    return c;
  };

  get_file = async () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        resolve(file);
      };
      input.click();
    });
  };

  get_image = async () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        if (
          input.files &&
          input.files[0] &&
          (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg")
        ) {
          var reader = new FileReader();

          reader.onload = (e) => {
            resolve({ file, uri: e.target.result });
          };
          reader.readAsDataURL(input.files[0]);
        }
      };
      input.click();
    });
  };

  get_file_with_extras = async () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();  
        var reader = new FileReader();
        let is_image = false;
        if (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg") {
          is_image = true;
        }

        reader.onload = (e) => {
          resolve({ file, uri: e.target.result, is_image, extension: ext });
        };
        reader.readAsDataURL(input.files[0]);
      };
      input.click();
    });
  };

  get_uri_from_file = async (file /* files[0] */) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  extract_data = (response) => {
    return response.data.d ?? response.data.data;
  };

  method_queue = (method = () => {}) => {
    setTimeout(method, 1);
  };

  container_filename = (path = "") => {
    let arr = path.split("-");
    arr.shift();
    return arr.join("-");
  };

  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  get_file_size = (file) => {
    let file_size = this.bytesToSize(file.size);

    return file_size;
  };

  get_file_size_from_url = (url) => {
    return new Promise(async (resolve, reject) => {
      const imageURL = url;
      const response = await fetch(imageURL);
      const blob = await response.blob();
      resolve(this.bytesToSize(blob.size));
    });
  };

  setState = this._this ? this._this.setState : () => {};
}

const UI = new ui();
export default UI;
