import { getAuthHeader, getError, header } from "../../shared/utils";
import {
  API_ROLE_LIST,
  API_UNIT_LIST,
  API_POSITION_LIST,
  API_ACCOUNT_LIST,
  API_SHOW_ACCOUNT,
  API_SHOW_USER_ACCOUNT,
  API_ADD_ACCOUNT,
  API_EDIT_ACCOUNT,
  API_UPDATE_ACCOUNT_STATUS,
  API_EDIT_USER_ACCOUNT,
} from "../../shared/constant/url";
import UI from "../../shared/react-native/UI/js";

export const ACCOUNT = {
  LIST_ACCOUNT_START: "LIST_ACCOUNT_START",
  LIST_ACCOUNT_SUCCESS: "LIST_ACCOUNT_SUCCESS",
  LIST_ACCOUNT_ERROR: "LIST_ACCOUNT_ERROR",

  LIST_ROLE_START: "LIST_ROLE_START",
  LIST_ROLE_SUCCESS: "LIST_ROLE_SUCCESS",
  LIST_ROLE_ERROR: "LIST_ROLE_ERROR",

  LIST_UNIT_START: "LIST_UNIT_START",
  LIST_UNIT_SUCCESS: "LIST_UNIT_SUCCESS",
  LIST_UNIT_ERROR: "LIST_UNIT_ERROR",

  LIST_POSITION_START: "LIST_POSITION_START",
  LIST_POSITION_SUCCESS: "LIST_POSITION_SUCCESS",
  LIST_POSITION_ERROR: "LIST_POSITION_ERROR",

  SHOW_ACCOUNT_START: "SHOW_ACCOUNT_START",
  SHOW_ACCOUNT_SUCCESS: "SHOW_ACCOUNT_SUCCESS",
  SHOW_ACCOUNT_ERROR: "SHOW_ACCOUNT_ERROR",

  ADD_ACCOUNT_START: "ADD_ACCOUNT_START",
  ADD_ACCOUNT_SUCCESS: "ADD_ACCOUNT_SUCCESS",
  ADD_ACCOUNT_ERROR: "ADD_ACCOUNT_ERROR",

  EDIT_ACCOUNT_START: "EDIT_ACCOUNT_START",
  EDIT_ACCOUNT_SUCCESS: "EDIT_ACCOUNT_SUCCESS",
  EDIT_ACCOUNT_ERROR: "EDIT_ACCOUNT_ERROR",

  UPDATE_ACCOUNT_START: "UPDATE_ACCOUNT_START",
  UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_ERROR: "UPDATE_ACCOUNT_ERROR",
};

export const getAccountList = (params) => {
  return async (dispatch) => {
    dispatch({ type: ACCOUNT.LIST_ACCOUNT_START });
    let requestOption = {
      method: "GET",
      headers: getAuthHeader(),
    };

    const url =
      API_ACCOUNT_LIST + (await UI.encodeParams(params)) + "&per_page=1000";
    fetch(url, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ACCOUNT.LIST_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: ACCOUNT.LIST_ACCOUNT_ERROR, payload: error });
      });
  };
};

export const showAccount = (params) => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.SHOW_ACCOUNT_START });
    let requestOption = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(`${API_SHOW_ACCOUNT}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ACCOUNT.SHOW_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: ACCOUNT.SHOW_ACCOUNT_ERROR, payload: error });
      });
  };
};
export const showUserAccount = (params) => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.SHOW_ACCOUNT_START });
    let requestOption = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_SHOW_USER_ACCOUNT, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ACCOUNT.SHOW_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: ACCOUNT.SHOW_ACCOUNT_ERROR, payload: error });
      });
  };
};

export const getRoleList = () => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.LIST_ROLE_START });
    let requestOption = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_ROLE_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ACCOUNT.LIST_ROLE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: ACCOUNT.LIST_ROLE_ERROR, payload: error });
      });
  };
};

export const getPositionList = () => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.LIST_POSITION_START });
    let requestOption = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_POSITION_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ACCOUNT.LIST_POSITION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: ACCOUNT.LIST_POSITION_ERROR, payload: error });
      });
  };
};

export const getUnitList = () => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.LIST_UNIT_START });
    let requestOption = {
      method: "GET",
      headers: getAuthHeader(),
    };
    fetch(API_UNIT_LIST, requestOption)
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: ACCOUNT.LIST_UNIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: ACCOUNT.LIST_UNIT_ERROR, payload: error });
      });
  };
};

export const addAccount = (params, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.ADD_ACCOUNT_START });
    let formData = new FormData();
    formData.append("name", params.name);
    formData.append("rank", params.rank);
    formData.append("unit", params.unit);
    formData.append("role", params.role);
    formData.append("email", params.email);
    formData.append("gender", params.gender);
    formData.append("contact_number", params.contact_number);
    formData.append("status", params.status);
    formData.append("image", file[0]?.originFileObj);
    if (params.password) {
      formData.append("password", params.password);
    }

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_ACCOUNT, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        callback(response, null);
        dispatch({
          type: ACCOUNT.ADD_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        console.log(error);
        dispatch({
          type: ACCOUNT.ADD_ACCOUNT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editAccount = (params, id, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.EDIT_ACCOUNT_START });
    let formData = new FormData();
    formData.append("_method", "PUT");
    if (params.name) {
      formData.append("name", params.name);
    }
    if (params.rank) {
      formData.append("rank", params.rank);
    }
    if (params.unit) {
      formData.append("unit", params.unit);
    }
    if (params.role) {
      formData.append("role", params.role);
    }
    if (params.email) {
      formData.append("email", params.email);
    }
    if (params.password) {
      formData.append("password", params.password);
    }
    if (params.gender) {
      formData.append("gender", params.gender);
    }
    if (file[0]?.originFileObj) {
      formData.append("image", file[0]?.originFileObj);
    }
    if (params.contact_number) {
      formData.append("contact_number", params.contact_number);
    }
    if (params.status) {
      formData.append("status", params.status);
    }

    let url = `${API_EDIT_ACCOUNT}${id}`;

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ACCOUNT.EDIT_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ACCOUNT.EDIT_ACCOUNT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editUserAccount = (params, id, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.EDIT_ACCOUNT_START });
    let formData = new FormData();
    formData.append("_method", "PUT");
    if (params.name) {
      formData.append("name", params.name);
    }
    if (params.unit) {
      formData.append("unit", params.unit);
    }
    if (params.role) {
      formData.append("role", params.role);
    }
    if (params.email) {
      formData.append("email", params.email);
    }
    if (params.gender) {
      formData.append("gender", params.gender);
    }
    if (params.rank) {
      formData.append("rank", params.rank);
    }
    if (file[0]?.originFileObj) {
      formData.append("image", file[0]?.originFileObj);
    }
    if (params.contact_number) {
      formData.append("contact_number", params.contact_number);
    }
    if (params.status) {
      formData.append("status", params.status);
    }

    let url = API_EDIT_USER_ACCOUNT;

    let requestOptions = {
      method: "POST",
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: ACCOUNT.EDIT_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: ACCOUNT.EDIT_ACCOUNT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const updateAccountStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: ACCOUNT.UPDATE_ACCOUNT_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: "PATCH",
      body: data,
      headers: header(),
    };
    fetch(`${API_UPDATE_ACCOUNT_STATUS}${id}/status`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: ACCOUNT.UPDATE_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: ACCOUNT.UPDATE_ACCOUNT_ERROR, payload: error });
      });
  };
};
