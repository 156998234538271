import { getAuthHeader, getError, header } from '../../shared/utils';
import {
  API_ADD_INFORMATION_OPERATION,
  API_EDIT_INFORMATION_OPERATION,
  API_DELETE_INFORMATION_OPERATION,
  API_UPDATE_INFORMATION_OPERATION_STATUS,
  API_GET_REFERENCE_TABLE,
  API_GET_ACTIVITYLOG,
} from '../../shared/constant/url';

export const INFO = {
  ADD_START: 'ADD_START',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_ERROR: 'ADD_ERROR',

  DELETE_START: 'DELETE_START',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_ERROR: 'DELETE_ERROR',

  EDIT_START: 'EDIT_START',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  EDIT_ERROR: 'EDIT_ERROR',

  UPDATE_INFORMATION_OPERATION_START: 'UPDATE_INFORMATION_OPERATION_START',
  UPDATE_INFORMATION_OPERATION_SUCCESS: 'UPDATE_INFORMATION_OPERATION_SUCCESS',
  UPDATE_INFORMATION_OPERATION_ERROR: 'UPDATE_INFORMATION_OPERATION_ERROR',
};

export const addNewInfoRecord = (params, file, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.ADD_START });
    let formData = new FormData();
    formData.append('report_type', params.category);
    formData.append('date_submitted', params.date_submitted);
    formData.append('subject', params.subject);
    formData.append('description', params.description);
    formData.append('file', file[0]?.file);

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(API_ADD_INFORMATION_OPERATION, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: INFO.ADD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: INFO.ADD_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const editInfoRecord = (params, file, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.EDIT_START });
    let formData = new FormData();
    if (params.category) {
      formData.append('report_type', params.category);
    }
    if (params.date_submitted) {
      formData.append('date_submitted', params.date_submitted);
    }
    if (params.subject) {
      formData.append('subject', params.subject);
    }
    if (params.description) {
      formData.append('description', params.description);
    }

    if (file[0]?.file) {
      if (file[0].file.id) {
      } else {
        formData.append('file', file[0]?.file);
      }
    }

    formData.append('_method', 'PUT');

    let requestOptions = {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    };
    fetch(`${API_EDIT_INFORMATION_OPERATION}${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);
        dispatch({
          type: INFO.EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(null, error);
        dispatch({
          type: INFO.EDIT_ERROR,
          payload: getError(error),
        });
      });
  };
};

export const deleteInfoOperation = (params, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.DELETE_START });
    let requestOption = {
      method: 'DELETE',
      headers: getAuthHeader(),
    };
    fetch(`${API_DELETE_INFORMATION_OPERATION}${params}`, requestOption)
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: INFO.DELETE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({ type: INFO.DELETE_ERROR, payload: error });
      });
  };
};

export const updateInfoOperationStatus = (params, id, callback = null) => {
  return (dispatch) => {
    dispatch({ type: INFO.UPDATE_INFORMATION_OPERATION_START });
    let data = JSON.stringify({ status: params.status });

    let requestOption = {
      method: 'PATCH',
      body: data,
      headers: header(),
    };
    fetch(
      `${API_UPDATE_INFORMATION_OPERATION_STATUS}${id}/status`,
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response);
        dispatch({
          type: INFO.UPDATE_INFORMATION_OPERATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        callback(error, getError(error));
        dispatch({
          type: INFO.UPDATE_INFORMATION_OPERATION_ERROR,
          payload: error,
        });
      });
  };
};

export const getActivitylogList = (params, callback = null) => {
  let token = localStorage.getItem('token');
  return (dispatch) => {
    dispatch({ type: INFO.UPDATE_INFORMATION_OPERATION_START });

    let requestOption = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Accept': 'application/json',
      },
    };

    // console.log(`${API_ANNOUNCEMENT_LIST}${params ? params.search : ""}` +
    // "&per_page=1000")

    fetch(
      params
        ? `${API_GET_ACTIVITYLOG}?include[]=causer&search=${
            params.search ? params.search : ''
          }&start_date=${
            params?.formatDateStart ? params?.formatDateStart : ''
          }&end_date=${
            params?.formatDateEnd ? params?.formatDateEnd : ''
          }&per_page=1000`
        : `${API_GET_ACTIVITYLOG}?include[]=causer&per_page=1000`,
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        callback(response, null);

        dispatch({
          type: INFO.UPDATE_INFORMATION_OPERATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({
          type: INFO.UPDATE_INFORMATION_OPERATION_ERROR,
          payload: error,
        });
      });
  };
};
