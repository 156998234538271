import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Upload, Skeleton, Button, message, Select } from 'antd';

import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/react-native/UI/js';
import FormWrapper from '../../../shared/components/FormWrapper';
import { getReferenceReport } from '../../general/action';
const { Option } = Select;
const FormAddEdit = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { TextArea } = Input;

  const state = useSelector((state) => state);
  const { loading, showAnnouncement } = state.announcement;
  const { addAnnouncement, editAnnouncement, userdata } = props;
  const history = useHistory();

  const [fileList, setFileList] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [userInput, setUserInput] = useState({
    category: userdata?.category?.id ? userdata?.category?.id : '',
    name: userdata?.name ? userdata?.name : '',
    tableType: userdata?.tableType,
  });
  const [tableType, setTableType] = useState('');
  useEffect(() => {
    if (id) {
      onSetFieldValue();
    }
  }, [showAnnouncement]);
  useEffect(() => {
    dispatch(getReferenceReport(Callback));
  }, []);
  const Callback = (response) => {
    setCategoryType(response.data);
  };
  const onSetFieldValue = () => {
    if (id) {
      setFileList([
        showAnnouncement && {
          name: showAnnouncement.file?.name,
          uri: showAnnouncement.file?.url,
        },
      ]);
      form.setFieldsValue({
        title: showAnnouncement.title,
        description: showAnnouncement.description,
      });
    } else {
      setFileList([]);
      form.setFieldsValue({
        title: '',
        description: '',
      });
    }
  };

  const renderFileName = () => {
    if ((showAnnouncement && showAnnouncement.file.name < 1) || fileList < 1) {
      return (
        <TouchableOpacity
          onClick={() => {
            selectFile();
          }}
        >
          <div
            className='bg-gray-10 p-8 rounded-md'
            style={{ width: 100 }}
          >
            <i className='fas fa-file-upload text-blue-10 text-5xl'></i>
          </div>
        </TouchableOpacity>
      );
    }
  };

  const onFinish = (value) => {
    // const data = { category: userdata.category.id, name: userdata.name };
    console.log(id);
    if (id) {
      if (!userInput.category && !userInput.name) {
        const data = { category: userdata.category.id, name: userdata.name };
        console.log(data);
        dispatch(editAnnouncement(data, id, callback));
      }
      if (userInput.category && userInput.name) {
        const data = { category: userInput.category, name: userInput.name };
        console.log(data);
        dispatch(editAnnouncement(data, id, callback));
      }
      if (!userInput.category && userInput.name) {
        const data = { category: userdata.category.id, name: userInput.name };
        console.log(data);
        dispatch(editAnnouncement(data, id, callback));
      }
      if (userInput.category && !userInput.name) {
        const data = { category: userInput.category, name: userdata.name };
        console.log(data);
        dispatch(editAnnouncement(data, id, callback));
      }
      // console.log('first');
    } else {
      console.log(value);
      dispatch(addAnnouncement(value, callback));
      // console.log('second');
    }
  };

  const callback = (response, error) => {
    const res = response.message;

    if (res === 'Reference Table created successfully.') {
      onSetFieldValue();
      message.success(res);
      history.push('/reference-table-maintenance');
    } else if (res === 'Reference Table updated successfully.') {
      message.success(res);
      history.push('/reference-table-maintenance');
    }
  };

  const selectFile = async () => {
    const file = await UI.get_image();
    if (file) {
      const size = file.file.size;
      if (size > 10000000) {
        message.error(
          'The file you uploaded exceeded the max. Given: ' +
            UI.get_file_size(file.file),
        );
      } else {
        setFileList([file]);
      }
    }
  };

  return (
    <div className='sub-container p-5 md:p-10'>
      <FormWrapper>
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
          >
            <div className='row'>
              <div className='col'>
                <div className=''>
                  <p
                    className='text-blue-10 mb-3'
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      maxWidth: '320px',
                    }}
                  >
                    Table Type
                  </p>

                  <Form.Item
                    name='table_type'
                    className='md:mb-14'
                  >
                    <Select
                      defaultValue={
                        userdata?.attributes?.table_type
                          ? userdata?.attributes?.table_type
                          : ''
                      }
                      style={{
                        maxWidth: '320px',
                      }}
                      onChange={
                        // (e) => console.log(e)
                        (e) =>
                          setUserInput((prev) => {
                            return { ...prev, tableType: e };
                          })
                      }
                    >
                      <Option
                        hidden
                        value=''
                      >
                        Select Table Type
                      </Option>
                      <Option value='report_types'>Report Type</Option>
                      <Option value='ranks'>Rank</Option>
                      <Option value='units'>Unit</Option>
                    </Select>
                  </Form.Item>
                </div>
                {userInput.tableType === 'report_types' && (
                  <div className=''>
                    <p
                      className='text-blue-10 mb-3'
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        maxWidth: '320px',
                      }}
                    >
                      Category Type
                    </p>

                    <Form.Item
                      name='category'
                      className='md:mb-14'
                    >
                      <Select
                        defaultValue={
                          userdata?.attributes?.category_type
                            ? userdata?.attributes?.category_type
                            : ''
                        }
                        style={{
                          maxWidth: '320px',
                        }}
                        onChange={
                          // (e) => console.log(e)
                          (e) =>
                            setUserInput((prev) => {
                              return { ...prev, category: e };
                            })
                        }
                      >
                        <Option
                          hidden
                          value=''
                        >
                          Select Report Type
                        </Option>
                        {categoryType.map((data) => {
                          return <Option value={data.id}>{data.name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </div>
              <div className='col'>
                <div className='w-full'>
                  <p
                    className='text-blue-10 mb-3'
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    Name
                  </p>
                  <Form.Item
                    name='name'
                    className='md:mb-14'
                    style={{ maxWidth: '320px' }}
                  >
                    <Input
                      defaultValue={
                        userdata?.attributes?.name
                          ? userdata?.attributes?.name
                          : ''
                      }
                      placeholder='Enter Details Here'
                      onChange={(e) =>
                        setUserInput((prev) => {
                          return { ...prev, name: e.target.value };
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className='flex justify-end mt-10'>
              <Button
                htmlType='submit'
                className='bg-blue-10 h-10  text-white w-32 mr-2'
              >
                SUBMIT
              </Button>
              <Link to='/reference-table-maintenance'>
                <Button className='bg-red-10 h-10 text-white w-32'>
                  CANCEL
                </Button>
              </Link>
            </div>
          </Form>
        )}
      </FormWrapper>
    </div>
  );
};

const styles = StyleSheet.create({
  image_pick: {
    height: 100,
    width: 100,
    borderRadius: 10,
  },
});
export default FormAddEdit;
