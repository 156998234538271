import React from "react";
import { useDispatch } from "react-redux";
import { Card, Select, Table, Form } from "antd";
import CardWrapper from "../../../shared/components/CardWrapper";
import FormWrapper from "../../../shared/components/FormWrapper";

const CardReports = (props) => {
  const dispatch = useDispatch();
  const { getPendingReportList, listPendingReport, loading } = props;

  const onFilterBy = (e) => {
    const filter = { filter_by: e };
    dispatch(getPendingReportList(filter));
  };

  const formFilter = () => {
    return (
      <FormWrapper>
        <Form className="-mb-6 w-32">
          <Form.Item>
            <Select placeholder="Daily" onChange={onFilterBy}>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </Select>
          </Form.Item>
        </Form>
      </FormWrapper>
    );
  };

  const columns = [
    {
      title: "Report Type",
      dataIndex: "report_type",
      key: "id",
      width: "60%",
    },
    {
      title: "Date Submitted",
      dataIndex: "date_submitted",
      key: "id",
      width: "40%",
    },
  ];

  return (
    <CardWrapper>
      <Card className="card">
        <div className="p-4 flex justify-between bg-orange-10 text-white rounded-tl-md rounded-tr-md">
          <div className="flex items-center w-1/2">
            <i className="fas fa-exclamation-circle text-5xl mr-2 text-white"></i>
            <p className="font-bold">
              Total Number of Pending Reports (
              {listPendingReport && listPendingReport.length})
            </p>
          </div>
          <div className="flex justify-end items-center w-1/2">
            <p className="mr-2 font-bold">Filter By:</p>
            {formFilter()}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={listPendingReport}
          loading={loading}
          pagination={false}
          scroll={{ y: 200 }}
          style={{ marginBottom: "2em" }}
        />
      </Card>
    </CardWrapper>
  );
};

export default CardReports;
