import React from "react";
import styled from "@emotion/styled";

function FrontendLanding(props) {
  const { children } = props;
  return <StyledDiv>{children}</StyledDiv>;
}

export default FrontendLanding;

const StyledDiv = styled.div({

  ".main-container": {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

      ".lg-title": {
        fontSize: "1.5rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        marginBottom: "2rem",
      },
      ".md-title": {
        fontSize: "1rem;",
        lineHeight: "2rem;",
        fontWeight: "500",
        marginBottom: "2rem",
      },
      ".sm-title": {
        fontSize: "0.7rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        marginBottom: "2rem",
      },
      ".xs-title": {
        fontSize: "0.5rem;",
        lineHeight: "2rem;",
        fontWeight: "600",
        marginBottom: "2rem",
      },
    },
  ".content-crop": {
    height: "calc(100vh - 72px); !important",
  },
});
