import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Avatar } from 'antd';
import bggroup from '../../../shared/images/Group 36.png';
import CardWrapper from '../../../shared/components/CardWrapper';

const CardProfile = (props) => {
  const { showProfile } = props;

  return (
    <CardWrapper>
      <Card className='card w-100'>
        <div className='w-100 flex items-center justify-between bg-unsplash text-white py-7 px-10 rounded-md'>
          <Avatar
            src={showProfile?.file}
            size={100}
          />
          <div className='ml-5'>
            <p className='card-md-title'>{showProfile?.full_name}</p>
            <p className='card-sm-title mb-5'>{showProfile?.position}</p>
            <Link to={`/edit-profile/${showProfile?.id}`}>
              <Button className='h-10 w-40'>
                Edit Profile&nbsp;<i className='fas fa-arrow-right'></i>
              </Button>
            </Link>
          </div>
        </div>
        <div className='card-content'>
          <p className='mb-2'>
            <span className='font-bold'>Rank:</span>
            <span className='text-gray-20 ml-2'>
              {showProfile?.rank && showProfile?.rank?.name}
            </span>
          </p>
          <p className='mb-2'>
            <span className='font-bold'>Unit:</span>
            <span className='text-gray-20 ml-2'>
              {showProfile && showProfile?.unit?.name}
            </span>
          </p>
          <p className='mb-2'>
            <span className='font-bold'>Duty Status:</span>
            <span className='text-green-10 ml-2'>
              {showProfile?.status === 'active' ? 'ACTIVE' : 'INACTIVE'}
            </span>
          </p>
          <p className='mb-2'>
            <span className='font-bold'>Contact No.:</span>
            <span className='text-gray-20 ml-2'>
              {showProfile?.contact_number}
            </span>
          </p>
          <p className='mb-2'>
            <span className='font-bold'>Email:</span>
            <span className='text-gray-20 ml-2'>{showProfile?.email}</span>
          </p>
          <p className='mb-2'>
            <span className='font-bold'>Sex:</span>
            <span className='text-gray-20 ml-2'>{showProfile?.gender}</span>
          </p>
        </div>
      </Card>
    </CardWrapper>
  );
};

export default CardProfile;
