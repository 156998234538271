import TouchableOpacity from "./react-native/TouchableOpacity/js";
import ScrollView from "./react-native/ScrollView/js";
import View from "./react-native/View/js";
import ImageBackground, { ImageFit, Image } from "./react-native/Image/js";
import Text from "./react-native/Text/js";
import r from "./react-native/Request/js";
import Shadow from "./react-native/Shadow/js";
import StyleSheet from "./react-native/StyleSheet/js";
import mem from "./react-native/Memory/js";
import IconTint from 'react-icon-tint';
const Icon = IconTint;

export default TouchableOpacity
export {TouchableOpacity};
export {ScrollView};
export {View};
export {Image};
export {ImageBackground};
export {ImageFit};
export {Text};
export {StyleSheet};
export {Icon};
export {r};
export {Shadow};
export {mem};



