import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Upload,
  Skeleton,
  Button,
  message,
  Select,
  DatePicker,
  Space,
  TimePicker,
} from 'antd';
import './FormAddEdit.css';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from '../../../shared/custom-react-native';
import moment from 'moment';
import UI from '../../../shared/react-native/UI/js';
import FormWrapper from '../../../shared/components/FormWrapper';
import { getReferenceReport, getReportType } from '../../general/action';
const { Option } = Select;
const FormAddEdit = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { TextArea } = Input;
  let newDate = new Date();
  const state = useSelector((state) => state);
  const { loading, showAnnouncement } = state.announcement;
  const { reportType, listInfo } = state.general;
  const { addAnnouncement, editAnnouncement, userdata } = props;
  const history = useHistory();

  const [fileList, setFileList] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [userInput, setUserInput] = useState({
    category: '',
    sub_category: '',
    title: '',
    description: '',
    date: '',
    time: '',
  });
  const [categoryID, setCategoryID] = useState('');
  useEffect(() => {
    if (id) {
      onSetFieldValue();
    }
  }, [showAnnouncement]);
  useEffect(() => {
    dispatch(getReferenceReport(Callback));
  }, []);
  const Callback = (response) => {
    setCategoryType(response.data);
  };
  useEffect(() => {
    setUserInput({
      category: userdata.relationships?.category.id,
      sub_category: userdata.relationships?.sub_category.id,
      title: userdata.attributes?.title,
      description: userdata.attributes?.description,
      date: moment(userdata.attributes?.date).format('YYYY-MM-DD'),
      time: moment(userdata.attributes?.time, ['h:mm A']).format('HH:mm'),
    });
  }, [userdata]);
  const onSetFieldValue = () => {
    if (id) {
      setFileList([
        showAnnouncement && {
          name: showAnnouncement.file?.name,
          uri: showAnnouncement.file?.url,
        },
      ]);
      form.setFieldsValue({
        title: showAnnouncement.title,
        description: showAnnouncement.description,
      });
    } else {
      setFileList([]);
      form.setFieldsValue({
        title: '',
        description: '',
      });
    }
  };

  const renderFileName = () => {
    if ((showAnnouncement && showAnnouncement.file.name < 1) || fileList < 1) {
      return (
        <TouchableOpacity
          onClick={() => {
            selectFile();
          }}
        >
          <div
            className='bg-gray-10 p-8 rounded-md'
            style={{ width: 100 }}
          >
            <i className='fas fa-file-upload text-blue-10 text-5xl'></i>
          </div>
        </TouchableOpacity>
      );
    }
  };

  const onFinish = (value) => {
    if (id) {
      console.log(userInput);
      dispatch(editAnnouncement(userInput, id, callback));
    } else {
      const data = {
        title: value.title,
        description: value.description,
        category: value.category,
        sub_category: value.sub_category,
        date: userInput.date,
        time: userInput.time,
      };
      console.log(data);
      dispatch(addAnnouncement(data, callback));
    }
  };

  const callback = async (response, error) => {
    const res = await response?.message;
    console.log(response);
    if (res === 'Frequency Report added successfully.') {
      onSetFieldValue();
      message.success(res);
      history.push('/frequency-report');
    } else if (res === 'Frequency Report updated successfully.') {
      message.success(res);
      history.push('/frequency-report');
    } else if (response?.errors['date']?.[1]) {
      message.error(response?.errors['date'][1]);
    } else if (response?.errors['date']?.[0]) {
      message.error(response?.errors['date'][0]);
    } else if (response?.errors['title']?.[0]) {
      message.error(response?.errors['title'][0]);
    } else {
      message.error(res);
    }
  };
  useEffect(() => {
    const name = categoryID;
    dispatch(getReportType(name));
  }, [categoryID]);

  const selectFile = async () => {
    const file = await UI.get_image();
    if (file) {
      const size = file.file.size;
      if (size > 10000000) {
        message.error(
          'The file you uploaded exceeded the max. Given: ' +
            UI.get_file_size(file.file),
        );
      } else {
        setFileList([file]);
      }
    }
  };
  const onChange = (time, timeString) => {
    console.log(time);
    console.log(moment(time).format('HH:mm'));
    setUserInput((prev) => {
      return { ...prev, time: moment(time).format('HH:mm') };
    });
  };

  const onChangeDate = (date, dateString) => {
    console.log(moment(dateString).format('MM/DD/YYYY'));
    setUserInput((prev) => {
      return { ...prev, date: moment(dateString).format('MM/DD/YYYY') };
    });
  };

  return (
    <div className='sub-container p-5 md:p-10'>
      <FormWrapper>
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
          >
            <div className='row'>
              <div className='col-lg-6'>
                {' '}
                <div className=''>
                  <p
                    className='text-blue-10 mb-3'
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    Category Type
                  </p>

                  <Form.Item
                    name='category'
                    className='md:mb-14'
                  >
                    <Select
                      value={categoryType}
                      disabled={
                        history.location.pathname === `/view-frequency/${id}`
                          ? true
                          : false
                      }
                      defaultValue={
                        userdata.relationships?.category.id
                          ? userdata.relationships?.category.id
                          : ''
                      }
                      onChange={(e) => {
                        setUserInput((prev) => {
                          setCategoryID(e);
                          return { ...prev, category: e };
                        });
                      }}
                    >
                      <Option
                        hidden
                        value=''
                      >
                        Select Category Type
                      </Option>
                      {categoryType.map((data) => {
                        return <Option value={data.id}>{data.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </div>{' '}
                <div className=''>
                  <p
                    className='text-blue-10 mb-3'
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    Sub-Category
                  </p>

                  <Form.Item
                    name='sub_category'
                    className='md:mb-14'
                  >
                    <Select
                      disabled={
                        history.location.pathname === `/view-frequency/${id}`
                          ? true
                          : false
                      }
                      value={categoryType}
                      defaultValue={
                        userdata.relationships?.sub_category.id
                          ? userdata.relationships?.sub_category.id
                          : ''
                      }
                      onChange={(e) => {
                        setUserInput((prev) => {
                          console.log(e);
                          return { ...prev, sub_category: e };
                        });
                      }}
                    >
                      <Option
                        hidden
                        value=''
                      >
                        Select Sub-Category
                      </Option>
                      {reportType?.map((data) => {
                        return <Option value={data.id}>{data.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p
                    className='text-blue-10 mb-2'
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    Description
                  </p>
                  <Form.Item
                    name='description'
                    className='md:mb-14'
                  >
                    <Input.TextArea
                      disabled={
                        history.location.pathname === `/view-frequency/${id}`
                          ? true
                          : false
                      }
                      defaultValue={
                        userdata.attributes?.description
                          ? userdata.attributes?.description
                          : ''
                      }
                      onChange={(e) => {
                        setUserInput((prev) => {
                          return { ...prev, description: e.target.value };
                        });
                      }}
                      style={{ minHeight: '6rem' }}
                      rows={5}
                      placeholder='Enter Description'
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='w-full'>
                  <p
                    className='text-blue-10 mb-3'
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    Title
                  </p>
                  <Form.Item
                    name='title'
                    className='md:mb-14'
                  >
                    <Input
                      disabled={
                        history.location.pathname === `/view-frequency/${id}`
                          ? true
                          : false
                      }
                      defaultValue={userdata.attributes?.title}
                      value={userdata.attributes?.title}
                      placeholder='Enter Title'
                      onChange={(e) => {
                        setUserInput((prev) => {
                          return { ...prev, title: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className=''
                  style={{ marginBottom: '3.5rem' }}
                >
                  <p
                    className='text-blue-10 mb-3 mt-5'
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    Date
                  </p>
                  <Space
                    direction='vertical'
                    className='w-100'
                  >
                    <DatePicker
                      // placeholder='Select Date'
                      disabled={
                        history.location.pathname === `/view-frequency/${id}`
                          ? true
                          : false
                      }
                      onChange={onChangeDate}
                      defaultValue={
                        userdata?.attributes?.date !== undefined
                          ? moment(userInput?.date ,'YYYY-MM-DD')
                          : ''
                      }
                      style={{ height: '47.99px' }}
                    />
                  </Space>
                </div>
                <div style={{ marginTop: '3.5rem!important' }}>
                  <p
                    className='text-blue-10 mb-2'
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Time
                  </p>
                  <TimePicker
                    style={{ color: 'red!important' }}
                    disabled={
                      history.location.pathname === `/view-frequency/${id}`
                        ? true
                        : false
                    }
                    onChange={onChange}
                    defaultValue={
                      userdata.attributes?.time
                        ? moment(userdata.attributes?.time, 'HH:mm:ss A')
                        : moment('00:00:00', 'HH:mm:ss')
                    }
                  />
                </div>
              </div>
            </div>

            <div className='flex justify-end mt-10'>
              <Button
                htmlType='submit'
                className='bg-blue-10 h-10  text-white w-32 mr-2'
              >
                SUBMIT
              </Button>
              <Link to='/frequency-report'>
                <Button className='bg-red-10 h-10 text-white w-32'>
                  CANCEL
                </Button>
              </Link>
            </div>
          </Form>
        )}
      </FormWrapper>
    </div>
  );
};

const styles = StyleSheet.create({
  image_pick: {
    height: 100,
    width: 100,
    borderRadius: 10,
  },
});
export default FormAddEdit;
