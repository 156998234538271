class stylesheet {
  create = (styles = {}) => {
    return styles;
  };
}

export const StyleSheet = new stylesheet();


export const styled = StyleSheet.create({
  pending: {
    background: '#c07127',
    textAlign: 'center',
    color: 'white',
    borderRadius: '3px',
    width:'70%'
  },
  approved: {
    background: '#42ba96',
    textAlign: 'center',
    color: 'white',
    borderRadius: '3px',
    width:'70%'
  },
  declined: {
    background: '#d94647',
    textAlign: 'center',
    color: 'white',
    borderRadius: '3px',
    width:'70%'
  },
  btn: {
    textAlign: 'center',
    color: 'white',
    borderRadius: '3px',
    fontSize: '1.125rem',
    width: '2rem',
    padding: '9px 0 9px 0'
  }
  ,
  showSidebar: {
    width: '20%',
  },
  hideSidebar: {
    width: '0rem',
    overflow: 'hidden',
   
  }

});

export default styled;