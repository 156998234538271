import React, { useEffect } from 'react';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableAccount from '../components/TableAccount';
import { getAccountList } from '../action';
import { useDispatch, useSelector } from 'react-redux';
import { getUnitList } from '../../user-accounts/action';

const Landing = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { listAccount, loading } = state.account;
    const { listUnit } = state.account;

    useEffect(() => {
        dispatch(getAccountList());
        dispatch(getUnitList());
    }, []);

    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">USER MANAGEMENT</p>
                    <p className="md-title text-gray-20">View user management here</p>
                </div>
                <div className="mt-2">
                    <TableAccount listUnit={listUnit} listAccount={listAccount} loading={loading} />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
