import { ANNOUNCEMENT } from './action';

const INITIAL_STATE = {
    loading: false,
    error: '',
    isLoggedIn: false,
    showAnnouncement: "",
    listAnnouncement: [],
};


const announcement = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ANNOUNCEMENT.SHOW_ANNOUNCEMENT_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case ANNOUNCEMENT.SHOW_ANNOUNCEMENT_SUCCESS:
            return { ...state, loading: false, showAnnouncement: action.payload, isLoggedIn: true }
        case ANNOUNCEMENT.SHOW_ANNOUNCEMENT_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        case ANNOUNCEMENT.LIST_ANNOUNCEMENT_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case ANNOUNCEMENT.LIST_ANNOUNCEMENT_SUCCESS:
            return { ...state, loading: false, listAnnouncement: action.payload, isLoggedIn: true }
        case ANNOUNCEMENT.LIST_ANNOUNCEMENT_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        default:
            return state;
    }
}

export default announcement;


