import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Form, Input, Upload, Skeleton, Button, Select, message } from 'antd';
import FormWrapper from '../../../shared/components/FormWrapper';
import banner from '../../../shared/images/Group 36.png';
import { useSelector, useDispatch } from 'react-redux';
import { mem, View } from '../../../shared/custom-react-native';
import { BsFillPencilFill } from 'react-icons/bs';
import UI from '../../../shared/react-native/UI/js';
import { showUserAccount } from '../action';
import './FormAddEdit.css';
const FormAddEdit = (props) => {
  const history = useHistory();
  const { id, view } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const state = useSelector((state) => state);
  const { showAccount, listUnit, listPosition, listRole, loading } =
    state.account;
  const role = showAccount.role;
  const { addAccount, editAccount, editUserAccount } = props;

  const user = JSON.parse(localStorage.getItem('user'));

  const { pathname } = history.location;

  const currentPage = pathname.split('/', 2).pop();

  const onChange = ({ fileList: newFileList }) => {
    console.log(newFileList);
    setFileList(newFileList);
  };
  const propsUpload = {
    // setFileList({ name: file.name, response: '' }),
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'Download',
      showRemoveIcon: true,
      response: '',
      status: '',
      showRemoveIcon: true,
    },
    maxCount: 1,
    accept: '.png,.jpeg,.jpg',
    beforeUpload: (file) => {
      return false;
    },
  };

  useEffect(() => {
    if (showAccount) {
      onSetFieldValue();
    }
  }, [showAccount]);

  const onSetFieldValue = () => {
    if (id !== showAccount.id) {
      setProfileImage(showAccount.file);
      form.setFieldsValue({
        name: showAccount.full_name,
        rank: showAccount.rank && showAccount.rank.id,
        unit: showAccount.unit && showAccount.unit.id,
        email: showAccount.email,
        gender: showAccount.gender,
        sub_unit: showAccount.sub_unit,
        contact_number: showAccount.contact_number,
        status: showAccount.status,
      });
    } else {
      if (id) {
        setProfileImage(showAccount.file);
        form.setFieldsValue({
          name: showAccount.full_name,
          rank: showAccount.rank && showAccount.rank.id,
          role: showAccount.role,
          unit: showAccount.unit && showAccount.unit.id,
          sub_unit: showAccount.sub_unit,
          email: showAccount.email,
          gender: showAccount.gender,
          contact_number: showAccount.contact_number,
          status: showAccount.status,
        });

        console.log(form);
      } else {
        form.setFieldsValue({
          name: '',
          rank: '',
          role: '',
          unit: '',
          sub_unit: '',
          email: '',
          gender: '',
          contact_number: '',
          password: '',
          status: 'active',
        });
      }
    }
  };

  useEffect(() => {
    if (fileList[0]?.originFileObj) {
      const filesArray = URL.createObjectURL(fileList[0]?.originFileObj);
      console.log(filesArray);
      setFiles(filesArray);
      URL.revokeObjectURL(fileList[0]?.originFileObj);
    }
  }, [files.length === 0 ? fileList : '']);

  const renderUpload = () => {
    return (
      <div className='mt-10 lg:-mt-40 flex justify-center w-full'>
        <Upload
          action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
          {...propsUpload}
          onChange={onChange}
        >
          {fileList < 1 ? (
            <div className='bg-gray-10 file-uploader'>
              <i className='fas fa-user text-blue-10 text-5xl'></i>
            </div>
          ) : (
            <div className='flex justify-center w-96'>
              <img
                src={files[0]}
                className='file-uploader'
              />
            </div>
          )}
        </Upload>
      </div>
    );
  };

  const renderUnit = () => {
    return (
      listUnit &&
      listUnit.map((item) => {
        return (
          <option
            className='text-uppercase'
            key={item.id}
            value={item.id}
          >
            {item.name}
          </option>
        );
      })
    );
  };

  const renderPosition = () => {
    return (
      listPosition &&
      listPosition.map((item) => {
        return (
          <option
            className='text-uppercase'
            key={item.id}
            value={item.id}
          >
            {item.name}
          </option>
        );
      })
    );
  };

  const renderRole = () => {
    return (
      listRole &&
      listRole.map((item) => {
        return (
          <option
            className='text-uppercase'
            key={item.value}
            value={item.value}
          >
            {item.name}
          </option>
        );
      })
    );
  };

  const onFinish = (value) => {
    if (id) {
      const data = localStorage.getItem('user');

      if (pathname === `/edit-account/${id}`) {
        dispatch(editAccount(value, id, fileList, callback));
      } else if (pathname === `/edit-profile/${id}`) {
        dispatch(editUserAccount(value, id, fileList, callback));
      }
    } else {
      if (fileList.length > 0) {
        dispatch(addAccount(value, fileList, callback));
      } else {
        alert('Please upload a profile picture');
      }
    }
  };

  const callback = (response) => {
    let res = response.message;
    let err = response.errors;

    console.log(res);

    console.log(err);

    if (res === 'User account created successfully.') {
      setFileList([]);
      onSetFieldValue();
      message.success(res);
      console.log(response);
      if (pathname === `/add-account`) history.push('/user-accounts');
      console.log(res);
    } else if (res === 'User account updated successfully.') {
      message.success(res);
      if (pathname === `/edit-profile/${id}`) history.push('/');
      if (pathname === `/edit-account/${id}`) history.push('/user-accounts');
    } else {
      if (err.name) {
        form.setFields([
          {
            name: 'name',
            errors: [`${err.name}`],
          },
        ]);
      }
      if (err.contact_number) {
        form.setFields([
          {
            name: 'contact_number',
            errors: [`${err.contact_number}`],
          },
        ]);
      }
      if (err.rank) {
        form.setFields([
          {
            name: 'rank',
            errors: [`${err.rank}`],
          },
        ]);
      }
      if (err.unit) {
        form.setFields([
          {
            name: 'unit',
            errors: [`${err.unit}`],
          },
        ]);
      }
      if (err.gender) {
        form.setFields([
          {
            name: 'gender',
            errors: [`${err.gender}`],
          },
        ]);
      }
      if (err.email) {
        form.setFields([
          {
            name: 'email',
            errors: [`${err.email}`],
          },
        ]);
      }
      if (err.password) {
        form.setFields([
          {
            name: 'password',
            errors: [`${err.password}`],
          },
        ]);
      }
      if (err.role) {
        form.setFields([
          {
            name: 'role',
            errors: [`${err.role}`],
          },
        ]);
      }
    }
  };
  console.log(pathname);
  console.log(id);

  return (
    <div
      className='sub-container '
      style={{ position: 'relative' }}
    >
      <div className='profile-banner'>
        <img
          src={banner}
          className='w-full h-52 object-cover hidden lg:flex banner-image'
          alt=''
        />
        <div className='profile-picture'>
          <div style={{ position: 'relative', textAlign: 'center' }}>
            {' '}
            <Upload
              {...propsUpload}
              onChange={onChange}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '10rem',
                  height: '10rem',
                  borderRadius: '0 !important',
                }}
                className='flex justify-center'
              >
                {profileImage ? (
                  <img
                    src={profileImage}
                    style={{
                      width: '100%',
                      minHeight: '100%',
                      borderRadius: '5rem',
                    }}
                    className='file-uploader'
                    alt=''
                  />
                ) : (
                  <div
                    className=''
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      minHeight: '100%',
                      borderRadius: '5rem',
                      backgroundColor: '#EAEAEA',
                      cursor: 'pointer',
                    }}
                  >
                    <svg
                      width='2.5rem'
                      height='2.5rem'
                      fill='#333333'
                      x='0px'
                      y='0px'
                      viewBox='0 0 490 490'
                    >
                      <g>
                        <g>
                          <path
                            d='M0,167.85v216.2c0,33,26.8,59.8,59.8,59.8h370.4c33,0,59.8-26.8,59.8-59.8v-216.2c0-31.4-25.5-56.9-56.9-56.9h-79.6
			l-1.9-8.3c-7.7-33.3-37-56.5-71.2-56.5h-70.9c-34.1,0-63.4,23.2-71.2,56.5l-1.9,8.3H56.9C25.5,110.95,0,136.55,0,167.85z
			 M146.2,135.45c5.7,0,10.6-3.9,11.9-9.5l4.1-17.8c5.2-22.1,24.6-37.5,47.3-37.5h70.9c22.7,0,42.1,15.4,47.3,37.5l4.1,17.8
			c1.3,5.5,6.2,9.5,11.9,9.5H433c17.9,0,32.4,14.5,32.4,32.4v216.2c0,19.5-15.8,35.3-35.3,35.3H59.8c-19.5,0-35.3-15.8-35.3-35.3
			v-216.2c0-17.9,14.5-32.4,32.4-32.4H146.2z'
                          />
                          <circle
                            cx='82.9'
                            cy='187.75'
                            r='16.4'
                          />
                          <path
                            d='M245,380.95c56.7,0,102.9-46.2,102.9-102.9s-46.2-102.9-102.9-102.9s-102.9,46.1-102.9,102.9S188.3,380.95,245,380.95z
			 M245,199.65c43.2,0,78.4,35.2,78.4,78.4s-35.2,78.4-78.4,78.4s-78.4-35.2-78.4-78.4S201.8,199.65,245,199.65z'
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                )}
              </div>
              <div
                className='p-2 d-flex justify-center'
                style={{
                  width: '40px',
                  height: '40px',
                  alignItems: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#0073bc',
                  position: 'absolute',
                  top: '76%',
                  right: '0%',
                }}
              >
                <BsFillPencilFill color='white' />
              </div>
            </Upload>
          </div>
        </div>
        {/* {renderUpload()} */}
      </div>
      <FormWrapper>
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            className='user-management-form'
          >
            {UI.box(30)}

            <div className='p-10'>
              <div
                className='row rounded'
                style={{ backgroundColor: '#EAEAEA' }}
              >
                {/* First column */}
                <div className='col-lg-4 bg-white'>
                  <div
                    className='w-100'
                    style={{ flex: '1 0 auto' }}
                  >
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      Name
                    </p>
                    <Form.Item
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter name here!',
                        },
                      ]}
                    >
                      <Input
                        placeholder='Enter Name'
                        className='w-100'
                      />
                    </Form.Item>
                  </div>
                  <div
                    className='w-100'
                    style={{ flex: '1 0 auto' }}
                  >
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      Contact No.
                    </p>
                    <Form.Item
                      name='contact_number'
                      rules={[
                        {
                          required: true,
                          message: 'Please contact no. here!',
                        },
                      ]}
                    >
                      <Input
                        placeholder='Enter Contact No.'
                        type='number'
                        style={{ height: '3rem' }}
                      />
                    </Form.Item>
                  </div>

                  {/* Rank  */}
                  {pathname === `/edit-profile/${id}` ? null : (
                    <div
                      className='w-100'
                      style={{ flex: '1 0 auto' }}
                    >
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Rank
                      </p>
                      <Form.Item
                        name='rank'
                        rules={[
                          {
                            required: true,
                            message: 'Please select rank here!',
                          },
                        ]}
                      >
                        <Select
                          className='text-uppercase'
                          placeholder='Enter Rank'
                          style={{ height: '3rem' }}
                        >
                          {renderPosition()}
                        </Select>
                      </Form.Item>
                    </div>
                  )}

                  {/* Role  */}
                  {/* <Form.Item
                  name='role'
                  style={{ display: 'hidden' }}
                >
                  <Input
                    value={showAccount.role}
                    type='hidden'
                  />
                </Form.Item> */}
                </div>
                {/* Second column */}
                <div className='col-lg-4 bg-white'>
                  {' '}
                  <div
                    className='w-100'
                    style={{ flex: '1 0 auto' }}
                  >
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      Email
                    </p>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter email here!',
                        },
                      ]}
                    >
                      <Input
                        placeholder='Enter Email'
                        type='email'
                        style={{ height: '3rem' }}
                      />
                    </Form.Item>
                  </div>
                  {/* status  */}
                  {pathname === `/edit-profile/${id}` ? null : (
                    <div className='w-100'>
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Status
                      </p>
                      <Form.Item
                        name='status'
                        rules={[
                          {
                            required: true,
                            message: 'Please select status here!',
                          },
                        ]}
                      >
                        <Select
                          className='text-uppercase'
                          placeholder='Select Status'
                          style={{ height: '3rem' }}
                        >
                          <option
                            className='text-uppercase'
                            value='active'
                          >
                            Active
                          </option>
                          <option
                            className='text-uppercase'
                            value='inactive'
                          >
                            Inactive
                          </option>
                        </Select>
                      </Form.Item>
                    </div>
                  )}
                  {/* ROle  */}
                  {pathname === `/edit-profile/${id}` ? null : (
                    <div
                      className='w-100'
                      style={{ flex: '1 0 auto' }}
                    >
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Role
                      </p>
                      <Form.Item
                        name='role'
                        rules={[
                          {
                            required: true,
                            message: 'Please select role here!',
                          },
                        ]}
                      >
                        <Select
                          className='text-uppercase'
                          placeholder='Select Role'
                          style={{ height: '3rem' }}
                        >
                          {renderRole()}
                        </Select>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {/* Third column */}
                <div className='col-lg-4 bg-white'>
                  <div
                    className='w-100'
                    style={{ flex: '1 0 auto' }}
                  >
                    <p
                      className='text-blue-10 mb-2'
                      style={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      Sex
                    </p>
                    <Form.Item
                      name='gender'
                      rules={[
                        {
                          required: true,
                          message: 'Please select sex here!',
                        },
                      ]}
                    >
                      <Select
                        className='text-uppercase'
                        placeholder='Select Sex'
                        style={{ height: '3rem' }}
                      >
                        <option
                          className='text-dark'
                          value='male'
                        >
                          Male
                        </option>
                        <option value='female'>Female</option>
                      </Select>
                    </Form.Item>
                  </div>
                  {/* Unit  */}
                  {user && pathname !== `/edit-profile/${id}` && (
                    <div
                      className='w-100'
                      style={{ flex: '1 0 auto' }}
                    >
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Unit
                      </p>
                      <Form.Item
                        name='unit'
                        rules={[
                          {
                            required: true,
                            message: 'Please select unit here!',
                          },
                        ]}
                      >
                        <Select
                          className='text-uppercase'
                          placeholder='Select Unit'
                          style={{ height: '3rem' }}
                        >
                          {renderUnit()}
                        </Select>
                      </Form.Item>
                    </div>
                  )}
                  {/* Unit  */}
                  {/* {user && pathname === 'edit-profile' && user && (
                    <Form.Item
                      name='unit'
                      rules={[
                        {
                          required: true,
                          message: 'Please select unit here!',
                        },
                      ]}
                    >
                      <Input
                        type='hidden'
                        value={showAccount.unit}
                        disabled
                      />
                      {console.log(showAccount)}
                    </Form.Item>
                  )} */}
                  {/* password not add account  */}
                  {/* {pathname !== '/add-account' && (
                    <div
                      className='w-100'
                      style={{ flex: '1 0 auto' }}
                    >
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Password (Optional)
                      </p>
                      <Form.Item
                        name='password'
                        rules={[
                          {
                            required: false,
                            min: 8,
                            message: 'Must be minimum 8',
                          },
                        ]}
                        style={{ height: '3rem !important' }}
                      >
                        <Input
                          type='password'
                          style={{ height: '3rem' }}
                        />
                      </Form.Item>
                    </div>
                  )} */}
                  {/* password add  */}
                  {!id && (
                    <div
                      className='w-100'
                      style={{ flex: '1 0 auto' }}
                    >
                      <p
                        className='text-blue-10 mb-2'
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                      >
                        Password (Default: pnp2022)
                      </p>
                      <Form.Item
                        name='password'
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input
                          type='password'
                          placeholder='Enter Password (Default: pnp2022)'
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div
              className='p-4'
              style={{ flex: '1 0 auto' }}
            >
              <div
                className='w-100'
                style={{ flex: '1 0 auto' }}
              >
                <p
                  className='text-blue-10 mb-2'
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                >
                  Sub Unit
                </p>
                <Form.Item
                  name='sub_unit'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter sub-unit here!',
                    },
                  ]}
                >
                  <Input placeholder='Enter Sub Unit' />
                </Form.Item>
              </div>
            </div> */}
            {/* Buttons */}
            <div className='flex justify-end mt-10 p-5'>
              {!view && (
                <Button
                  htmlType='submit'
                  className='bg-blue-10 h-10  text-white w-32 mr-2'
                >
                  SUBMIT
                </Button>
              )}
              <Link
                to={
                  (history.location.pathname === '/edit-account/' + id) |
                  (history.location.pathname === '/add-account')
                    ? '/user-accounts'
                    : '/'
                }
              >
                <Button className='bg-red-10 h-10 text-white w-32'>
                  {view ? 'BACK' : 'CANCEL'}
                </Button>
              </Link>
            </div>
          </Form>
        )}
      </FormWrapper>
    </div>
  );
};

export default FormAddEdit;
