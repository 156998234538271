import { getAuthHeader } from "../../shared/utils";
import { API_SHOW_PROFILE } from "../../shared/constant/url";

export const PROFILE = {
  SHOW_PROFILE_START: "SUMMARY_START",
  SHOW_PROFILE_SUCCESS: "SHOW_PROFILE_SUCCESS",
  SHOW_PROFILE_ERROR: "SHOW_PROFILE_ERROR",
};

export const onShowProfile = () => {
  return (dispatch) => {
    dispatch({ type: PROFILE.SHOW_PROFILE_START });
    let requestOption = {
      method: "GET",
      headers: getAuthHeader(),
    };

    fetch(API_SHOW_PROFILE, requestOption)
      .then((response) => response.json())
      .then((response) => {
        //return;
        // console.log(response.data);
        dispatch({
          type: PROFILE.SHOW_PROFILE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: PROFILE.SHOW_PROFILE_ERROR, payload: error });
      });
  };
};
