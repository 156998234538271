import { ACCOUNT } from './action';

const INITIAL_STATE = {
    loading: false,
    error: '',
    isLoggedIn: false,
    showAccount: "",
    listAccount: [],
    listRole: [],
    listUnit: [],
    listPosition: []
};


const account = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ACCOUNT.SHOW_ACCOUNT_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case ACCOUNT.SHOW_ACCOUNT_SUCCESS:
            return { ...state, loading: false, showAccount: action.payload, isLoggedIn: true }
        case ACCOUNT.SHOW_ACCOUNT_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        case ACCOUNT.LIST_ACCOUNT_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case ACCOUNT.LIST_ACCOUNT_SUCCESS:
            return { ...state, loading: false, listAccount: action.payload, isLoggedIn: true }
        case ACCOUNT.LIST_ACCOUNT_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        case ACCOUNT.LIST_ROLE_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case ACCOUNT.LIST_ROLE_SUCCESS:
            return { ...state, loading: false, listRole: action.payload, isLoggedIn: true }
        case ACCOUNT.LIST_ROLE_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        case ACCOUNT.LIST_UNIT_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case ACCOUNT.LIST_UNIT_SUCCESS:
            return { ...state, loading: false, listUnit: action.payload, isLoggedIn: true }
        case ACCOUNT.LIST_UNIT_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        case ACCOUNT.LIST_POSITION_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case ACCOUNT.LIST_POSITION_SUCCESS:
            return { ...state, loading: false, listPosition: action.payload, isLoggedIn: true }
        case ACCOUNT.LIST_POSITION_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        default:
            return state;
    }
}

export default account;


