import React, { useState, useEffect, createElement } from 'react';
import SideBar from './containers/SideBar';
import PageHeader from './containers/PageHeader';
import PageContent from './containers/PageContent';
import { useDispatch, useSelector } from 'react-redux';
import { onShowProfile } from '../profile/action';
import { Layout, Drawer, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';

const Landing = () => {
  const dispatch = useDispatch();
  const [collapse, setCollape] = useState(false);
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState('');
  const state = useSelector((state) => state);
  const { showProfile } = state.profile;
  const { Header, Sider, Content } = Layout;
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    dispatch(onShowProfile());
  }, []);

  const showSideBar = () => {
    setShow(!show);
  };

  const toggle = () => {
    setCollape(!collapse);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const renderLinks = () => {
    return (
      <div className='text-blue-10'>
        <Menu
          theme='white'
          style={{ border: 'none' }}
          mode='inline'
          defaultSelectedKeys={['1']}
        >
          <Menu.Item
            key='1'
            icon={<UserOutlined />}
          >
            <Link to='/'>
              <p>Dashboard</p>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='2'
            icon={<VideoCameraOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'information_operations');
              }}
              to='/information-operations'
            >
              <p>Information Operations</p>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='3'
            icon={<UploadOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'public_informations');
              }}
              to='/public-information'
            >
              <p>Public Information</p>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='4'
            icon={<UploadOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'community_affairs');
              }}
              to='/community-affairs'
            >
              <p>Community Affairs</p>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='5'
            icon={<UploadOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'salaam_polices');
              }}
              to='/salaam-police'
            >
              <p>Salaam Police</p>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='6'
            icon={<UploadOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'developments');
              }}
              to='/development'
            >
              <p className='text-sm'>
                Family, Juvenile, Gender and Development
              </p>
            </Link>
          </Menu.Item>
          <Menu.Item
            key='7'
            icon={<UploadOutlined />}
          >
            <Link
              onClick={() => {
                localStorage.setItem('active_tab', 'plans_programs');
              }}
              to='/plan-program'
            >
              <p>Plans and Programs</p>
            </Link>
          </Menu.Item>

          {user && user.role === 'admin' ? (
            <>
              <Menu.Item
                key='8'
                icon={<UploadOutlined />}
              >
                <Link to='/admin'>
                  <p>Admin</p>
                </Link>
              </Menu.Item>
              <Menu.Item
                key='9'
                icon={<UploadOutlined />}
              >
                <Link to='/announcement'>
                  <p>Announcements</p>
                </Link>
              </Menu.Item>
              <Menu.Item
                key='10'
                icon={<UploadOutlined />}
              >
                <Link to='/summary'>
                  <p>Summary</p>
                </Link>
              </Menu.Item>
              <Menu.Item
                key='11'
                icon={<UploadOutlined />}
              >
                <Link to='/user-accounts'>
                  <p>User Accounts</p>
                </Link>
              </Menu.Item>
            </>
          ) : (
            ''
          )}
        </Menu>
      </div>
    );
  };

  return (
    <Layout>
      <Sider
        width={320}
        trigger={null}
        collapsible
        collapsed={collapse}
        className='sidebar'
      >
        <SideBar
          collapse={collapse}
          showProfile={showProfile}
        />
      </Sider>
      <Layout style={{minHeight:'1500px'}}>
        <div
          className='bg-white  sticky  z-50 d-flex justify-between p-4'
          style={{ paddingLeft: '2rem' }}
        >
          <div
            className='d-flex'
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <button
              className='sidebar-btn-lg-screen'
              onClick={toggle}
            >
              <i className='fas fa-bars cursor-pointer'></i>
            </button>
            <button
              className='sidebar-btn-sm-screen'
              onClick={showDrawer}
            >
              <i className='fas fa-bars cursor-pointer'></i>
            </button>
            <img
              src='/images/navlogo.png'
              alt='img'
              className=''
              style={{ height: '35px', width: '35px' }}
            />

            <span
              className='  welcome-back fw-bold'
              style={{ textTransform: 'capitalize', letterSpacing: '1px' }}
            >
              Welcome Back {user && user.role}!
            </span>
          </div>
          <PageHeader
            showProfile={showProfile}
            showSideBar={showSideBar}
          />
        </div>
        <Content
          style={{
            margin: '0px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          <PageContent />
        </Content>

        <div
          className='fw-bold text-center pb-5'
          style={{ letterSpacing: '1px' }}
        >
          {' '}
          ©2022 Philippine National Police
        </div>
      </Layout>
      <Drawer
        title=''
        placement='left'
        onClose={onClose}
        visible={visible}
      >
        {renderLinks()}
      </Drawer>
    </Layout>
  );
};

export default Landing;
