import { combineReducers } from 'redux';
import infoOperation from '../../modules/info-operation/reducer';
import summary from '../../modules/summary/reducer';
import general from '../../modules/general/reducer';
import publicinfo from '../../modules/public-information/reducer';
import announcement from '../../modules/announcement/reducer';
import account from '../../modules/user-accounts/reducer';
import profile from '../../modules/profile/reducer';
import dashboard from '../../modules/dashboard/reducer';
import referenceTable from '../../modules/ReferenceTableMaintenance/reducer';
import reference from '../../modules/FrequencyReport/reducer';
const rootReducer = combineReducers({
  account,
  summary,
  general,
  profile,
  dashboard,
  publicinfo,
  announcement,
  infoOperation,
  referenceTable,
  reference,
});

export default rootReducer;
