export const names = ({
    io: 'information_operations',
    pi: 'public_informations',
    ca: 'community_affairs',
    sp: 'salaam_polices',
    de: 'developments',
    pp: 'plans_programs',
    ad: 'admins'
});


export const user = JSON.parse(localStorage.getItem('user'))