import React, { useEffect, useState } from 'react';
import FormAddEdit from '../components/FormAddEdit';
import StandardLanding from '../../../shared/components/StandardLanding';
import { onShowAnnouncement } from '../action';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addAnnouncement, editAnnouncement } from '../action';

const Landing = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const role = state?.profile?.showProfile?.role;
  const [userdata, setUserData] = useState([]);
  useEffect(() => {
    if (id) {
      dispatch(onShowAnnouncement(id, callback));
    }
  }, []);
  const callback = (response) => {
    const { data } = response;
    console.log(data);
    setUserData(data);
  };

  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>REFERENCE TABLE MAINTENANCE</p>
          <p className='md-title text-gray-20'>Add new report here</p>
        </div>
        <div className='mt-2'>
          <FormAddEdit
            addAnnouncement={addAnnouncement}
            editAnnouncement={editAnnouncement}
            userdata={userdata}
          />
        </div>
      </div>
    </StandardLanding>
  );
};

export default Landing;
