import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Select } from 'antd';
import { Link } from 'react-router-dom';
import FormWrapper from '../../../shared/components/FormWrapper';
import { getCategoriesReport } from '../../general/action';
import './FormSearch.scss';
import { DateRangePicker } from 'react-date-range';
import Moment from 'moment';
import { authHeader } from '../../../shared/utils';
import axios from 'axios';
import { API_GET_FREQUENCY_REPORT } from '../../../shared/constant/url';
import { useRef } from 'react';
import { TreeSelect } from 'antd';
import { treeData } from '../../../shared/hooks/useDateRange';
import { BiReset } from 'react-icons/bi';
const { Option } = Select;

const FormSearch = (props) => {
  const dispatch = useDispatch();
  const {
    getAnnouncementList,
    categories,
    categoryID,
    setCategories,
    setCategoryID,
    setSearch,
    search,
    setAnnouncementList,
    setHotReloadStart,
    hotReloadStart,
    callbackList,
  } = props;
  const [isshowDate, setisshowDate] = useState(true);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const onFinish = (value) => {
    getAnnouncementList(value);
  };

  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setisshowDate(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    dispatch(getCategoriesReport(Callback));
  }, []);
  useEffect(() => {
    // date rangeSear

    if (hotReloadStart) {
      console.log('range');
      const formatDateStart = Moment(selectionRange[0].startDate).format(
        'MM/DD/YYYY',
      );
      const formatDateEnd = Moment(selectionRange[0].endDate).format(
        'MM/DD/YYYY',
      );
      const params = {
        category: categoryID,
        start_date: formatDateStart,
        end_date: formatDateEnd,
      };

      // dispatch(getAnnouncementList(params, callbackRe));
      getlistDate(params);
    }
  }, [selectionRange]);
  const getlistDate = async (params) => {
    console.log(params);
    const result = await getlistDateAsync(params);
    const { data } = result;

    const newData = data.data.map((data) => {
      return {
        id: data.attributes?.id,
        category: data.relationships?.category?.name,
        subcategory: data.relationships?.sub_category?.name,
        title: data.attributes?.title,
        description: data.attributes?.description,
        date: data.attributes?.timestamp,
      };
    });

    setAnnouncementList(newData);
  };
  const getlistDateAsync = async (params) => {
    let token = localStorage.getItem('token');
    const result = await axios.get(
      `${API_GET_FREQUENCY_REPORT}?category=${params.category}&search=${
        params.search ? params.search : ''
      }&include[]=category&include[]=subCategory&start_date=${
        params?.start_date ? params?.start_date : ''
      }&end_date=${params?.end_date ? params?.end_date : ''}`,
      {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Accept': 'application/json',
        },
      },
    );
    return result;
  };

  const Callback = (response) => {
    setCategories(response.data);
  };
  const useDateRange = (dispatch, key) => (e) => {
    console.log(e);
    let date = new Date().getFullYear();

    let params = {
      category: categoryID,
      start_date: Moment(`01/01/${date}`).format('MM/DD/YYYY'),
      end_date: Moment(new Date()).format('MM/DD/YYYY'),
    };
    if (e === 'first_quarter') {
      params = {
        ...params,
        start_date: `01/01/${date}`,
        end_date: `03/31/${date}`,
      };
    }
    if (e === 'second_quarter') {
      params = {
        ...params,
        start_date: `04/01/${date}`,
        end_date: `06/30/${date}`,
      };
    }
    if (e === 'third_quarter') {
      params = {
        ...params,
        start_date: `07/01/${date}`,
        end_date: `09/30/${date}`,
      };
    }
    if (e === 'fourth_quarter') {
      params = {
        ...params,
        start_date: `10/01/${date}`,
        end_date: `12/31/${date}`,
      };
    }
    if (e === 'annually') {
      params = {
        ...params,
        start_date: `01/01/${date}`,
        end_date: `12/31/${date}`,
      };
    }
    if (e === 'jan') {
      params = {
        ...params,
        start_date: `01/01/${date}`,
        end_date: `01/31/${date}`,
      };
    }
    if (e === 'feb') {
      params = {
        ...params,
        start_date: `02/01/${date}`,
        end_date: `03/01/${date}`,
      };
    }
    if (e === 'mar') {
      params = {
        ...params,
        start_date: `03/01/${date}`,
        end_date: `03/31/${date}`,
      };
    }
    if (e === 'april') {
      params = {
        ...params,
        start_date: `04/01/${date}`,
        end_date: `04/30/${date}`,
      };
    }
    if (e === 'may') {
      params = {
        ...params,
        start_date: `05/01/${date}`,
        end_date: `05/31/${date}`,
      };
    }
    if (e === 'jun') {
      params = {
        ...params,
        start_date: `06/01/${date}`,
        end_date: `06/30/${date}`,
      };
    }
    if (e === 'jul') {
      params = {
        ...params,
        start_date: `07/01/${date}`,
        end_date: `07/31/${date}`,
      };
    }
    if (e === 'aug') {
      params = {
        ...params,
        start_date: `08/01/${date}`,
        end_date: `08/31/${date}`,
      };
    }
    if (e === 'sept') {
      params = {
        ...params,
        start_date: `09/01/${date}`,
        end_date: `09/30/${date}`,
      };
    }
    if (e === 'oct') {
      params = {
        ...params,
        start_date: `10/01/${date}`,
        end_date: `10/31/${date}`,
      };
    }
    if (e === 'nov') {
      params = {
        ...params,
        start_date: `11/01/${date}`,
        end_date: `11/30/${date}`,
      };
    }
    if (e === 'dec') {
      params = {
        ...params,
        start_date: `12/01/${date}`,
        end_date: `12/31/${date}`,
      };
    }
    getlistDate(params);
  };
  return (
    <FormWrapper>
      <Form onFinish={onFinish}>
        <div className='search-report flex-wrap'>
          <Form.Item name='search'>
            <Input
              placeholder='Search here'
              className='h-11'
              value={search}
              onChange={(e) => {
                if (!hotReloadStart) {
                  console.log('check');
                  setHotReloadStart(true);
                }
                setSearch(e.target.value);
              }}
            />
          </Form.Item>
          <Link to='/add-frequency'>
            <Button className='bg-blue-10 text-white h-11 '>
              Add&nbsp;<i className='fas fa-plus'></i>
            </Button>
          </Link>
          <div className='d-flex'>
            <Form.Item style={{ width: '17rem' }}>
              <p className='absolute z-50 flex-none bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md flex self-center'>
                Filter by:
              </p>
              <Select
                value={categoryID}
                defaultValue=''
                style={{
                  width: 200,
                  marginLeft: '25%',
                }}
                onChange={(e) => {
                  if (!hotReloadStart) {
                    console.log('check');
                    setHotReloadStart(true);
                  }
                  setCategoryID(e);
                }}
              >
                <Option
                  hidden
                  value=''
                >
                  Category
                </Option>
                {categories.map((data) => {
                  return <Option value={data.id}>{data.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  setCategoryID('');
                  dispatch(getAnnouncementList(null, callbackList));
                }}
                className='bg-blue-10 w-full md:w-auto text-white h-11 md:mr-5 mb-0'
              >
                Reset Filter
              </Button>
            </Form.Item>
          </div>
          <div className='d-flex h-11'>
            <TreeSelect
              size='middle'
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder='Please select'
              onChange={useDateRange(dispatch, 'public_information')}
            />

            <Button
              className='bg-blue-10 text-white h-11 mx-2'
              onClick={() => {
                dispatch(getAnnouncementList(null, callbackList));
              }}
            >
              <BiReset size={18} />
            </Button>
          </div>
          <div className='d-flex'>
            <Form.Item className='custom-date'>
              <p
                style={{ left: '-10px' }}
                className='absolute z-50 flex bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md'
              >
                Filter by:
              </p>
              <Button
                className='buttonDate mx-2'
                style={{
                  cursor: 'pointer',
                  border: '1px solid #C5C5C5',
                  height: '44px',
                  borderLeft: 'none',
                  minWidth: '160px',
                }}
                placeholder='Date'
                onClick={() => {
                  return setisshowDate((prev) => {
                    if (!hotReloadStart) {
                      console.log('check');
                      setHotReloadStart(true);
                    }
                    if (prev) {
                      setisshowDate(false);
                    } else {
                      setisshowDate(true);
                    }
                  });
                }}
              >
                Date
              </Button>
            </Form.Item>
            <Button
              onClick={() => {
                dispatch(getAnnouncementList(null, callbackList));
              }}
              className='bg-blue-10 text-white h-11 '
            >
              Reset Filter
            </Button>
          </div>
        </div>
      </Form>
      <div
        className='container'
        style={{ display: isshowDate ? 'none' : 'block' }}
        ref={ref}
      >
        <DateRangePicker
          editableDateInputs={true}
          onChange={(item) => setSelectionRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={selectionRange}
        />
      </div>
    </FormWrapper>
  );
};

export default FormSearch;
