import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableAdmin from '../components/TableAdmin';
import { getReportType, getAdminList } from '../../general/action';
import { deleteAdmin, updateAdminStatus } from '../action';
import { getUnitList } from '../../user-accounts/action';

const Landing = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { reportType, loading, listAdmin } = state.general;
    const { listUnit } = state.account;

    useEffect(() => {
        const name = 'admins';
        dispatch(getReportType(name));
        dispatch(getAdminList());
        dispatch(getUnitList());
    }, []);

    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">ADMIN</p>
                    <p className="md-title text-gray-20">View tabulated summary of reports here</p>
                </div>
                <div className="mt-2">
                    <TableAdmin
                        loading={loading}
                        reportType={reportType}
                        listAdmin={listAdmin}
                        listUnit={listUnit}
                        deleteAdmin={deleteAdmin}
                        getAdminList={getAdminList}
                        updateAdminStatus={updateAdminStatus}
                    />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
