import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Select, Button, Input } from 'antd';
import FormWrapper from '../../../shared/components/FormWrapper';
import { getAccountList } from '../action';
import './FormSearch.scss';
import UI from '../../../shared/react-native/UI/js';

const FormSearch = (props) => {
  const { reportType } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const user = JSON.parse(localStorage.getItem('user'));
  const [form] = Form.useForm();
  const [filterValue, setFilterValue] = useState(null);
  const [unitValue, setUnitValue] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [search, setSearch] = useState('');
  const [allUnits, setAllUnits] = useState();
  const [hotReloadStart, setHotReloadStart] = useState(false);

  useEffect(() => {
    if (props) {
      const { listUnit } = props;
      setAllUnits(listUnit);

      // console.log(props);
    }
  }, [allUnits, props]);
  useEffect(() => {
    if (hotReloadStart) {
      const identifier = setTimeout(() => {
        const params = {
          search: search,
        };
        dispatch(getAccountList(params));
      }, 500);

      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);
  const onFinish = (value) => {
    let newValue = {};

    if (value == 'clear') {
      newValue.search = '';
    } else {
      newValue.search = search;
    }
    console.log(newValue);
    dispatch(getAccountList(newValue));
  };

  const onFilterByStatus = (e) => {
    if (props) {
      props.onStatusChange(e);
    }

    setFilterValue(e);
  };

  const filterByUnit = (e) => {
    if (props) {
      props.onUnitChange(e);
    }

    setUnitValue(e);
  };

  const onFilterByDate = (e) => {
    if (props) {
      props.onDateChange(e);
    }

    setDateValue(e);
  };

  return (
    <FormWrapper>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <div className='formsearch-wrapper'>
          <div className='formsearch-wrapper__filter'>
            <div className='filter__add-account'>
              <Link to='/add-account'>
                <Button className='bg-blue-10 mb-5  text-white h-11 '>
                  Create Account&nbsp;<i className='fas fa-plus'></i>
                </Button>
              </Link>
            </div>
            <div className='filter__unit'>
              <Form.Item>
                <p className='absolute z-50 flex-none bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md flex self-center'>
                  Filter by:
                </p>
                <Select
                  placeholder='Unit'
                  className='flex-1'
                  value={unitValue}
                  style={{ padding: '5px 10px 5px 71px' }}
                  onChange={filterByUnit}
                >
                  {allUnits &&
                    allUnits.map((data) => {
                      return <option value={data.name}>{data.name}</option>;
                    })}
                </Select>
              </Form.Item>

              <Form.Item>
                <div
                  onClick={() => {
                    filterByUnit(null);
                  }}
                >
                  <Button className='bg-blue-10  text-white h-11'>
                    Reset Filter
                  </Button>
                </div>
              </Form.Item>
            </div>
            {/* <div className='filter__date-user'>
              <Form.Item>
                <p
                  style={{ left: '-10px' }}
                  className='absolute z-50 flex  bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md'
                >
                  Filter by:
                </p>
                <Select
                  value={dateValue}
                  placeholder='Date'
                  onChange={onFilterByDate}
                  style={{ minWidth: '190px' }}
                >
                  <option value='daily'>Daily</option>
                  <option value='weekly'>Weekly</option>
                  <option value='monthly'>Monthly</option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  hidden
                  htmlType='submit'
                ></Button>
                <div
                  onClick={() => {
                    onFilterByDate(null);
                  }}
                >
                  <Button className='bg-blue-10 text-white h-11 '>
                    Reset Filter
                  </Button>
                </div>
              </Form.Item>
            </div> */}
          </div>
          <div className='formsearch-wrapper__search'>
            <Form.Item name='search'>
              <Input
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (!hotReloadStart) {
                    setHotReloadStart(true);
                  }
                  if (e.target.value == '') {
                    onFinish('clear');
                  }
                }}
                value={search}
                placeholder='Search here'
                style={{ maxHeight: '44px' }}
              />
              <i className='fas fa-search absolute text-gray-20 -ml-8 mt-2 text-lg p-0'></i>
            </Form.Item>
          </div>
        </div>

        {/* {UI.box(30)}

        <Form.Item className='md:w-80'>
          <p className='absolute z-50 flex md:-ml-16 bg-white px-2 border border-gray-300 h-11 items-center rounded-tl-md rounded-bl-md'>
            Filter by:
          </p>
          <Select
            value={filterValue}
            placeholder='Status'
            onChange={(e) => dispatch(getAccountList({ filterby: e }))}
          >
            <option value='active'>Active</option>
            <option value='inactive'>Inactive</option>
          </Select>
        </Form.Item>

        {UI.box(30)}

        <Form.Item className='md:w-60'>
          <Button
            hidden
            htmlType='submit'
          ></Button>
          <Link
            onClick={() => {
              onFilterByStatus(null);
            }}
          >
            <Button className='bg-blue-10 w-full md:w-auto text-white h-11 md:mr-5 mb-5 md:mb-0'>
              Reset Filter
            </Button>
          </Link>
        </Form.Item> */}
      </Form>
    </FormWrapper>
  );
};

export default FormSearch;
