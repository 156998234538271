import { INFO } from './action';

const INITIAL_STATE = {
    loading: false,
    error: '',
    isLoggedIn: false,
    summary: []
};


const infoOperation = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case INFO.SUMMARY_START:
            return { ...state, loading: true, error: '', isLoggedIn: false }
        case INFO.SUMMARY_SUCCESS:
            return { ...state, loading: false, summary: action.payload, isLoggedIn: true }
        case INFO.SUMMARY_ERROR:
            return { ...state, loading: false, error: action.payload, isLoggedIn: false }

        default:
            return state;
    }
}

export default infoOperation;


