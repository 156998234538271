import { getAuthHeader, getError } from '../../shared/utils';
import { API_SUMMARY, API_URL } from '../../shared/constant/url';

export const SUMMARY = {
  SUMMARY_START: 'SUMMARY_START',
  SUMMARY_SUCCESS: 'SUMMARY_SUCCESS',
  SUMMARY_ERROR: 'SUMMARY_ERROR',
};

export const getSummary = (props) => {
  return (dispatch) => {
    dispatch({ type: SUMMARY.SUMMARY_START });
    let requestOption = {
      method: 'GET',
      headers: getAuthHeader(),
    };
    fetch(
      `${API_URL}/v1/summary-reports?category=information_operations&type=${
        props.type ? props.type : ''
      }`,
      requestOption,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: SUMMARY.SUMMARY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        // callback(null, getError(error));
        dispatch({ type: SUMMARY.SUMMARY_ERROR, payload: error });
      });
  };
};
