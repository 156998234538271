/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Table, Popconfirm, Popover } from 'antd';
import { Modal, Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from '../../../shared/components/Style';
import FormSearch from './FormSearch';
import TableWrapper from '../../../shared/components/TableWrapper';
import { deletePublicInfo } from '../action';
import { getPublicInfo, PIapproveStatus } from '../../general/action';
import { useDispatch } from 'react-redux';
import { updatePublicInfoStatus } from '../action';
import { AiFillEye } from 'react-icons/ai';
import { BsFillPencilFill, BsCheckLg, BsArchiveFill } from 'react-icons/bs';
import useDownloadFile from '../../../shared/hooks/useDownloadFile';
const TablePublicInfo = (props) => {
  const his = useHistory();
  const dispatch = useDispatch();
  const [id, setId] = useState('');
  const { publicInfo, reportType, loading, listUnit } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [modalShow, setModalShow] = React.useState(false);
  const [ReportType, setReportType] = useState({ title: '', id: '' });
  const [dataListInfo, setDataListInfo] = useState();
  const [renderedInfo, setRenderedInfo] = useState();

  const [typeFilter, setTypeFilter] = useState('');
  const [unitFilter, setUnitFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [remark, setremarks] = useState('');

  const columns = [
    {
      title: 'Control No.',
      dataIndex: 'control_number',
      key: 'id',
    },
    {
      title: 'Report Type',
      dataIndex: 'report_type',
      key: 'id',
    },
    {
      title: 'Title/Subject',
      dataIndex: 'subject',
      key: 'id',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'id',
    },
    {
      title: 'Date Submitted',
      dataIndex: 'date_submitted',
      key: 'id',
    },
    {
      title: ' Submitted By',
      dataIndex: 'submitted_by',
      key: 'id',
    },

    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'id',
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'id',
      render: (data, rowData) => renderStatus(data),
    },
    {
      title: 'Action',
      dataIndex: 'address',
      key: 'address',
      render: (data, rowData) => renderAction(rowData),
    },
  ];

  useEffect(() => {
    if (props) {
      if (!renderedInfo) {
        if (props?.publicInfo?.length > 0) {
          setRenderedInfo(props.publicInfo);
        }
      }

      if (dataListInfo != publicInfo) {
        setDataListInfo(publicInfo);
        setRenderedInfo(publicInfo);
      }

      setDataListInfo(props.publicInfo);
    }
  }, [props, publicInfo, renderedInfo]);

  useEffect(() => {
    renderSearch(typeFilter, unitFilter, dateFilter);
  }, [typeFilter, unitFilter, dateFilter]);

  const onApprove = () => {
    let param = { status: 'approved' };
    dispatch(updatePublicInfoStatus(param, id, callback));
  };

  const onDecline = () => {
    let param = { status: 'declined' };
    dispatch(updatePublicInfoStatus(param, id, callback));
  };

  const menu = (
    <div className='flex flex-col'>
      <button
        style={styled.approved}
        className='mb-2 px-1'
        onClick={onApprove}
      >
        Approve
      </button>
      <button
        style={styled.declined}
        onClick={onDecline}
      >
        Decline
      </button>
    </div>
  );

  const onDeleteItem = (id) => {
    dispatch(deletePublicInfo(id, callback));
  };

  const callback = (response, error) => {
    let res = response.message;
    dispatch(getPublicInfo());
  };

  const renderStatus = (data) => {
    switch (data) {
      case 'corrected':
        return (
          <p
            className='bg-warning px-3 py-1 text-white text-center'
            style={{ borderRadius: '5px', textAlign: 'center' }}
          >
            {data}
          </p>
        );
        break;
      case 'on-time':
        return (
          <p
            className='bg-success px-3 py-1 text-white'
            style={{ borderRadius: '5px', textAlign: 'center' }}
          >
            {data}
          </p>
        );
        break;
      case 'late':
        return (
          <p
            className='bg-danger px-3 py-1 text-white'
            style={{ borderRadius: '5px', textAlign: 'center' }}
          >
            {data}
          </p>
        );
    }
  };

  const download_file = async (file) => {
    useDownloadFile(file);
  };

  const renderDropdown = (data) => {
    if (data.status === 'pending' && user?.role == 'admin') {
      return (
        <Popover
          content={menu}
          trigger='click'
          placement='bottom'
        >
          <button onClick={() => setId(data.id)}>
            <i
              style={styled.btn}
              className='fas fa-caret-down bg-green-10'
            ></i>
          </button>
        </Popover>
      );
    }
  };

  const renderAction = (data) => {
    return (
      <>
        <div
          className='d-flex flex-row flex-nowrap align-items-center'
          style={{ gap: '1em' }}
        >
          {/* <Link to="/summary-report">
                    <i style={styled.btn} className="far fa-eye bg-green-10"></i>
                </Link> */}
          <div
            onClick={() => {
              download_file(data.file);
            }}
            style={{ width: '2rem' }}
          >
            <AiFillEye
              className='py-2 px-1 rounded'
              style={{ backgroundColor: '#339ce2' }}
              color='white'
              size={35}
            />
          </div>
          {user.role === 'encoder' && (
            <button
              onClick={() => {
                his.push(`/edit-public-info/${data.id}`);
              }}
              style={{ width: '2rem' }}
            >
              <BsFillPencilFill
                className=' py-2 px-1 rounded'
                color='white'
                size={35}
                style={{
                  background: 'black',
                  cursor: 'pointer',
                }}
              />
            </button>
          )}
          {/* {user.role === 'supervisor' && data.status === 'pending' && (
            <button
              onClick={() => {
                setModalShow(true);
                setReportType((prev) => {
                  return { ...prev, title: data.report_type, id: data.id };
                });
              }}
              style={{ width: '2rem' }}
            >
              <BsCheckLg
                className=' py-2 px-1 rounded'
                color='white'
                size={35}
                style={{
                  background: '#39b151',
                  cursor: 'pointer',
                }}
              />
            </button>
          )} */}
          {user.role === 'super_admin' && (
            <Popconfirm
              placement='top'
              title='Are you sure you want to archive this?'
              onConfirm={() => onDeleteItem(data.id)}
              okText='Yes'
              cancelText='No'
            >
              <BsArchiveFill
                className=' py-2 px-1 rounded'
                color='white'
                size={35}
                style={{ background: '#e1cb06' }}
              />
            </Popconfirm>
          )}
          {/* <Popconfirm
            placement='top'
            title='Are you sure you want to archive this?'
            onConfirm={() => onDeleteItem(data.id)}
            okText='Yes'
            cancelText='No'
          >
            <button style={{ width: '2rem' }}>
              <i
                style={{ ...styled.btn, margin: '0', width: '100%' }}
                className='far fa-trash-alt bg-red-10'
              ></i>
            </button>
          </Popconfirm> */}
        </div>
      </>
    );
  };

  const renderSearch = (type = '', unit = '', date = '') => {
    let temporaryData = dataListInfo;
    let finalData;

    if (temporaryData) {
      if (type && !unit && !date) {
        const filteredData = temporaryData.filter((data) => {
          return data.report_type == type;
        });
        finalData = filteredData;
      }

      if (unit && !type && !date) {
        const filteredData = temporaryData.filter((data) => {
          return data.unit == unit;
        });
        finalData = filteredData;
      }

      if (date && !unit && !type) {
        const resData = selectDateType(date, temporaryData);
        finalData = resData;
      }

      if (type && unit) {
        const filteredData = temporaryData.filter((data) => {
          return data.report_type == type && data.unit == unit;
        });
        finalData = filteredData;
      }

      if (unit && date) {
        const unitFiltered = temporaryData.filter((data) => {
          return data.unit == unit;
        });

        const resData = selectDateType(date, unitFiltered);
        finalData = resData;
      }

      if (type && date) {
        const unitFiltered = temporaryData.filter((data) => {
          return data.report_type == type;
        });

        const resData = selectDateType(date, unitFiltered);
        finalData = resData;
      }

      if (type && unit && date) {
        const notFinalData = temporaryData.filter((data) => {
          return data.report_type == type && data.unit == unit;
        });

        const resData = selectDateType(date, notFinalData);
        finalData = resData;
      }
    }

    setRenderedInfo(finalData);
  };

  const selectDateType = (e, temporaryData) => {
    if (e == 'daily') {
      return weekFilterByDaily(temporaryData);
    } else if (e == 'weekly') {
      return weekFilterByWeekly(temporaryData);
    } else if (e == 'monthly') {
      return weekFilterByMonthly(temporaryData);
    }
  };

  const weekFilterByDaily = (temporaryData) => {
    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.date_submitted.split(' ');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);
      const currentDate = new Date();

      const dataDay = newDate.getDate();
      const currentDay = currentDate.getDate();

      const getDataMonth = newDate.toLocaleString('en-US', {
        month: 'long',
      });
      const getCurrentMonth = currentDate.toLocaleString('en-US', {
        month: 'long',
      });

      const getDataYear = newDate.getFullYear();
      const getCurrentYear = currentDate.getFullYear();

      return (
        dataDay == currentDay &&
        getDataMonth == getCurrentMonth &&
        getDataYear == getCurrentYear
      );
    });

    return filteredData;
  };

  const weekFilterByWeekly = (temporaryData) => {
    const currentDate = new Date();

    let dataWeek = [];
    for (let i = 1; i <= 7; i++) {
      let first = currentDate.getDate() - currentDate.getDay() + i;
      let day = new Date(currentDate.setDate(first)).toISOString().slice(0, 10);
      dataWeek.push(day);
    }

    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.date_submitted.split(' ');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);

      const convertedDay = () => {
        return (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
      };
      const convertedMonth = () => {
        return (
          (newDate.getMonth() < 10 ? '0' : '') +
          (parseInt(newDate.getMonth()) + 1).toString()
        );
      };

      const fullDate = `${newDate.getFullYear()}-${convertedMonth()}-${convertedDay()}`;

      let isTrue = false;
      dataWeek.forEach((week) => {
        if (week == fullDate) {
          isTrue = true;
        }
      });

      return isTrue ? data : '';
    });

    return filteredData;
  };

  const weekFilterByMonthly = (temporaryData) => {
    const filteredData = temporaryData.filter((data) => {
      const dateArr = data.date_submitted.split(' ');
      const newDate = new Date(`${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`);
      const currentDate = new Date();

      const getDataMonth = newDate.toLocaleString('en-US', {
        month: 'long',
      });
      const getCurrentMonth = currentDate.toLocaleString('en-US', {
        month: 'long',
      });

      const getDataYear = newDate.getFullYear();
      const getCurrentYear = currentDate.getFullYear();

      return getDataMonth == getCurrentMonth && getDataYear == getCurrentYear;
    });

    return filteredData;
  };
  const onSubmitHandler = (id, statusresponse) => {
    // e.preventDefault();
    console.log(id, statusresponse);
    const params = { id: id, status: statusresponse, remarks: remark };
    dispatch(PIapproveStatus(params, callback));
    setModalShow(false);
  };
  return (
    <>
      {' '}
      <div className='sub-container p-5'>
        <FormSearch
          reportType={reportType}
          listUnit={listUnit}
          onUnitChange={(e) => setUnitFilter(e)}
          onReportTypeChange={(e) => setTypeFilter(e)}
          onDateChange={(e) => setDateFilter(e)}
        />
        <TableWrapper>
          <Table
            loading={loading}
            dataSource={renderedInfo}
            columns={columns}
            scroll={{ x: 1000 }}
          />
        </TableWrapper>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header
          className='d-flex justify-center'
          closeButton
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            <span className='text-center'> {ReportType.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Control
                type='text'
                placeholder='Remarks'
                onChange={(e) => setremarks(e.target.value)}
              />
            </Form.Group>

            <div className='d-flex justify-center mt-5'>
              <Button
                className='px-4 mx-2 py-2'
                onClick={(e) => onSubmitHandler(ReportType.id, 1)}
              >
                Approve
              </Button>
              <Button
                className='bg-white px-4 mx-2 py-2  text-primary'
                onClick={(e) => onSubmitHandler(ReportType.id, 2)}
              >
                Decline
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TablePublicInfo;
