import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableAnnouncement from '../components/TableAnnouncement';
import { getAnnouncementList, updateAnnouncementStatus } from '../action';

import UI from '../../../shared/react-native/UI/js';
import { getCategoriesReport, getReportType } from '../../general/action';

function ReferenceTableMaintenance() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const { loading } = state.announcement;
  const { reportType } = state.general;
  const [reportTypeID, setReportTypeID] = useState('');

  const [announcementList, setAnnouncementList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = useState('');
  const [search, setSearch] = useState('');
  const [tableType, setTableType] = useState();
  const [hotReloadStart, setHotReloadStart] = useState(false);
  useEffect(() => {
    const params = { category: 'information_operation' };

    dispatch(getAnnouncementList(params, callback));
  }, []);
  const callback = (response) => {
    const { data } = response;
    console.log(data);
    const newData = data.map((newdata) => {
      return { ...newdata, name: newdata.attributes.name };
    });
    setAnnouncementList(newData);
  };

  // useEffect(() => {
  //   const identifier = setTimeout(() => {
  //     if (categoryID) {
  //       const params = {
  //         category_type: categoryID,
  //         table_type: tableType,
  //       };
  //       dispatch(getAnnouncementList(params, callback));
  //     }
  //   }, 500);

  //   return () => {
  //     clearTimeout(identifier);
  //   };
  // }, [categoryID]);
  useEffect(() => {
    if (hotReloadStart) {
      const identifier = setTimeout(() => {
        const params = {
          search: search,
        };
        dispatch(getAnnouncementList(params, callback));
      }, 500);

      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);
  useEffect(() => {
    if (hotReloadStart) {
      console.log(tableType);
      if (tableType) {
        const params = { table_type: tableType, category_type: categoryID };
        dispatch(getAnnouncementList(params, callback));
      }
    }
  }, [tableType, categoryID]);

  return (
    <StandardLanding>
      <div className='main-container content-crop'>
        <div className='flex justify-between'>
          <p className='lg-title text-blue-10'>REFERENCE TABLE MAINTENANCE</p>
          <p className='md-title text-gray-20'>
            View tabulated summary report here
          </p>
        </div>
        <div className='mt-2'>
          <TableAnnouncement
            loading={loading}
            listAnnouncement={announcementList}
            getAnnouncementList={getAnnouncementList}
            updateAnnouncementStatus={updateAnnouncementStatus}
            setHotReloadStart={setHotReloadStart}
            setAnnouncementList={setAnnouncementList}
            categoryID={categoryID}
            setCategories={setCategories}
            setCategoryID={setCategoryID}
            categories={categories}
            setSearch={setSearch}
            reportTypeID={reportTypeID}
            setReportTypeID={setReportTypeID}
            reportType={reportType}
            setTableType={setTableType}
            tableType={tableType}
            hotReloadStart={hotReloadStart}
          />
        </div>
      </div>
    </StandardLanding>
  );
}

export default ReferenceTableMaintenance;
