import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Upload, Skeleton, Button, message } from 'antd';
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from '../../../shared/custom-react-native';
import UI from '../../../shared/react-native/UI/js';
import FormWrapper from '../../../shared/components/FormWrapper';

const FormAddEdit = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { TextArea } = Input;
  const [fileList, setFileList] = useState([]);
  const state = useSelector((state) => state);
  const { loading, showAnnouncement } = state.announcement;
  const { addAnnouncement, editAnnouncement } = props;
  const history = useHistory();

  useEffect(() => {
    if (id) {
      onSetFieldValue();
    }
  }, [showAnnouncement]);

  const onSetFieldValue = () => {
    if (id) {
      setFileList([
        showAnnouncement && {
          name: showAnnouncement.file?.name,
          uri: showAnnouncement.file?.url,
        },
      ]);
      form.setFieldsValue({
        title: showAnnouncement.title,
        description: showAnnouncement.description,
      });
    } else {
      setFileList([]);
      form.setFieldsValue({
        title: '',
        description: '',
      });
    }
  };

  const renderFileName = () => {
    if ((showAnnouncement && showAnnouncement.file.name < 1) || fileList < 1) {
      return (
        <TouchableOpacity
          onClick={() => {
            selectFile();
          }}
        >
          <div
            className='bg-gray-10 p-8 rounded-md'
            style={{ width: 100 }}
          >
            <i className='fas fa-file-upload text-blue-10 text-5xl'></i>
          </div>
        </TouchableOpacity>
      );
    }
  };

  const onFinish = (value) => {
    console.log(fileList);
    if (fileList.length === 0) {
      message.error('Please upload image!');
    }
    if (id) {
      dispatch(editAnnouncement(value, id, fileList, callback));
      // console.log('first');
    } else {
      dispatch(addAnnouncement(value, fileList, callback));
      // console.log('second');
    }
  };

  const callback = (response, error) => {
    const res = response.message;

    if (res === 'Announcement added successfully.') {
      onSetFieldValue();
      message.success(res);
      history.push('/announcement');
    } else if (res === 'Announcement updated successfully.') {
      message.success(res);
    }
  };

  const selectFile = async () => {
    const file = await UI.get_image();
    const size = file.file.size;

    if (!file) {
      message.error('The file you uploaded is not an image ');
    }
    if (file) {
      if (size > 10000000) {
        message.error(
          'The file you uploaded exceeded the max. Given: ' +
            UI.get_file_size(file.file),
        );
      } else {
        setFileList([file]);
      }
    }
  };

  return (
    <div className='sub-container p-5 md:p-10'>
      <FormWrapper>
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
          >
            <div className='mb-4'>
              <img
                src='./images/announcementicon.png'
                alt='img'
              />
            </div>
            <div className='md:w-80'>
              <p
                className='text-blue-10 mb-3'
                style={{ fontSize: '16px', fontWeight: 'bold' }}
              >
                Announcement Title
              </p>
              <Form.Item
                name='title'
                className='md:mb-14'
                rules={[
                  {
                    required: true,
                    message: 'Please enter announcement title!',
                  },
                ]}
              >
                <Input placeholder='Enter Announcement Title' />
              </Form.Item>
            </div>
            <div className='w-full'>
              <p
                className='text-blue-10 mb-3'
                style={{ fontSize: '16px', fontWeight: 'bold' }}
              >
                Details
              </p>
              <Form.Item
                name='description'
                className='md:mb-14'
                rules={[
                  {
                    required: true,
                    message: 'Please enter details!',
                  },
                ]}
              >
                <TextArea
                  rows={8}
                  placeholder='Enter Details Here'
                />
              </Form.Item>
            </div>
            <div>
              <p
                className='text-blue-10 mb-3'
                style={{ fontSize: '16px', fontWeight: 'bold' }}
              >
                Upload Image (Max. 10mb)
              </p>
              <Form.Item className='md:mb-14'>
                {renderFileName()}
                {fileList.map((item) => {
                  return (
                    <TouchableOpacity onClick={selectFile}>
                      <img
                        style={styles.image_pick}
                        src={item.uri}
                      />
                    </TouchableOpacity>
                  );
                })}
              </Form.Item>
            </div>
            <div className='flex justify-end mt-10'>
              <Button
                htmlType='submit'
                className='bg-blue-10 h-10  text-white w-32 mr-2'
              >
                SUBMIT
              </Button>
              <Link to='/announcement'>
                <Button className='bg-red-10 h-10 text-white w-32'>
                  CANCEL
                </Button>
              </Link>
            </div>
          </Form>
        )}
      </FormWrapper>
    </div>
  );
};

const styles = StyleSheet.create({
  image_pick: {
    height: 100,
    width: 100,
    borderRadius: 10,
  },
});
export default FormAddEdit;
