import React, { useState, useEffect } from "react";
import FormForgotPassword from "../components/FormForgotPassword";
import StandardLanding from "../../../shared/components/StandardLanding";
import {
  ImageBackground,
  View,
  Text,
} from "../../../shared/custom-react-native";
import UI from "../../../shared/react-native/UI/js";

const ForgotPassword = () => {
  const [w, setW] = useState(UI.getWidth());

  useEffect(() => {
    setW(UI.getWidth());
    window.addEventListener("resize", resize);

    return function cleanup() {
      window.removeEventListener("resize", resize);
    };
  }, [w]);

  const resize = () => {
    setW(UI.getWidth());
  };

  return (
    <StandardLanding>
      <div className="main-container w-full flex justify-center items-center absolute z-50 px-2 lg:p-0">
        <div className="w-full flex flex-col items-center h-screen justify-center">
          <ImageBackground
            imageFit={"cover"}
            source={"/images/banner.png"}
            style={{
              width: w > 1022 ? w / 4 : "100%",
              minHeight: 100,
              padding: 16,
              backgroundColor: "black",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <View style={{ flexDirection: "row", alignItems: "center" }}>
              <img
                style={{ height: 80, width: 80 }}
                src={"/images/main_logo.png"} alt='logo'
              />
              <View style={{ marginLeft: 10, lineHeight: '120%' }}>
                <Text
                  style={{ color: "white", fontSize: 15, fontWeight: "bold" }}
                >
                  Police Community Relations
                </Text>
                <Text
                  style={{ color: "white", fontSize: 15, fontWeight: "bold" }}
                >
                  Management Information System
                </Text>
              </View>
            </View> */}
          </ImageBackground>
          <div className="bg-white rounded-bl-md rounded-br-md w-full lg:w-1/4">
            <FormForgotPassword />
          </div>
          <p className='mt-4 text-white text-center' style={{ fontSize: '15px' }}>
            © 2022 Directorate for Police Community Relations
          </p>
        </div>
      </div>
      <div className='bg-black'>
        <div
          style={{ background: 'url("images/cover.png")', opacity: '0.75', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}
          className='w-full h-screen'
        />
      </div>
    </StandardLanding>
  );
};


export default ForgotPassword;
