import { GENERAL } from './action';

const INITIAL_STATE = {
  loading: false,
  error: '',
  isLoggedIn: false,
  showInfo: '',
  showPublicInfo: '',
  showCommunityAffair: '',
  listInfo: [],
  listArchive: [],
  listSalaamPolice: [],
  publicInfo: [],
  reportType: [],
  listAdmin: [],
  showAdmin: '',
  showPlanProgram: '',
  showSalaamPolice: '',
  showDevelopment: '',
  listDevelopment: [],
  listPlanProgram: [],
  listCommunityAffair: [],
};

const general = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERAL.LIST_ADMIN_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LIST_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        listAdmin: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LIST_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.LIST_PLAN_PROGRAM_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LIST_PLAN_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        listPlanProgram: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LIST_PLAN_PROGRAM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.LIST_SALAAM_POLICE_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LIST_SALAAM_POLICE_SUCCESS:
      return {
        ...state,
        loading: false,
        listSalaamPolice: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LIST_SALAAM_POLICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.LIST_DEVELOPMENT_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LIST_DEVELOPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        listDevelopment: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LIST_DEVELOPMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.LIST_REPORT_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LIST_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportType: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LIST_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.SALAAM_POLICE_SHOW_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.SALAAM_POLICE_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        showSalaamPolice: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.SALAAM_POLICE_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.DEVELOPMENT_SHOW_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.DEVELOPMENT_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        showDevelopment: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.DEVELOPMENT_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.PLAN_PROGRAM_SHOW_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.PLAN_PROGRAM_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        showPlanProgram: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.PLAN_PROGRAM_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.ADMIN_SHOW_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.ADMIN_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        showAdmin: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.ADMIN_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.INFO_SHOW_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.INFO_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        showInfo: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.INFO_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.COMMUNITY_AFFAIR_SHOW_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.COMMUNITY_AFFAIR_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        showCommunityAffair: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.COMMUNITY_AFFAIR_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.LIST_INFO_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LIST_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        listInfo: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LIST_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };
    case GENERAL.LISTARCHIVE_INFO_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LISTARCHIVE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        listArchive: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LISTARCHIVE_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.LIST_COMMUNITY_AFFAIR_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.LIST_COMMUNITY_AFFAIR_SUCCESS:
      return {
        ...state,
        loading: false,
        listCommunityAffair: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.LIST_COMMUNITY_AFFAIR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.PUBLIC_INFO_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.PUBLIC_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        publicInfo: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.PUBLIC_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    case GENERAL.PUBLIC_INFO_SHOW_START:
      return { ...state, loading: true, error: '', isLoggedIn: false };
    case GENERAL.PUBLIC_INFO_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        showPublicInfo: action.payload,
        isLoggedIn: true,
      };
    case GENERAL.PUBLIC_INFO_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false,
      };

    default:
      return state;
  }
};

export default general;
