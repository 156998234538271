import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducer';

const migrations = {
  1: (state) => {
    return {
      ...state,
    };
  },
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: ['login'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export default {
  store,
  persistor,
};
