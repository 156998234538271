import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardLanding from '../../../shared/components/StandardLanding';
import TableSalaamPolice from '../components/TableSalaamPolice';
import { deleteSalaamPoliceRecord } from '../action';
import { getReportType, getSalaamPoliceList } from '../../general/action';
import { getUnitList } from '../../user-accounts/action';

const Landing = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { reportType, loading, listSalaamPolice } = state.general;
    const { listUnit } = state.account;

    useEffect(() => {
        const name = 'salaam_polices';
        dispatch(getReportType(name));
        dispatch(getSalaamPoliceList());
        dispatch(getUnitList());
    }, []);

    return (
        <StandardLanding>
            <div className="main-container content-crop">
                <div className="flex justify-between">
                    <p className="lg-title text-blue-10">SALAAM POLICE</p>
                    <p className="md-title text-gray-20">View tabulated summary of reports here</p>
                </div>
                <div className="mt-2">
                    <TableSalaamPolice
                        loading={loading}
                        reportType={reportType}
                        listSalaamPolice={listSalaamPolice}
                        listUnit={listUnit}
                        getSalaamPoliceList={getSalaamPoliceList}
                        deleteSalaamPoliceRecord={deleteSalaamPoliceRecord}
                    />
                </div>
            </div>
        </StandardLanding>
    );
};

export default Landing;
