import React from 'react';
import { Switch, Route } from 'react-router';

import MainDashboard from '../../dashboard/containers/Landing';
import InFoOperation from '../../info-operation/containers/Landing';
import AddIORecord from '../../info-operation/containers/AddNewRecord';
import AddRwp from '../../community-affairs/containers/AddNewRecord';
import Summary from '../../summary/containers/Landing';
import PublicInfo from '../../public-information/containers/Landing';
import AddPI from '../../public-information/containers/AddNewRecord';
import ShowPublicInfo from '../../public-information/containers/AddNewRecord';
import CommunityAffairs from '../../community-affairs/containers/Landing';
import SalaamPolice from '../../salaam-police/containers/Landing';
import AddSalaamPolice from '../../salaam-police/containers/AddNewRecord';
import PlanProgram from '../../plan-program/containers/Landing';
import AddPlanProgram from '../../plan-program/containers/AddNewRecord';
import Development from '../../development/containers/Landing';
import AddDevelopment from '../../development/containers/AddNewRecord';
import Admin from '../../admin/containers/Landing';
import AddAdmin from '../../admin/containers/AddNewRecord';
import Announcement from '../../announcement/containers/Landing';
import AddEditAnnouncement from '../../announcement/containers/AddEditRecord';
import ShowAnnouncement from '../../announcement/containers/ShowAnnouncement';
import AddEditReference from '../../ReferenceTableMaintenance/containers/AddEditRecord';
import ShowReference from '../../ReferenceTableMaintenance/containers/ShowAnnouncement';
import UserAccounts from '../../user-accounts/containers/Landing';
import AddEditAccount from '../../user-accounts/containers/AddEditRecord';
import ForgotPassword from '../../forgot-password/containers/Landing';
import ViewAllNotification from '../../view-all-notification';
import Archive from '../../archive/containers/Landing';
import ReferenceTableMaintenance from '../../ReferenceTableMaintenance/containers/ReferenceTableMaintenance';
import ActivityLog from '../../ActivityLog/containers/ActivityLog';
import FrequencyReport from '../../FrequencyReport/containers/Landing';
import AddFrequencyReport from '../../FrequencyReport/containers/AddEditRecord';
import PrivacyPolicy from '../../PrivacyPolicyCMS/PrivacyPolicy';
const PageContent = () => {
  return (
    <Switch>
      <Route
        exact
        path='/'
        component={MainDashboard}
      />
      <Route
        exact
        path='/information-operations'
        component={InFoOperation}
      />
      <Route
        exact
        path='/add-info'
        component={AddIORecord}
      />
      <Route
        exact
        path='/edit-info/:id'
        component={AddIORecord}
      />
      <Route
        exact
        path='/summary'
        component={Summary}
      />
      <Route
        exact
        path='/public-information'
        component={PublicInfo}
      />
      <Route
        exact
        path='/add-public-info'
        component={AddPI}
      />
      <Route
        exact
        path='/edit-public-info/:id'
        component={AddPI}
      />
      <Route
        exact
        path='/show-public-info'
        component={ShowPublicInfo}
      />
      <Route
        exact
        path='/community-affairs'
        component={CommunityAffairs}
      />
      <Route
        exact
        path='/add-community-affair'
        component={AddRwp}
      />
      <Route
        exact
        path='/edit-community-affair/:id'
        component={AddRwp}
      />
      <Route
        exact
        path='/salaam-police'
        component={SalaamPolice}
      />
      <Route
        exact
        path='/add-salaam-police'
        component={AddSalaamPolice}
      />
      <Route
        exact
        path='/edit-salaam-police/:id'
        component={AddSalaamPolice}
      />
      <Route
        exact
        path='/add-development'
        component={AddDevelopment}
      />
      <Route
        exact
        path='/edit-development/:id'
        component={AddDevelopment}
      />
      <Route
        exact
        path='/plan-program'
        component={PlanProgram}
      />
      <Route
        exact
        path='/add-program'
        component={AddPlanProgram}
      />
      <Route
        exact
        path='/edit-program/:id'
        component={AddPlanProgram}
      />
      <Route
        exact
        path='/development'
        component={Development}
      />
      <Route
        exact
        path='/admin'
        component={Admin}
      />
      <Route
        exact
        path='/add-admin'
        component={AddAdmin}
      />
      <Route
        exact
        path='/edit-admin/:id'
        component={AddAdmin}
      />
      <Route
        exact
        path='/announcement'
        component={Announcement}
      />
      <Route
        exact
        path='/add-announcement'
        component={AddEditAnnouncement}
      />
      <Route
        exact
        path='/edit-announcement/:id'
        component={AddEditAnnouncement}
      />
      <Route
        exact
        path='/announcement/:id'
        component={ShowAnnouncement}
      />
      <Route
        exact
        path='/user-accounts'
        component={UserAccounts}
      />
      <Route
        exact
        path='/add-account'
        component={AddEditAccount}
      />
      <Route
        exact
        path='/edit-account/:id'
        component={AddEditAccount}
      />
      <Route
        exact
        path='/edit-profile/:id'
        component={AddEditAccount}
      />
      <Route
        exact
        path='/forgot-password'
        component={ForgotPassword}
      />
      <Route
        exact
        path='/notification'
        component={ViewAllNotification}
      />
      <Route
        exact
        path='/archive'
        component={Archive}
      />
      <Route
        exact
        path='/reference-table-maintenance'
        component={ReferenceTableMaintenance}
      />

      <Route
        exact
        path='/add-reference'
        component={AddEditReference}
      />
      <Route
        exact
        path='/edit-reference/:id'
        component={AddEditReference}
      />
      <Route
        exact
        path='/reference/:id'
        component={ShowReference}
      />
      <Route
        exact
        path='/activity-log'
        component={ActivityLog}
      />
      <Route
        exact
        path='/frequency-report'
        component={FrequencyReport}
      />
      <Route
        exact
        path='/add-frequency'
        component={AddFrequencyReport}
      />
      <Route
        exact
        path='/edit-frequency/:id'
        component={AddFrequencyReport}
      />
      <Route
        exact
        path='/view-frequency/:id'
        component={AddFrequencyReport}
      />
      <Route
        exact
        path='/privacy-policy'
        component={PrivacyPolicy}
      />
    </Switch>
  );
};

export default PageContent;
