import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import FormWrapper from '../../../shared/components/FormWrapper';

const FormSearch = (props) => {
  const dispatch = useDispatch();
  const { getAnnouncementList } = props;

  const onFinish = (value) => {
    getAnnouncementList(value);
  };

  return (
    <FormWrapper>
      <Form
        onFinish={onFinish}
        className='md:flex'
      >
        <Link to='/add-announcement'>
          <Button className='bg-blue-10 text-white h-11 w-full md:w-auto mb-5 md:mb-0 md:mr-5'>
            Add&nbsp;<i className='fas fa-plus'></i>
          </Button>
        </Link>
        <Form.Item
          name='search'
          className='md:w-80 md:mr-20'
        >
          <Input
            placeholder='Search here'
            className='h-11'
            onChange={(e) => {
              if (e.target.value == '') {
                onFinish({ search: '' });
              }
            }}
          />
        </Form.Item>
        <Button htmlType='submit'></Button>
      </Form>
    </FormWrapper>
  );
};

export default FormSearch;
