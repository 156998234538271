import axios from 'axios';
import {
  PRIVACYPOLICY,
  VIEW_ALL_NOTIFICATION,
} from '../../shared/constant/url';
import { authHeader, header } from '../../shared/utils';

export const updatePrivacyPolicy = async (params) => {
  console.log('authHeader: ', authHeader());
  const result = await axios.put(`${PRIVACYPOLICY}`, params, authHeader());
  return result;
};
export const getPrivacyPolicy = async () => {
  console.log('authHeader: ', authHeader());
  const result = await axios.get(`${PRIVACYPOLICY}`, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return result;
};